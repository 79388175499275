import { Fragment, useEffect, useRef, useState } from "react";
import { breakPointDef, sideNavSizesDef, sideNavStatesDef, themeStateDef } from "../definitions/definintion";
import { setMainbreakPoint } from "../redux/responsive/responsiveSlice";
import { useDispatch, useSelector } from "react-redux";
import { bodywrapperSubClassName } from "../definitions/classnames";
import { closeSideNav, expandeSideNav, notPendingSideNav, pendingSideNav, setTryAgainHref, setisCheckingLogin, shrinkSideNav } from "../redux/config/configSlice";
import { SideTabs } from "../pages/home/components/sideTabs";
import { UserProfile } from "../pages/home/components/userProfile";
import { RoundedIconOnlyButton } from "./button";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { Transition } from "@headlessui/react";
import SimpleBar from "simplebar-react";
import '../index.css'
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { setUser } from "../redux/user/userSlice";
import { ModalLoading } from "./modal";



export function BodyWrapperMain({ children }) {



    return (
        <div className="fixed inset-0 flex flex-col h-full grow overflow-auto scroll-smooth   ">
            <div className="absolute bg-mainBgImage inset-0 blur-[100px] bg-no-repeat bg-cover"></div>
            <div className=" grow flex flex-col theme-smooth absolute inset-0  bg-gray-200/20 dark:bg-primaryy-dark-1000  z-0 overflow-y-auto max-h-full">
                {children}
            </div>
        </div>
    )

}

export function BodyWrapperSubNoPadding({ children, className, responsive = false }) {
    const elementRef = useRef(null);
    const dispatch = useDispatch()

    const sideNav = useSelector(
        state => state.config.sideNav
    )

    useEffect(() => {
        // Function to update the wid;th
        let settedSize = ''
        const updateWidth = (state) => {
            const currentElement = elementRef.current;
            if (responsive && currentElement) {
                const computedStyle = window.getComputedStyle(currentElement);
                const paddingLeft = parseInt(computedStyle.paddingLeft, 10);
                const paddingRight = parseInt(computedStyle.paddingRight, 10);
                const totalPadding = paddingLeft + paddingRight;
                const widthExcludingPadding = currentElement.clientWidth - totalPadding;
                if (state === 'starting') {
                    let bk = breakPointDef.xs.name;
                    let size = breakPointDef.xs.size

                    for (const breakpoint in breakPointDef) {

                        if (widthExcludingPadding < breakPointDef[breakpoint].size) {
                            bk = breakPointDef[breakpoint].name
                            size = breakPointDef[breakpoint].size
                            break;
                        }
                    }
                    settedSize = bk
                    dispatch(setMainbreakPoint({
                        breakPoint: settedSize,
                        size: size
                    }))
                    // console.log(bk)
                } else {
                    for (const breakpoint in breakPointDef) {
                        if (widthExcludingPadding < breakPointDef[breakpoint].size) {
                            const bk = breakPointDef[breakpoint].name
                            const size = breakPointDef[breakpoint].size


                            if (settedSize !== bk) {
                                settedSize = bk
                                dispatch(setMainbreakPoint({
                                    breakPoint: settedSize,
                                    size: size
                                }))
                                // console.log(bk)
                            }
                            break;
                        }
                    }
                }


                // console.log(widthExcludingPadding)
            }
        };

        const update = () => setTimeout(
            updateWidth,
            200
        )


        // Set initial width

        updateWidth('starting')

        // Add event listener for window resize
        let mutationObserver = new MutationObserver(update);
        if (responsive === true) {
            // mutationObserver = new MutationObserver(update);
            window.addEventListener('resize', update);
            mutationObserver.observe(elementRef.current, { attributes: true })
        }

        // Cleanup
        return () => {
            if (responsive === true) {
                window.removeEventListener('resize', update);
                mutationObserver.disconnect();
            }
        };
    }, []);

    return (
        <div ref={elementRef} className={`  w-full transition-all duration-300  ${sideNav.size === sideNavSizesDef.expanded ? 'md:ps-[220px] ' : 'md:ps-[90px]'} ${className} `}>

            {children}
        </div>
    )

}
export function BodyWrapperSub({ children, className, responsive = false }) {
    const elementRef = useRef(null);
    const dispatch = useDispatch()

    const sideNav = useSelector(
        state => state.config.sideNav
    )

    useEffect(() => {
        // Function to update the wid;th
        let settedSize = ''
        const updateWidth = (state) => {
            const currentElement = elementRef.current;
            if (responsive && currentElement) {
                const computedStyle = window.getComputedStyle(currentElement);
                const paddingLeft = parseInt(computedStyle.paddingLeft, 10);
                const paddingRight = parseInt(computedStyle.paddingRight, 10);
                const totalPadding = paddingLeft + paddingRight;
                const widthExcludingPadding = currentElement.clientWidth - totalPadding;
                if (state === 'starting') {
                    let bk = breakPointDef.xs.name;
                    let size = breakPointDef.xs.size

                    for (const breakpoint in breakPointDef) {

                        if (widthExcludingPadding < breakPointDef[breakpoint].size) {
                            bk = breakPointDef[breakpoint].name
                            size = breakPointDef[breakpoint].size
                            break;
                        }
                    }
                    settedSize = bk
                    dispatch(setMainbreakPoint({
                        breakPoint: settedSize,
                        size: size
                    }))
                    // console.log(bk)
                } else {
                    for (const breakpoint in breakPointDef) {
                        if (widthExcludingPadding < breakPointDef[breakpoint].size) {
                            const bk = breakPointDef[breakpoint].name
                            const size = breakPointDef[breakpoint].size


                            if (settedSize !== bk) {
                                settedSize = bk
                                dispatch(setMainbreakPoint({
                                    breakPoint: settedSize,
                                    size: size
                                }))
                                // console.log(bk)
                            }
                            break;
                        }
                    }
                }


                // console.log(widthExcludingPadding)
            }
        };

        const update = () => setTimeout(
            updateWidth,
            200
        )


        // Set initial width

        updateWidth('starting')

        // Add event listener for window resize
        let mutationObserver = new MutationObserver(update);
        if (responsive === true) {
            // mutationObserver = new MutationObserver(update);
            window.addEventListener('resize', update);
            mutationObserver.observe(elementRef.current, { attributes: true })
        }

        // Cleanup
        return () => {
            if (responsive === true) {
                window.removeEventListener('resize', update);
                mutationObserver.disconnect();
            }
        };
    }, []);

    return (
        <div ref={elementRef} className={` ${bodywrapperSubClassName} w-full transition-all duration-300  ${sideNav.size === sideNavSizesDef.expanded ? 'md:ps-[240px]   lg:ps-[256px]' : 'md:ps-[106px] lg:ps-[126px]'} ${className} `}>

            {children}
        </div>
    )

}


export function SideNavBar({ children }) {

    const [showSizeButton, setshowSizeButton] = useState(false)

    const sideNav = useSelector(
        state => state.config.sideNav
    )

    const theme = useSelector(
        state => state.config.theme
    )
    const dispatch = useDispatch();

    const divRef = useRef();

    // const clickOutside = () => {
    //     if (sideNav.isPending === sideNavStatesDef.pending) {
    //         return null
    //     } else if (sideNav.isPending === sideNavStatesDef.notPening) {
    //         return (event) => {
    //             if (!divRef.current?.contains(event.target)) {
    //                 dispatch(closeSideNav())
    //                 dispatch(pendingSideNav())
    //                 setTimeout(
    //                     () => {
    //                         dispatch(notPendingSideNav())
    //                     },
    //                     sideNavStatesDef.pendingTime
    //                 )
    //             }
    //         }
    //     }


    // }

    const clickOutside = () => {
        dispatch(closeSideNav())
        dispatch(pendingSideNav())
        setTimeout(
            () => {
                dispatch(notPendingSideNav())
            },
            sideNavStatesDef.pendingTime
        )



    }

    const performShrinkAndExpand = () => {
        if (sideNav.size === sideNavSizesDef.shrink) {
            dispatch(expandeSideNav())
        } else if (sideNav.size === sideNavSizesDef.expanded) {
            dispatch(shrinkSideNav())
        }
    }


    // useEffect(
    //     () => {
    //         // Close the dropdown when clicking outside of it
    //         const fn = clickOutside()
    //         window.addEventListener('click', fn);

    //         return () => {

    //             // Close the dropdown when clicking outside of it
    //             window.removeEventListener('click', fn);

    //         }

    //     },
    // )

    return (
        <>

            <div ref={divRef}
                className={` ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : `transition-all duration-300`}
                 h-full z-30 ${sideNav.state === sideNavStatesDef.open ? 'ml-0' : '-ml-64'}
                  fixed  md:ml-0 md:backdrop-blur-[10px] justify-center items-center  self-stretch bg-sideNavBgImage bg-no-repeat bg-cover md:bg-none md:bg-gray-100/40 dark:bg-none dark:bg-primaryy-dark-1000 flex flex-col  border-r-2 border-solid border-r-gray-50 dark:border-r-gray-500 shadow-xl ${sideNav.size === sideNavSizesDef.expanded ? `min-w-[${sideNavSizesDef.expandedSize}] max-w-[${sideNavSizesDef.expandedSize}]` : `min-w-[${sideNavSizesDef.shrinkSize}] max-w-[${sideNavSizesDef.shrinkSize}]`}`}
                style={{
                    minWidth: sideNav.size === sideNavSizesDef.expanded ? sideNavSizesDef.expandedSize : sideNavSizesDef.shrinkSize,
                    maxWidth: sideNav.size === sideNavSizesDef.expanded ? sideNavSizesDef.expandedSize : sideNavSizesDef.shrinkSize
                }}
                onMouseEnter={() => setshowSizeButton(true)}
                onMouseLeave={() => setshowSizeButton(false)}
            >
                <SimpleBar className="fullwidthscroll w-full h-full overflow-auto px-3">
                    <div className="h-full flex flex-col ">
                        {children}
                    </div>
                </SimpleBar>
                <Transition
                    className={'absolute -right-[17px] top-0 z-10'}
                    show={showSizeButton}
                    // as={Fragment}
                    enter="ease-out duration-100"
                    enterFrom="opacity-0 scale-0 "
                    enterTo="opacity-100 scale-100 "
                    leave="ease-in duration-700"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"

                >
                    <RoundedIconOnlyButton

                        onClick={performShrinkAndExpand}
                        className={`pe-[1px] ${sideNav.size === sideNavSizesDef.shrink ? ' -scale-x-100' : ''} hidden md:flex   `}
                        icon={<IconChevronLeft size={20} />}
                    />
                </Transition>
            </div>
            <div onClick={clickOutside} className={`transition-opacity left-0 top-0 duration-1000 ${sideNav.state === sideNavStatesDef.open ? 'z-20 opacity-100 ' : 'opacity-0 -z-10'} fixed h-screen w-screen bg-gray-400/60 dark:bg-black/60  md:hidden `}>

            </div>
        </>
    )

}

export function ScrollBarSubWrap({ children }) {

    const sideNav = useSelector(
        state => state.config.sideNav
    )

    return (
        <SimpleBar
            className={` ${sideNav.size === sideNavSizesDef.expanded ? 'withLeft' : 'noleft'}  h-[190px]`}
            autoHide={false}
        >
            <BodyWrapperSub>

                {children}
            </BodyWrapperSub>

        </SimpleBar>
    )

}

export function ProtectedRoute({ children,
    loadingLogin            //this componet is used in login section protect route only. because it loads to home screen automatically if loagged in
}) {

    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(true)
    const dispatch = useDispatch()
    const isCheckingLogin = useSelector(
        state => state.config.globalElements.checkingAuth
    )
    const location = useLocation();
    const currentPath = location.pathname;

    function errorHandle(code) {

        switch (code) {
            case 'ERR_NETWORK':
                // dispatch(setTryAgainHref(currentPath))
                navigate('/networkError')
                break;

            default:
                break;
        }

    }
    
    useEffect(
        () => {
            const headers = {
                Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE)}`,
            };
            const initData = async () => {
                setIsLoading(true)
                if (isCheckingLogin===false) {
                    try {
                        dispatch(setisCheckingLogin(true))
                        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user`, { headers })
                        if(res.data.success===true){
                            dispatch(setUser(res.data.user))
                        }
                        dispatch(setisCheckingLogin(false))
                        if (loadingLogin) {
                            navigate('/home', { replace: true })
                        }


                    } catch (error) {
                        if (error?.response?.status === 401) {
                            localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE)
                        }
                        dispatch(setisCheckingLogin(false))
                        navigate('/', {
                            replace: true
                        });
                        errorHandle(error.code)
                        console.log(error)
                    }
                    dispatch(setisCheckingLogin(false))
                }
                setIsLoading(false)

            }

            initData();

        }, []
    )

    if(isLoading){
        return  <ModalLoading show={isLoading} zIndex={100} />
    }
    

    return children;
}; 