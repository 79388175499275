import { useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DarkModeButton, SquareButton } from "../components/button";
import { LoginInput } from "../components/inputs";
import { employeeDef, themeStateDef } from "../definitions/definintion";
import '../index.css';
import axios from "axios";
import { showErrorToast } from "../components/toast";
import { ModalLoading } from "../components/modal";

export function LoginPage(props) {

  const theme = useSelector(
    state => state.config.theme
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [userData,setUserData] = useState({
    [employeeDef.MaritalStatus]:'',
    [employeeDef.Otp]:''
  })
  const [errdatas, setErrDatas] = useState({})
  const [isLoading, setIsLoading] = useState(false)


  const onChangeOTP = (value)=>{
    setUserData({
      ...userData,
      [employeeDef.Otp]:value
    })
  }
  const [isOtpMode,setOtpMode] = useState(false)

  const onChangeUserData = (event)=>{

    setUserData({
      ...userData,
      [event.target.name]:event.target.value
    })

  }

  const verifyEmail = async ()=>{

    if(performValidation(userData)){
      setIsLoading(true)
      try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/generate-otp`,{
          [employeeDef.email]:userData[employeeDef.email]
        })
        if(res.data.success===false){
          const err = new Error('somthing went wrong')
          err.msg = res.data.message;
          throw err
        }
        setOtpMode(true)
      } catch (error) {
        console.log(error)
        showErrorToast({
          message: error.response?.data?.message || error.msg || 'Something went wrong',
          timeOut: 3000
      })
      }
      setIsLoading(false)
    }

  }

  const verifyOtp = async()=>{
    console.log(userData)
    setIsLoading(true)
    try {
      if(userData[employeeDef.Otp].length >=6){
        
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/verify-otp`,{
          [employeeDef.email]:userData[employeeDef.email],
          [employeeDef.Otp]:userData[employeeDef.Otp],
          
        })
        if(res.data.success===true){
          localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE,res.data.access_token)
        }else{
          const err = new Error('somthing went wrong')
          err.msg = res.data.message;
          throw err
        }
        
        navigate('/home')
      }else{
        const err = new Error('enter valid otp')
        err.msg = 'Enter valid Otp'
        throw err
      }
    } catch (error) {
      console.log(error)
      showErrorToast({
        message: error.response?.data?.message || error.msg || 'Something went wrong',
        timeOut: 3000
      })
      
    }
    setIsLoading(false)
  }

  const validationObj = {
    
    [employeeDef.email]: {
        message: 'required',
        validaton: [
            (value) => {
                if (value && value !== '') {
                    return true
                }
                return 'required'
            },
            (value) => {
                if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                    return true
                }
                return 'Not valid email'
            }
        ]
    },
  }

  const performValidation = (obj = {}) => {

    let errObj = {}
    let errNotOccured = true;

    for (const key in validationObj) {

        let isPass = true;

        for (let i = 0; i < validationObj[key].validaton.length; i++) {
            isPass = validationObj[key].validaton[i](obj[key])
            if (isPass !== true) {
                errNotOccured = false
                errObj[key] = isPass;
                break;
            }
        }



    }
    // console.log(err)

    setErrDatas(errObj)
    return errNotOccured;

}

  const handleUserVerification = (e)=>{
    e.preventDefault()
    if(isOtpMode===false){
      verifyEmail()
    }else{
      verifyOtp()
    }
  }



  return (
    <div 
    style={{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center',
      position:'fixed',
      top:0,
      bottom:0,
      left:0,
      right:0
    }} className=" theme-smooth bg-backgroud-gray dark:bg-primaryy-dark-1000 ">
      <ModalLoading show={isLoading} zIndex={100} />
      {/* <div className="absolute inset-0 bg-[url(https://play.tailwindcss.com/img/grid.svg)] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div> */}
      {/* <div className="  bg-signUpImage bg-no-repeat bg-cover  tablet:w-[900px] rounded-[20px] rounded-tr-[25px] shadow-xl  bg-primaryy-500"> */}
      <div className="theme-smooth fixed w-full hfull opacity-30 dark:opacity-[0.1] blur-[40px] background-gradient"></div>
            <div className="">

        <div className="relative justify-center theme-smooth items-center rounded-[20px] shadow-md  bg-white bg-opacity-40 dark:bg-black dark:bg-opacity-20 border border-gray-transparent-700  flex flex-col ml-auto px-5 sm:px-10 md:px-20 min-w-[350px] sm:w-[500px] md:w-[500px]  py-16">
          <div className=" absolute top-2 right-2">
            <DarkModeButton />
          </div>
          <form className=" font-montserrat w-full flex flex-col items-center" onSubmit={handleUserVerification}>
            <>
            <h1 className="text-text-main theme-smooth dark:text-gray-300 text-4xl font-montserrat font-bold whitespace-nowrap text-center ">LOGIN</h1>
            {
              isOtpMode===false?
              <div className="pt-6 flex flex-col gap-3 w-full">
                <LoginInput name={employeeDef.email} type={'text'} label="Email" onChange={onChangeUserData} value={userData[employeeDef.email]} errMsg={errdatas[employeeDef.email]} />
                <SquareButton text={'Sent OTP'} className={' mt-6 w-full'} />
              </div>
              :
              <div
              className="pt-6 flex flex-col items-center gap-3 w-min "
              >
                <OTPInput

                  value={userData[[employeeDef.Otp]]}
                  onChange={onChangeOTP}
                  numInputs={6}
                  renderSeparator={<span className="w-1"></span>}
                  renderInput={(props) => <input  {...{...props,style:{}}}  className={` hideNormalInputBehavour ${theme.state === themeStateDef.PENDING?'theme-smooth':'transition-all'} h-9 w-9 bg-transparent text-gray-800 font-normal text-xs placeholder:text-gray-500  dark:text-gray-300 outline outline-0 focus:outline-0 disabled:bg-gray-200 disabled:dark:bg-primaryy-dark-950   border-2 border-gray-400 dark:border-gray-600  px-3 py-2.5 rounded-[7px] focus:border-transparent focus:dark:border-transparent focus:ring-2  focus:ring-primaryy-500`} />}
                  shouldAutoFocus={true}
                  

                  />
                  <SquareButton text={'Login'} className={' mt-6 w-full'}  />
              </div>
              
            }
            </>
            </form>

  
        </div>
      </div>
    </div>
  );
}
// export function LoginPage(props) {
//   return (
//     <div className="h-full w-full theme-smooth bg-backgroud-gray dark:bg-primaryy-dark-1000 flex justify-center items-center">
//       {/* <div className="absolute inset-0 bg-[url(https://play.tailwindcss.com/img/grid.svg)] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div> */}
//       {/* <div className="  bg-signUpImage bg-no-repeat bg-cover  tablet:w-[900px] rounded-[20px] rounded-tr-[25px] shadow-xl  bg-primaryy-500"> */}
//       <div className="theme-smooth fixed w-[100vw] h-[100vh] opacity-30 dark:opacity-[0.1] blur-[40px] background-gradient"></div>
      
//         {/* <div className="fixed w-[200px] h-[300px] right-[30%] opacity-25 rotate-12 bottom-[5%] rounded-full blur-[60px] bg-gradient-to-r from-cyan-500 to-blue-500"></div> */}
//       <div className="">

//         <div className="relative justify-center theme-smooth items-center rounded-[20px] shadow-md  bg-white bg-opacity-40 dark:bg-black dark:bg-opacity-20 border border-gray-transparent-700  flex flex-col ml-auto px-5 sm:px-10 md:px-20 min-w-[350px] sm:w-[500px] md:w-[500px]  py-16">
//           <div className=" absolute top-2 right-2">
//             <DarkModeButton />
//           </div>
//           <form className=" font-montserrat w-full" action="/home/dashboard">
//             <h1 className="text-text-main theme-smooth dark:text-gray-300 text-4xl font-montserrat font-bold whitespace-nowrap text-center ">LOGIN</h1>
//             <LoginInput className=" mt-9" label="Email" />
//             <LoginInput className=" mt-5" label="Password" type="password" />

//             <SquareButton text={'Login'} className={' mt-6 w-full'} />

//             <div className=" w-full text-center text-gray-400 font-montserrat font-medium tracking-widest text-sm mt-8">
//               <span className="text-gray-400 block sm:inline">Don’t have account? </span>
//               <Link to="/" className="text-primaryy-400 whitespace-nowrap">Create One</Link>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// }
