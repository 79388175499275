import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Accounts } from "./accounts/accounts.page";
import { ProtectedRoute } from "../../../components/layouts";

export function Managment() {


    return (
        <>
            <Routes>
                <Route index element={<Navigate to={'accounts'} replace />} />
                <Route key={1} path="accounts/*" element={
                    // <ProtectedRoute>
                        <Accounts />
                        // </ProtectedRoute>
                    } />
                {/* <Route key={1} path="admin/*" element={<Admin />} /> */}
                {/* <Route key={2} path="hrms/*" element={<HRMS />} /> */}
            </Routes>
        </>
    )
}