import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { useEffect, useState } from "react";
import { IconOnlyButton, SquareButton, SquareIconButton } from "../../../../../../../components/button";
import { InputStd2, InputTextAreaStd2, SelectBoxInputBox, SwitchInputStd2 } from "../../../../../../../components/inputs";
import { ModalLoading, ModalStanderd, QuestionModal } from "../../../../../../../components/modal";
import { showErrorToast, showToast } from "../../../../../../../components/toast";
import { useRESTapi } from '../../../../../../../controller/restAPI';
import { TransactionDef, accountLedgerDef, employeeDef, ledgerPostingDef, partyDef, squareButtonVarient, transType } from "../../../../../../../definitions/definintion";
import { useSelector } from 'react-redux';







export function ModalAddBranch({ show, closeModal, updateUi, pushData, parties = [] }) {


    const user = useSelector(state => state.user.user)
    const api = useRESTapi()
    const [balance, setBalance] = useState('')
    const [errdatas, setErrDatas] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [expenseLedgers, setExpenseLedgers] = useState([])
    const [assetLedgers, setAssetLedgers] = useState([])
    // const [data, setData] = useState(
    //     {
    //         FM09_PartyName: 'werty',
    //         FM09_Address: 'sm street',
    //         FM09_MobileNumber: '9745660899',
    //         FM09_Email: 'test@gmail.com'
    //     }
    // )
    const [data, setData] = useState({
        [TransactionDef.BranchId]: '',
        [TransactionDef.Date]: new Date().toISOString().slice(0, 10),
        [TransactionDef.TranType]: transType.expense,
        [TransactionDef.PartyID]: '',
        [TransactionDef.CurrencyID]: 1,
        [TransactionDef.BaseAmount]: '',
        [TransactionDef.ConversionRate]: 1,
        [TransactionDef.ConvertedAmount]: '',
        [TransactionDef.LedgerId]: '',
        [TransactionDef.AccountLedgerId]: '',
        [TransactionDef.FiscalYearID]: '',
        [TransactionDef.BaseAmount]: '',
        [TransactionDef.EntryEmployeeId]: '',
        [TransactionDef.IsActive]: 1,
        [TransactionDef.FT02_expected_received_date]: '',
        [TransactionDef.CreatedBy]: user[employeeDef.EmployeeId],
        [TransactionDef.SysCode]: '',
        [TransactionDef.Description]: "",
        [TransactionDef.IsApprove]: 1,
        [TransactionDef.ApprovedBy]: user[employeeDef.EmployeeId],
        [ledgerPostingDef.Debit]: '',
        [ledgerPostingDef.Credit]: '',
        [ledgerPostingDef.RefTable]: 'T02',
        [ledgerPostingDef.ChequeNo]: '',
        [ledgerPostingDef.ChequeDate]: '',
        [ledgerPostingDef.CreatedDate]: '',
        [ledgerPostingDef.ApprovedDate]: '',
        [ledgerPostingDef.IsCancel]: '',
        [ledgerPostingDef.CanceledBy]: user[employeeDef.EmployeeId],
        [ledgerPostingDef.CanceledDate]: '',
        [TransactionDef.Is_pinned]: 0,
    })




    const validationObj = {
        [TransactionDef.BaseAmount]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
                (value) => {
                    const number = Number(value);
                    if (Number.isFinite(number) && number >= 0) {
                        return true
                    } else {
                        return 'Not valid'
                    }
                },
            ]
        },
        [TransactionDef.Date]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [TransactionDef.TranType]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [TransactionDef.LedgerId]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [TransactionDef.Description]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [TransactionDef.AccountLedgerId]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },


    }

    const performValidation = (obj = {}) => {

        let errObj = {}
        let errNotOccured = true;

        for (const key in validationObj) {

            let isPass = true;

            for (let i = 0; i < validationObj[key].validaton.length; i++) {
                isPass = validationObj[key].validaton[i](obj[key])
                if (isPass !== true) {
                    errNotOccured = false
                    errObj[key] = isPass;
                    break;
                }
            }



        }
        // console.log(err)

        setErrDatas(errObj)
        return errNotOccured;

    }

    const onChangeInput = (event) => {

        let valueObj
        valueObj = {
            ...data,
            [event.target.name]: event.target.value
        }

        setData(valueObj)


    }
    const onChangeInputParty = (event) => {


        let valueObj
        valueObj = {
            ...data,
            [event.target.name]: event.target.value
        }

        setData(valueObj)

        // loadBalance(event.target.value).then(
        //     res => setBalance(res.data.totalBalance ? res.data.totalBalance : 0)
        // )


    }

    const clearData = () => {
        setData({
            [TransactionDef.BranchId]: '',
            [TransactionDef.Date]: new Date().toISOString().slice(0, 10),
            [TransactionDef.TranType]: transType.expense,
            [TransactionDef.PartyID]: '',
            [TransactionDef.CurrencyID]: 1,
            [TransactionDef.BaseAmount]: '',
            [TransactionDef.ConversionRate]: 1,
            [TransactionDef.ConvertedAmount]: '',
            [TransactionDef.LedgerId]: '',
            [TransactionDef.AccountLedgerId]: '',
            [TransactionDef.FiscalYearID]: '',
            [TransactionDef.BaseAmount]: '',
            [TransactionDef.EntryEmployeeId]: '',
            [TransactionDef.IsActive]: 1,
            [TransactionDef.FT02_expected_received_date]: '',
            [TransactionDef.CreatedBy]: user[employeeDef.EmployeeId],
            [TransactionDef.SysCode]: '',
            [TransactionDef.Description]: "",
            [TransactionDef.IsApprove]: 1,
            [TransactionDef.ApprovedBy]: user[employeeDef.EmployeeId],
            [ledgerPostingDef.Debit]: '',
            [ledgerPostingDef.Credit]: '',
            [ledgerPostingDef.RefTable]: 'T02',
            [ledgerPostingDef.ChequeNo]: '',
            [ledgerPostingDef.ChequeDate]: '',
            [ledgerPostingDef.CreatedDate]: '',
            [ledgerPostingDef.ApprovedDate]: '',
            [ledgerPostingDef.IsCancel]: '',
            [ledgerPostingDef.CanceledBy]: user[employeeDef.EmployeeId],
            [ledgerPostingDef.CanceledDate]: '',
            [TransactionDef.Is_pinned]: 0,
        })
        setBalance('')
        setErrDatas({})
    }

    // const loadBalance = async (id) => {
    //     setIsLoading(true)
    //     try {
    //         const res = await api.get({
    //             url: `${process.env.REACT_APP_BACKEND_URL}/api/partybalance`,
    //             params: {
    //                 FT02_M09_PartyID: id
    //             }
    //         })

    //         if (res?.data?.success === true) {
    //             setIsLoading(false)
    //             return res.data

    //         } else {
    //             showErrorToast({
    //                 message: res?.data?.message || 'Error loading party balance',
    //                 timeOut: 5000
    //             })
    //         }

    //     } catch (error) {
    //         console.log(error)
    //         showErrorToast({
    //             message: 'Internal Error !!!',
    //             showAtTimeOne: true,
    //             id: 'INTERNAL ERROR',
    //             timeOut: 5000,
    //             showAtTimeOne: true,
    //             id: 'uniqueIdParySelect'
    //         })
    //     }
    //     setIsLoading(false)
    // }

    const addRole = async () => {
        setIsLoading(true)
        console.log(data)
        try {

            if (performValidation(data)) {
                data[TransactionDef.BaseAmount] = Number(data[TransactionDef.BaseAmount])
                data[TransactionDef.ConvertedAmount] = data[TransactionDef.BaseAmount] * data[TransactionDef.ConversionRate]
                const res = await api.post({
                    url: `${process.env.REACT_APP_BACKEND_URL}/api/expense`,
                    body: data
                });

                if (res?.data?.success === true) {
                    showToast({
                        message: res.data.message || 'Transaction added'
                    })
                    clearData()
                    closeModal();
                    updateUi();
                } else {
                    if (res && res.data) {
                        const res1 = res.data;
                        if (res1.error) {
                            const error = {};
                            for (let key in res1.error) {
                                error[key] = res1.error[key][0]
                            }
                            console.log(error)
                            setErrDatas(error)
                        }
                    }
                    showErrorToast({
                        message: res?.data?.message || 'Error adding transaction',
                        timeOut: 5000
                    })
                }



            } else {
                showErrorToast({
                    message: 'Please fill the form',
                    timeOut: 2000
                })
            }

        } catch (err) {

            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR',
                timeOut: 5000
            })

        }
        setIsLoading(false)
    }



    useEffect(
        () => {
            async function initialize() {
                setIsLoading(true)
                try {

                    const res2 = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/ledgerlist_expenses`
                    })
                    if (res2?.data?.success === true) {
                        setExpenseLedgers(res2.data.data.map(items => ({
                            name: items[accountLedgerDef.LedgerName],
                            value: items[accountLedgerDef.AccountLedgerId],
                        })))
                    } else {
                        showErrorToast({
                            message: res2.data.message
                        })
                        setIsLoading(false)
                        return;
                    }
                    setIsLoading(true)
                    const res1 = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/ledgerlist`
                    })
                    if (res1.data.success === true) {
                        setAssetLedgers(res1.data.data.map(items => ({
                            name: items[accountLedgerDef.LedgerName],
                            value: items[accountLedgerDef.AccountLedgerId],
                        }))
                        )
                    } else {
                        showErrorToast({
                            message: res1.data.message
                        })
                        setIsLoading(false)
                        return;
                    }

                } catch (error) {
                    showErrorToast({
                        message: 'Internal Error !!!',
                        showAtTimeOne: true,
                        id: 'INTERNAL ERROR'
                    })
                    console.log('error')
                }
                setIsLoading(false)

            }
            if (show === true) {

                initialize();

            }
        }, [show]
    )


    return (
        <>
            <ModalLoading show={isLoading} zIndex={100} />

            <ModalStanderd
                show={show}
                title="Add Expense"
                closeModal={closeModal}
                closeClickOutside={false}
            >
                <ModalStanderd.Body>
                    <div className="sm:flex sm:items-start w-full">

                        <div className="mt-3 text-center  sm:mt-0 sm:text-left w-full pb-1">

                            <div className=" w-full flex flex-wrap gap-2 ">
                                <InputStd2 type='date' errMsg={errdatas[TransactionDef.Date]} className={' grow  basis-full sm:basis-[49%] '} placeholder={'name'} title={'Date'} value={data[TransactionDef.Date]} name={TransactionDef.Date} onChange={onChangeInput} />
                                <InputStd2 className={'grow  basis-full sm:basis-[49%]'} placeholder={'Amount'} title={'Amount'} errMsg={errdatas[TransactionDef.BaseAmount]} value={data[TransactionDef.BaseAmount]} name={TransactionDef.BaseAmount} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <SelectBoxInputBox option={expenseLedgers} errMsg={errdatas[TransactionDef.LedgerId]} className={' grow  basis-full sm:basis-auto'} placeholder={'Expense'} title={'Expense'} value={data[TransactionDef.LedgerId]} name={TransactionDef.LedgerId} onChange={onChangeInput} />
                                <SelectBoxInputBox option={assetLedgers} errMsg={errdatas[TransactionDef.AccountLedgerId]} className={' grow  basis-full sm:basis-auto'} placeholder={'Cash or bank'} title={'Cash or bank'} value={data[TransactionDef.AccountLedgerId]} name={TransactionDef.AccountLedgerId} onChange={onChangeInputParty} />
                                {/* <InputStd2 type='date' className={'grow'} placeholder={'date'} title={'Due date'} errMsg={errdatas[TransactionDef.FT02_expected_received_date]} value={data[TransactionDef.FT02_expected_received_date]} name={TransactionDef.FT02_expected_received_date} onChange={onChangeInput} /> */}
                                <div className=" basis-full"> </div>
                                <InputTextAreaStd2 className={'grow'} placeholder={'Description'} title={'Description'} errMsg={errdatas[TransactionDef.Description]} value={data[TransactionDef.Description]} name={TransactionDef.Description} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                {/* <SwitchInputStd2 states={{ enabled: 1, disabled: 0 }} title={'Pin'} value={data[TransactionDef.Is_pinned]} name={TransactionDef.Is_pinned} onChange={onChangeInput} /> */}

                            </div>
                        </div>
                    </div>
                </ModalStanderd.Body>
                <ModalStanderd.Bottom>
                    <SquareIconButton icon={<IconPlus />} text={'Add'} onClick={addRole} />
                    <SquareButton text={'Cancel'} varient={squareButtonVarient.outlined} onClick={closeModal} />
                </ModalStanderd.Bottom>
            </ModalStanderd>
        </>
    )

}
export function ModalBranchDetails({ show, closeModal, updateUi, fiscalFull, id }) {

    const [balance, setBalance] = useState('')
    const [errdatas, setErrDatas] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [allowEdit, setallowEdit] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const api = useRESTapi();
    const user = useSelector(state => state.user.user)
    const [expenseLedgers, setExpenseLedgers] = useState([])
    const [assetLedgers, setAssetLedgers] = useState([])
    const [ledgers, setLedgers] = useState([])
    const [editable, setEditable] = useState(false)

    const [data, setData] = useState({
        [TransactionDef.BranchId]: '',
        [TransactionDef.Date]: new Date().toISOString().slice(0, 10),
        [TransactionDef.TranType]: transType.expense,
        [TransactionDef.PartyID]: '',
        [TransactionDef.CurrencyID]: 1,
        [TransactionDef.BaseAmount]: '',
        [TransactionDef.ConversionRate]: 1,
        [TransactionDef.ConvertedAmount]: '',
        [TransactionDef.LedgerId]: '',
        [TransactionDef.AccountLedgerId]: '',
        [TransactionDef.FiscalYearID]: '',
        [TransactionDef.BaseAmount]: '',
        [TransactionDef.EntryEmployeeId]: '',
        [TransactionDef.IsActive]: 1,
        [TransactionDef.FT02_expected_received_date]: '',
        [TransactionDef.CreatedBy]: user[employeeDef.EmployeeId],
        [TransactionDef.SysCode]: '',
        [TransactionDef.Description]: "",
        [TransactionDef.IsApprove]: 1,
        [TransactionDef.ApprovedBy]: user[employeeDef.EmployeeId],
        [ledgerPostingDef.Debit]: '',
        [ledgerPostingDef.Credit]: '',
        [ledgerPostingDef.RefTable]: 'T02',
        [ledgerPostingDef.ChequeNo]: '',
        [ledgerPostingDef.ChequeDate]: '',
        [ledgerPostingDef.CreatedDate]: '',
        [ledgerPostingDef.ApprovedDate]: '',
        [ledgerPostingDef.IsCancel]: '',
        [ledgerPostingDef.CanceledBy]: user[employeeDef.EmployeeId],
        [ledgerPostingDef.CanceledDate]: '',
        [TransactionDef.Is_pinned]: 0,
    })


    const [initialData, setInitialData] = useState({})

    const validationObj = {
        [TransactionDef.BaseAmount]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
                (value) => {
                    const number = Number(value);
                    if (Number.isFinite(number) && number >= 0) {
                        return true
                    } else {
                        return 'Not valid'
                    }
                },
            ]
        },
        [TransactionDef.Date]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [TransactionDef.TranType]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [TransactionDef.LedgerId]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [TransactionDef.Description]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [TransactionDef.AccountLedgerId]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },


    }


    const performValidation = (obj = {}) => {

        let errObj = {}
        let errNotOccured = true;

        for (const key in validationObj) {

            let isPass = true;

            for (let i = 0; i < validationObj[key].validaton.length; i++) {
                isPass = validationObj[key].validaton[i](obj[key])
                if (isPass !== true) {
                    errNotOccured = false
                    errObj[key] = isPass;
                    break;
                }
            }



        }

        setErrDatas(errObj)
        return errNotOccured;

    }



    const onChangeInput = (event) => {

        let valueObj
        valueObj = {
            ...data,
            [event.target.name]: event.target.value
        }


        setData(valueObj)


    }

    const onChangeInputParty = (event) => {


        let valueObj
        valueObj = {
            ...data,
            [event.target.name]: event.target.value
        }

        setData(valueObj)


    }

    async function deleteTransaction() {
        setIsLoading(true)
        try {
            const res = await api.Delete({
                url: `${process.env.REACT_APP_BACKEND_URL}/api/transactions/${id}`
            });
            if (res?.data?.success === true) {
                showToast({
                    message: res.data?.message || 'Transaction Deleted'
                })
                closeTheModal();
                updateUi();
            } else {
                showErrorToast({
                    message: res?.data?.message || 'Error Deleting Transaction'
                })
            }

        } catch (error) {
            console.log(error)
            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR'
            })
        }
        setShowDeleteModal(false)
        setIsLoading(false)
    }


    const closeTheModal = () => {
        setShowDeleteModal(false)
        setallowEdit(false)
        clearData()
        closeModal()
    }


    const disAllowEdit = () => {
        setallowEdit(false);
        setErrDatas({})
        setData(initialData)
    }




    const clearData = () => {
        setData({
            [TransactionDef.BranchId]: '',
            [TransactionDef.Date]: new Date().toISOString().slice(0, 10),
            [TransactionDef.TranType]: transType.expense,
            [TransactionDef.PartyID]: '',
            [TransactionDef.CurrencyID]: 1,
            [TransactionDef.BaseAmount]: '',
            [TransactionDef.ConversionRate]: 1,
            [TransactionDef.ConvertedAmount]: '',
            [TransactionDef.LedgerId]: '',
            [TransactionDef.AccountLedgerId]: '',
            [TransactionDef.FiscalYearID]: '',
            [TransactionDef.BaseAmount]: '',
            [TransactionDef.EntryEmployeeId]: '',
            [TransactionDef.IsActive]: 1,
            [TransactionDef.FT02_expected_received_date]: '',
            [TransactionDef.CreatedBy]: user[employeeDef.EmployeeId],
            [TransactionDef.SysCode]: '',
            [TransactionDef.Description]: "",
            [TransactionDef.IsApprove]: 1,
            [TransactionDef.ApprovedBy]: user[employeeDef.EmployeeId],
            [ledgerPostingDef.Debit]: '',
            [ledgerPostingDef.Credit]: '',
            [ledgerPostingDef.RefTable]: 'T02',
            [ledgerPostingDef.ChequeNo]: '',
            [ledgerPostingDef.ChequeDate]: '',
            [ledgerPostingDef.CreatedDate]: '',
            [ledgerPostingDef.ApprovedDate]: '',
            [ledgerPostingDef.IsCancel]: '',
            [ledgerPostingDef.CanceledBy]: user[employeeDef.EmployeeId],
            [ledgerPostingDef.CanceledDate]: '',
            [TransactionDef.Is_pinned]: 0,
        })
        setErrDatas({})
        setBalance('')
    }



    const addRole = async () => {
        setIsLoading(true)
        console.log(data)
        try {

            if (performValidation(data)) {

                const res = await api.put({
                    url: `${process.env.REACT_APP_BACKEND_URL}/api/expense/${id}`,
                    body: data
                },)

                if (res?.data?.success === true) {
                    showToast({
                        message: res.data.message || 'Transaction Edited'
                    })
                    clearData()
                    closeModal();
                    updateUi();
                } else {
                    if (res && res.data) {
                        const res1 = res.data;
                        if (res1.error) {
                            const error = {};
                            for (let key in res1.error) {
                                error[key] = res1.error[key][0]
                            }
                            console.log(error)
                            setErrDatas(error)
                        }
                    }
                    showErrorToast({
                        message: res?.data?.message || 'Error Editing Transaction',
                        timeOut: 5000
                    })
                }

            } else {
                showErrorToast({
                    message: 'Please fill the form',
                    timeOut: 2000
                })
            }

        } catch (err) {
            console.log(err)
            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR',
            })
        }
        setIsLoading(false)
    }




    useEffect(
        () => {
            const initialize = async () => {

                setIsLoading(true)
                try {
                    const res = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/ledgerlist_expenses`
                    })
                    if (res.data.success === true) {
                        setExpenseLedgers(res.data.data.map(items => ({
                            name: items[accountLedgerDef.LedgerName],
                            value: items[accountLedgerDef.AccountLedgerId],
                        })))
                    } else {
                        showErrorToast({
                            message: res.data.message
                        })
                        setIsLoading(false)
                        return;
                    }
                    const res2 = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/ledgerlist`
                    })
                    if (res2?.data?.success === true) {
                        setAssetLedgers(res2.data.data.map(items => ({
                            name: items[accountLedgerDef.LedgerName],
                            value: items[accountLedgerDef.AccountLedgerId],
                        }))
                        )
                    } else {
                        showErrorToast({
                            message: res2.data.message
                        })
                        setIsLoading(false)
                        return;
                    }
                    const res3 = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/transactions/${id}`
                    });
                    if (res3?.data?.success === true) {
                        const employee = res3.data.data;
                        for (const key in employee) {
                            employee[key] = employee[key] === null || employee[key] === undefined ? '' : employee[key]
                            // employee[key] = undefined
                        }


                        if (employee[TransactionDef.Description] === 'Opening balance &^&%^%$%$#%%^%^%^%^^%') {
                            setEditable(false)
                            employee[TransactionDef.Description] = 'Opening Balance'
                        } else {
                            setEditable(true)
                        }


                        setData(employee)
                        setInitialData(employee)
                    } else {
                        showErrorToast({
                            message: res3.data?.message || 'Error loading branch'
                        })
                        closeModal()
                    }

                } catch (error) {
                    console.log(error)
                    showErrorToast({
                        message: 'Internal Error !!!',
                        showAtTimeOne: true,
                        id: 'INTERNAL ERROR'
                    })
                    closeModal()

                }

                setIsLoading(false)

            }
            if (show) {
                initialize();
            }
        }, [show]
    )



    return (
        <>
            <ModalLoading show={isLoading} zIndex={1000} />
            <QuestionModal show={showDeleteModal} Question="Do you want to delete transaction?" onNegative={() => setShowDeleteModal(false)} onPositive={deleteTransaction} />

            <ModalStanderd title="Expense Details" show={show} closeModal={closeModal} afterLeave={closeTheModal} >
                <ModalStanderd.Body>
                    <div className=" flex justify-end gap-1 px-1 pt-1">
                        {editable && <IconOnlyButton onClick={() => setallowEdit(true)} icon={<IconEdit />} />}
                        {editable && <IconOnlyButton onClick={() => setShowDeleteModal(true)} icon={<IconTrash />} />}
                    </div>
                    <div className="sm:flex sm:items-start w-full">

                        <div className="mt-3 text-center  sm:mt-0 sm:text-left w-full pb-1">

                            <div className=" w-full flex flex-wrap gap-2 ">
                                <InputStd2 disabled={!allowEdit} type='date' errMsg={errdatas[TransactionDef.Date]} className={' grow  basis-full sm:basis-[49%] '} placeholder={'name'} title={'Date'} value={data[TransactionDef.Date]} name={TransactionDef.Date} onChange={onChangeInput} />
                                <InputStd2 disabled={!allowEdit} className={'grow  basis-full sm:basis-[49%]'} placeholder={'Amount'} title={'Amount'} errMsg={errdatas[TransactionDef.BaseAmount]} value={data[TransactionDef.BaseAmount]} name={TransactionDef.BaseAmount} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <SelectBoxInputBox disabled={!allowEdit} option={expenseLedgers} errMsg={errdatas[TransactionDef.LedgerId]} className={' grow  basis-full sm:basis-auto'} placeholder={'Expense'} title={'Expense'} value={data[TransactionDef.LedgerId]} name={TransactionDef.LedgerId} onChange={onChangeInput} />
                                <SelectBoxInputBox disabled={!allowEdit} option={assetLedgers} errMsg={errdatas[TransactionDef.AccountLedgerId]} className={' grow  basis-full sm:basis-auto'} placeholder={'Cash or Bank'} title={'Cash or Bank'} value={data[TransactionDef.AccountLedgerId]} name={TransactionDef.AccountLedgerId} onChange={onChangeInputParty} />
                                {/* <InputStd2 disabled={!allowEdit} type='date' className={'grow'} placeholder={'date'} title={'Due date'} errMsg={errdatas[TransactionDef.FT02_expected_received_date]} value={data[TransactionDef.FT02_expected_received_date]} name={TransactionDef.FT02_expected_received_date} onChange={onChangeInput} /> */}
                                <div className=" basis-full"> </div>
                                <InputTextAreaStd2 disabled={!allowEdit} className={'grow'} placeholder={'Description'} title={'Description'} errMsg={errdatas[TransactionDef.Description]} value={data[TransactionDef.Description]} name={TransactionDef.Description} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                {/* <SwitchInputStd2 disabled={!allowEdit} states={{ enabled: 1, disabled: 0 }} title={'Pin'} value={data[TransactionDef.Is_pinned]} name={TransactionDef.Is_pinned} onChange={onChangeInput} /> */}

                            </div>
                        </div>
                    </div>
                </ModalStanderd.Body>
                <ModalStanderd.Bottom>
                    {
                        allowEdit ?
                            <><SquareButton className={'w-min'} text={'Edit'} onClick={addRole} />
                                <SquareButton text={'Cancel'} varient={squareButtonVarient.outlined} onClick={disAllowEdit} /></>
                            :
                            <SquareButton className={' w-[90px]'} text={'cancel'} onClick={closeModal} />}
                </ModalStanderd.Bottom>
            </ModalStanderd>
        </>
    )

}

