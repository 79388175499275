import { IconFileDownload, IconFilter, IconRotateClockwise2 } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SquareIconButton } from '../../../../../../components/button'
import { InputStd2, SelectBoxInputBox } from '../../../../../../components/inputs'
import MenuSimple from '../../../../../../components/menu'
import { TableMenu, TablePagination, TableStanderd } from '../../../../../../components/table'
import { showErrorToast } from '../../../../../../components/toast'
import { useRESTapi } from '../../../../../../controller/restAPI'
import { TransactionDef, accountLedgerDef, partyDef, squareButtonVarient, themeStateDef, transType } from '../../../../../../definitions/definintion'
import { setTopNavText } from '../../../../../../redux/config/configSlice'
import { formatNumberWithCommas, formattedDate } from '../../../../../../controller/format'

export function ExpenseReport() {

    const searchParamDef = {
        fromDate: 'fromDate',
        toDate: 'toDate',
        expense: 'ledgerId',
        type: TransactionDef.TranType
    }

    const { id } = useParams();

    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [selectedEmploye, setSelectedEmploye] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [updateUi, setUpdateUi] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [balance, setBalance] = useState()
    const [openingBalance, setOpeningBalance] = useState(null)
    const [closingBalance, setClosingBalance] = useState(null)
    const [searchedParty, setSearchedParty] = useState(true)
    const [searchParams, setSearchParams] = useState({
        [searchParamDef.fromDate]: '',
        [searchParamDef.toDate]: '',
        [searchParamDef.expense]: id === 'init' ? null : id
    })
    const [isDownloading, setIsDownloading] = useState(false)
    const api = useRESTapi()
    const [expenses, setExpense] = useState([])
    const [totalRow, setTotalRow] = useState(30)
    const [rowPerPage, setRowPerPage] = useState(30)
    const [pageNo, setpageNo] = useState(1)
    const onChangeSearchParams = (event) => {
        searchParams[event.target.name] = event.target.value;
        console.log(searchParams)
        setSearchParams({
            ...searchParams
        })
    }
    const [rows1, setRow1] = useState([
        // {
        //     id: '1',
        //     data: {
        //         [accountLedgerDef.LedgerName]: 'Abu',
        //         [accountLedgerDef.OpeningBalance]: '10000',
        //         [accountLedgerDef.CreditPeriod]: '12000',
        //         [accountLedgerDef.CreditLimit]: '13000',
        //         [accountLedgerDef.AccountGroupId]: 'sample',
        //         [accountLedgerDef.IsDefault]: 'sample',
        //         [accountLedgerDef.CrOrDr]: 'sample',
        //         [accountLedgerDef.Narration]: 'sample',
        //         [accountLedgerDef.MailingName]: 'sample',
        //         [accountLedgerDef.Address]: 'sample',
        //         [accountLedgerDef.Phone]: 'sample',
        //         [accountLedgerDef.Mobile]: 'sample',
        //         [accountLedgerDef.Email]: 'sample',
        //         [accountLedgerDef.IsActive]: 1,
        //     }
        // },
    ])
    const rebuildUi = () => {
        setUpdateUi(!updateUi)
    }
    const dispatch = useDispatch()

    const options = [
        {
            name: 'Download',
            // icon: <IconFileDownload />,
            icon: isDownloading === false ? <IconFileDownload /> : <IconRotateClockwise2 className=' animate-spin duration-100' />,
            state: 'download',
            link: '',
            onClick: async ({ closeMenu }) => {
                if (isDownloading === false) {
                    const fileName = 'Party_balance.xlsx';
                    setIsDownloading(true)
                    try {
                        const response = await api.get({
                            url: `${process.env.REACT_APP_BACKEND_URL}/api/partybalance`,
                            params: {
                                start_date: searchParams[searchParamDef.fromDate],
                                end_date: searchParams[searchParamDef.toDate],
                                [searchParamDef.party]: searchParams[searchParamDef.party],
                                export: 'excel'
                            },
                            responseType: 'blob',
                        });
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        closeMenu()

                    } catch (error) {
                        console.error('Download error:', error);
                        showErrorToast({
                            message: 'Error downloading !!!'
                        })

                    }
                    setIsDownloading(false)
                }

            }

        },
    ]

    useEffect(
        () => {

            const initialLoad = async () => {

                try {
                    setIsLoading(true)
                    const res1 = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/ledgerlist_expenses`
                    })
                    if (res1.data.success === true) {
                        setExpense([
                            ...res1.data.data.map(items => ({
                                name: items[accountLedgerDef.LedgerName],
                                value: items[accountLedgerDef.AccountLedgerId],
                            }))
                        ])
                    } else {
                        showErrorToast({
                            message: res1.data.message
                        })
                        setIsLoading(false)
                        return;
                    }
                    //IF PARTY SELECTED
                    // if (searchParams[searchParamDef.party]) {
                    const res = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/transactions`,
                        params: {
                            [searchParamDef.type]: transType.expense,
                            [searchParamDef.fromDate]: searchParams[searchParamDef.fromDate],
                            [searchParamDef.toDate]: searchParams[searchParamDef.toDate],
                            [searchParamDef.expense]: searchParams[searchParamDef.expense],
                            perPage: rowPerPage,
                            page: pageNo
                        }
                    });

                    if (res?.data?.success === true) {
                        const employees = res.data.data;
                        console.log(employees)
                        setTotalRow(res.data.totalTransactions)
                        const rows = employees.map(
                            (emp, index) => {
                                // emp = emp.transaction_details
                                emp[TransactionDef.SerialNo] = ((pageNo - 1) * rowPerPage) + (index + 1);
                                emp[partyDef.PartyName] = <div className=' whitespace-nowrap'>{emp.party?.[partyDef.PartyName]}</div>
                                emp['commaAmount'] = formatNumberWithCommas(emp[TransactionDef.ConvertedAmount])
                                emp['actualDescription'] = emp[TransactionDef.Description] === 'Opening balance &^&%^%$%$#%%^%^%^%^^%' ? 'Opening Balance' : emp[TransactionDef.Description]
                                emp['dateFormatted'] = <div className=' whitespace-nowrap'>{formattedDate(emp[TransactionDef.Date])}</div>
                                emp[TransactionDef.FT02_expected_received_date] = <div className=' whitespace-nowrap'>{formattedDate(emp[TransactionDef.FT02_expected_received_date])}</div>

                                return ({
                                    id: emp[TransactionDef.TransactionID],
                                    data: emp
                                })
                            }
                        )
                        setRow1(rows)
                    } else {
                        showErrorToast({
                            message: res?.data?.message || 'something went wrong',
                            showAtTimeOne: true,
                            id: 'transactionReport',
                            timeOut: 5000
                        })
                        setIsLoading(false)
                        return;
                    }

                    const res2 = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/expense_report`,
                        params: {
                            // [searchParamDef.type]: searchParams[searchParamDef.type],
                            start_date: searchParams[searchParamDef.fromDate],
                            end_date: searchParams[searchParamDef.toDate],
                            [searchParamDef.expense]: searchParams[searchParamDef.expense],

                        }
                    });

                    if (res2?.data?.success === true) {
                        const balance = res2.data.data.totalExpense;
                        setBalance(balance)
                        if (searchParams[searchParamDef.toDate].length > 3 || searchParams[searchParamDef.fromDate].length > 3) {
                            setClosingBalance(res2.data.data.closingBalance);
                            setOpeningBalance(res2.data.data.openingBalance);
                        } else {
                            setOpeningBalance(null)
                            setClosingBalance(null)
                            setOpeningBalance(null)
                        }
                        // setSearchedParty(res2.data.data.partyId)

                    } else {
                        showErrorToast({
                            message: res2?.data?.message || 'something went wrong',
                            showAtTimeOne: true,
                            id: 'transactionReport',
                            timeOut: 5000
                        })
                        setIsLoading(false)
                        return;
                    }

                    // }

                } catch (error) {
                    console.log(error)
                    showErrorToast({
                        message: 'Internal Error !!!',
                        showAtTimeOne: true,
                        id: 'INTERNAL ERROR',
                        showAtTimeOne: true,
                        id: 'transactionReport',
                        timeOut: 5000
                    })
                }
                setIsLoading(false)
            }

            initialLoad()
            dispatch(setTopNavText('Expense Report'))
            return () => {
                dispatch(setTopNavText(''))
            }

        }, [updateUi, pageNo]
    )

    const headers = [


        {
            id: '4',
            name: 'No',
            label: TransactionDef.SerialNo
        },
        {
            id: '1',
            name: 'Date',
            label: 'dateFormatted'
        },
        {
            id: '6',
            name: 'Description',
            label: 'actualDescription'
        },
        {
            id: '5',
            name: 'Amount',
            label: 'commaAmount'
        },
        {
            id: '7',
            name: '',
            label: 'deleteButton'
        },

    ]


    const openDetailsModal = (id, index) => {
        setSelectedEmploye(id)
        setShowDetailsModal(true)
    }

    const theme = useSelector(
        state => state.config.theme
    )

    const elements = [
        { delay: -1600 },
        { delay: -1550 },
        { delay: -1500 },
        { delay: -1450 },
        { delay: -1400 },
        { delay: -1350 },
        { delay: -1300 },
        { delay: -1250 },
        { delay: -1200 },
        { delay: -1150 },
        { delay: -1100 },
        { delay: -1050 },
        { delay: -1000 },
    ];


    return (
        <div className=' grow flex flex-col overflow-hidden'>
            {
                searchedParty
                    ?
                    <div className='flex justify-end items-center gap-2 '>

                        <MenuSimple
                            closeInsideTouch={false}
                            show={showFilterModal}
                            closeMenu={() => setShowFilterModal(false)}
                            top={32}
                            right={0}
                            label={<SquareIconButton onClick={() => setShowFilterModal(true)} varient={squareButtonVarient.outlined} icon={<IconFilter />} text={'Filter'} />}
                        >
                            <div className=' max-w-60 w-60 flex flex-col py-3'>
                                <div className=' text-sm font-bold flex pb-1 justify-start w-full'>By Date</div>
                                <div className='flex flex-col ps-1'>
                                    <InputStd2 title='From' type='date' onChange={onChangeSearchParams} value={searchParams[searchParamDef.fromDate]} name={searchParamDef.fromDate} />
                                    <InputStd2 title='To' onChange={onChangeSearchParams} value={searchParams[searchParamDef.toDate]} name={searchParamDef.toDate} type='date' />
                                </div>
                                <div className=' text-sm font-bold flex pb-1 justify-start w-full pt-2'>By Expense</div>
                                <div className='ps-1'>
                                    <SelectBoxInputBox option={expenses} onChange={onChangeSearchParams} value={searchParams[searchParamDef.expense]} name={searchParamDef.expense} className={' grow  basis-full sm:basis-auto'} placeholder={'Expense'} title={'Expense'} />
                                </div>
                                <div className=' w-full flex justify-end pt-2'>
                                    <SquareIconButton text={'Search'} onClick={() => { rebuildUi(); setShowFilterModal(false); setpageNo(1); }} />
                                </div>
                            </div>
                        </MenuSimple>
                        <TableMenu options={options} />

                    </div>
                    : null
            }
            <div className='pt-1 pb-5 grow  overflow-hidden flex flex-col gap-2' >
                {isLoading === false
                    ?

                    searchedParty
                        ?
                        <div className=" flex overflow-hidden relative shadow-md sm:rounded-lg">
                            <TableStanderd>
                                <TableStanderd.Head>
                                    {
                                        headers.map(
                                            header => (<th key={header.id} scope="col" className="py-3 px-6 whitespace-nowrap">{header.name}</th>)
                                        )
                                    }
                                </TableStanderd.Head>
                                <TableStanderd.Body>
                                    {/* OPENING BALANCE */}
                                    {
                                        openingBalance !== null &&
                                        <TableStanderd.TbRow coloured={true}>
                                            {
                                                <>
                                                    <TableStanderd.Td  ></TableStanderd.Td >
                                                    {/* <TableStanderd.Td  ></TableStanderd.Td > */}
                                                    <TableStanderd.Td colSpan={3}  >Opening Amount</TableStanderd.Td >
                                                    <TableStanderd.Td  >{formatNumberWithCommas(openingBalance)}</TableStanderd.Td >
                                                    {/* <TableStanderd.Td  ></TableStanderd.Td > */}
                                                </>
                                            }

                                        </TableStanderd.TbRow>
                                    }
                                    {/* TRANSACTION */}
                                    {
                                        rows1.length > 0
                                            ?
                                            rows1.map(
                                                (row, index) => {

                                                    return (
                                                        <TableStanderd.TbRow onClick={() => openDetailsModal(row.id, index)} key={row.id} className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 hover:dark:bg-gray-600`}>
                                                            {
                                                                headers.map(
                                                                    (header, index) => (
                                                                        <TableStanderd.Td key={index} >{
                                                                            header.label === TransactionDef.TranType
                                                                                ?
                                                                                row.data[header.label] === transType.reciept
                                                                                    ?
                                                                                    'reciept'
                                                                                    :
                                                                                    'payment'
                                                                                :
                                                                                row.data[header.label]}</TableStanderd.Td>
                                                                    )
                                                                )
                                                            }

                                                        </TableStanderd.TbRow>
                                                    )
                                                }
                                            )
                                            :
                                            <TableStanderd.TbRow>
                                                <TableStanderd.Td colSpan={headers.length}  ><div className='w-full text-center'>No transactions for {expenses[expenses.findIndex(item => item.value == searchedParty)]?.name}</div></TableStanderd.Td >
                                            </TableStanderd.TbRow>
                                    }
                                    {/* CLOSING BALANCE */}
                                    {
                                        closingBalance !== null &&
                                        <TableStanderd.TbRow coloured={true}>
                                            {
                                                <>
                                                    <TableStanderd.Td  ></TableStanderd.Td >
                                                    {/* <TableStanderd.Td  ></TableStanderd.Td > */}
                                                    <TableStanderd.Td colSpan={3}  >Total Amount</TableStanderd.Td >
                                                    <TableStanderd.Td  >{formatNumberWithCommas(closingBalance)}</TableStanderd.Td >
                                                    {/* <TableStanderd.Td  ></TableStanderd.Td > */}

                                                </>
                                            }

                                        </TableStanderd.TbRow>
                                    }
                                    {/* TOTAL BALANCE */}
                                    {
                                        <TableStanderd.TbRowEnd
                                            coloured={true}

                                        >
                                            {
                                                headers.map(
                                                    (header, index) => {
                                                        if (index === headers.length - 1) {
                                                            return <TableStanderd.Td key={index} >
                                                                <div className={`${Number(balance) > 0
                                                                    ?
                                                                    'text-lime-200'
                                                                    :
                                                                    Number(balance) < 0
                                                                        ?
                                                                        'text-red-300'
                                                                        :
                                                                        ''} font-extrabold text-base`}>
                                                                    {formatNumberWithCommas(balance)}
                                                                </div>
                                                            </TableStanderd.Td >
                                                        }
                                                        if (index === headers.length - 2) {
                                                            return <TableStanderd.Td key={index} >Closing Balance</TableStanderd.Td >
                                                        }
                                                        return <TableStanderd.Td key={index} ></TableStanderd.Td >
                                                    }
                                                )
                                            }

                                        </TableStanderd.TbRowEnd>
                                    }
                                </TableStanderd.Body>
                            </TableStanderd>
                        </div>
                        :
                        <div className=' flex flex-col gap-3 grow dark:text-gray-300 text-3xl font-bold  items-center justify-center theme-smooth'>
                            {/* SELECT PARTY */}
                            {/* {searchedParty && <div>No party found</div>} */}
                            <div className=' flex flex-col justify-center items-center bg-white dark:bg-primaryy-dark-950 theme-smooth border-2 p-3 rounded-lg gap-3'>
                                <div className=' max-w-60 w-60 flex flex-col py-3'>
                                    <div className=' text-sm font-bold flex pb-1 justify-start w-full text-gray-900 dark:text-gray-200  theme-smooth '>By Date</div>
                                    <div className='flex flex-col ps-1'>
                                        <InputStd2 title='From' type='date' onChange={onChangeSearchParams} value={searchParams[searchParamDef.fromDate]} name={searchParamDef.fromDate} />
                                        <InputStd2 title='To' onChange={onChangeSearchParams} value={searchParams[searchParamDef.toDate]} name={searchParamDef.toDate} type='date' />
                                    </div>
                                    <div className=' text-sm font-bold flex pb-1 justify-start w-full pt-2 text-gray-900 dark:text-gray-200  theme-smooth '>By Expense</div>
                                    <div className='ps-1'>
                                        <SelectBoxInputBox option={expenses} onChange={onChangeSearchParams} value={searchParams[searchParamDef.party]} name={searchParamDef.party} className={' grow  basis-full sm:basis-auto'} placeholder={'Expense'} title={'Expense'} />
                                    </div>
                                    <div className=' w-full flex justify-end pt-2'>
                                        <SquareIconButton text={'Search'} onClick={() => { rebuildUi(); setShowFilterModal(false) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                    <div className=' grow flex flex-col gap-2'>


                        {
                            elements.map(
                                (element, index) => (
                                    <div
                                        key={index}
                                        className={`w-full ${index === 0 ? 'h-16' : 'h-11'} bg-gray-600 rounded-md animate-bubblee`}
                                        style={{ animationDelay: `${element.delay}ms` }}
                                    ></div>
                                )
                            )
                        }



                    </div>
                }
                <TablePagination selectedPage={pageNo} totalRows={totalRow} rowsPerPage={rowPerPage} onClickIndex={(index) => setpageNo(index)} />
            </div>
        </div>
    )
}
