import { Listbox, Switch, Transition } from "@headlessui/react"
import { IconCheck, IconEyeOff, IconSelector, IconSquare, IconSquareCheckFilled, IconX } from "@tabler/icons-react"
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from 'react-dom'
import './input.css'
import { IconEye } from "@tabler/icons-react"
import { squareButtonVarient, themeStateDef } from "../definitions/definintion"
import { SquareButton } from "./button"
import { useSelector } from "react-redux"
export function LoginInput(
    {
        label = 'Label',
        className = '',
        type,
        name,
        value,
        onChange,
        errMsg
    }
) {

    const [hidePassword, setHidePassword] = useState(true)

    const toggleShowPassword = () => {
        setHidePassword(!hidePassword)
    }



    return (
        <div className={`hideNormalInputBehavour relative h-9 w-full min-w-[100%] my-2 ${className} `}>
            <input
                className={`${errMsg ? 'dark:border-red-600 border-red-300 text-red-700 dark:text-red-200 placeholder-shown:border-red-300 focus:border-red-500 caret-red-500' : 'dark:border-gray-600 border-gray-300 text-gray-700 dark:text-gray-200 placeholder-shown:border-gray-300 focus:border-primaryy-500 caret-gray-500'} peer h-full w-full border-b    bg-transparent pt-[10px] pb-1.5 font-sans text-sm font-normal  outline outline-0 theme-smooth  focus:outline-0 disabled:border-0 disabled:bg-gray-50 `}
                placeholder=" "
                type={hidePassword ? type : 'text'}
                name={name}
                value={value}
                onChange={(e) => onChange(e)}
            />
            <label className={`after:content[' ']  theme-smooth pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-[0]  transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 ${errMsg ? 'after:border-red-500 peer-placeholder-shown:text-red-500 peer-focus:text-red-500 peer-focus:after:border-red-500 peer-disabled:peer-placeholder-shown:text-red-500 text-red-500' : 'after:border-primaryy-500 text-gray-500 peer-placeholder-shown:text-gray-500 peer-focus:text-primaryy-500 peer-focus:after:border-primaryy-500 peer-disabled:peer-placeholder-shown:text-gray-500'} after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25]  peer-focus:text-[11px] peer-focus:leading-[0]  peer-focus:after:scale-x-100  peer-disabled:text-transparent  `}>
                {label}
            </label>
            {
                type === 'password' ?
                    <div className="text-gray-400 absolute right-0 bottom-0" onClick={toggleShowPassword}>
                        {
                            hidePassword ?
                                <i className='bx bxs-show cursor-pointer'  ></i>
                                :
                                <i className='bx bxs-hide cursor-pointer'  ></i>
                        }
                    </div> :
                    null
            }
            {
                errMsg && <div className=" text-xs text-red-500">{errMsg}</div>
            }

        </div>
    )

}


export function InputStd({ title }) {
    return (
        <div className="hideNormalInputBehavour  mt-1">
            <div className="relative w-full  h-10">
                <input
                    className="peer w-full h-full bg-transparent text-gray-800 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-gray-300 placeholder-shown:border-t-gray-300 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-gray-300 focus:border-primaryy-500"
                    placeholder=" " /><label
                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-primaryy-500 peer-focus:font-semibold before:border-gray-300 peer-focus:before:!border-primaryy-500 after:border-gray-300 peer-focus:after:!border-primaryy-500">
                    {title}
                </label>
            </div>
        </div>
    )
}

export const InputStd2 = React.forwardRef(({ errMsg = null, title, placeholder, className, type = 'text', onChange = () => { }, name, disabled = false, value, autocomplete = "on", onClick = () => { },
    autoGeneratePassword = false,
    onAutoGenerate = () => { },
    inputProps = {},
    state='nothing'|'positive'|'negative'
}, ref) => {
    const [hidePassword, setHidePassword] = useState(true)

    const toggleShowPassword = () => {
        setHidePassword(!hidePassword)
    }

    const theme = useSelector(
        state => state.config.theme
    )

    return (
        <div className={`hideNormalInputBehavour   ${className}`}  >
            <div className={`relative w-full flex flex-col items-start gap-y-1`}>
                {title && <label htmlFor="" className={` ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} block text-gray-700 dark:text-gray-400 text-xs font-semibold`}>{title}</label>}
                <input
                    {...inputProps}
                    ref={ref}
                    className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} peer w-full h-9 bg-transparent dark:[color-scheme:dark] text-gray-800 font-normal text-xs placeholder:text-gray-500  dark:text-gray-300 outline outline-0 focus:outline-0 disabled:bg-gray-200 disabled:dark:bg-primaryy-dark-950  transition-all  border   px-3 py-2.5 rounded-[7px] ${errMsg ? 'border-red-300' : 'border-gray-300'} ${state==='nothing' ?'':state==='negative'? ' border-red-300  text-red-400' : state==='positive'? 'border-lime-300 text-lime-500':''} focus:border-transparent focus:ring-2  focus:ring-primaryy-500`}
                    placeholder={placeholder}
                    type={hidePassword ? type : 'text'}
                    onChange={(e) => onChange(e)}
                    name={name}
                    disabled={disabled}
                    value={value}
                    autoComplete={autocomplete}
                    onClick={onClick}
                />
                {
                    type === 'password' ?
                        <div className={` ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} text-gray-400 absolute top-1/2 translate-x-1/2 right-4`} onClick={toggleShowPassword}>
                            {
                                hidePassword ?
                                    <IconEye />
                                    :
                                    <IconEyeOff />
                            }
                        </div> :
                        null
                }
            </div>
            <div className=" flex grow pt-1 justify-end items-start">
                {
                    errMsg
                        ? <div className=" ps-1 grow text-start text-[0.6rem] text-red-500 font-semibold">{errMsg}</div>
                        : null
                }
                {autoGeneratePassword && disabled === false &&
                    <div className=' flex flex-col justify-end '>
                        <SquareButton text={'generate'} varient={squareButtonVarient.outlined} onClick={onAutoGenerate} />
                    </div>
                }
            </div>

        </div>
    )
}
)


export function InputTextAreaStd2({ errMsg = null, title, placeholder, className, type = 'text', onChange = () => { }, name, disabled, value, rows }) {
    return (
        <div className={`hideNormalInputBehavour ${className}`}>
            <div className="w-full flex flex-col items-start gap-y-1">
                <label htmlFor="" className=" block text-gray-700 dark:text-gray-400 text-xs font-semibold">{title}</label>
                <textarea
                    rows={rows}

                    className={`peer w-full bg-transparent text-gray-800 font-normal text-xs placeholder:text-gray-500  dark:text-gray-300 outline outline-0 focus:outline-0 disabled:bg-gray-200 disabled:dark:bg-primaryy-dark-950  transition-all  border   px-3 py-2.5 rounded-[7px] ${errMsg ? 'border-red-300' : 'border-gray-300'} focus:border-transparent focus:ring-2  focus:ring-primaryy-500`}
                    placeholder={placeholder}
                    type={type}
                    onChange={(e) => onChange(e)}
                    name={name}
                    disabled={disabled}
                    value={value}
                />
            </div>
            {
                errMsg
                    ? <div className=" ps-1 text-start text-[0.6rem] text-red-500 font-semibold">{errMsg}</div>
                    : null
            }
        </div>
    )
}


export function SelectInputStd2({
    items = [
        { name: 'sample 1', value: 'sample 1' },
        { name: 'sample 2', value: 'sample 2' },
    ],
    title,
    className,
    name,
    onChange = () => { },
    value,
    disabled = false,
    errMsg = null,
    defaultValue = { name: 'Select', value: '' }
}) {
    const [dropdownItem, setdropdownItem] = useState(
        items
    )
    const [selected, setSelected] = useState(defaultValue)

    const onSelect = (obj) => {
        setSelected(obj)
        const value = {
            target: {
                name: name,
                value: obj.value
            }
        }
        onChange(value)
    }

    useEffect(
        () => {

            if (value !== '' || value !== null || value !== undefined) {
                const selected = items.filter(item => item.value === value)[0]
                if (selected)
                    setSelected(selected)
            }

        }, [value]
    )

    // const selVal = items.filter(
    //     item=>item.value==value
    // )[0]

    return (
        <div className={`hideNormalInputBehavour  ${className}`}>
            <div className="w-full flex flex-col items-start gap-y-1">
                {/* <input type="text" style={{ visibility: "hidden", height: 0, width: 0 }} /> */}
                <label htmlFor="" className=" block text-gray-700 dark:text-gray-400 text-xs font-semibold">{title}</label>
                <Listbox value={{ name: 'male', value: 'male' }} onChange={onSelect} disabled={disabled}>
                    <div className="relative w-full">
                        <Listbox.Button className={`w-full h-9 bg-transparent text-gray-800 font-normal text-xs placeholder:text-gray-500  dark:text-gray-300 outline outline-0 focus:outline-0 disabled:bg-gray-200 disabled:dark:bg-primaryy-dark-950  transition-all  border   px-3 py-2.5 rounded-[7px] ${errMsg ? 'border-red-300' : 'border-gray-300'} focus:border-transparent focus:ring-2  focus:ring-primaryy-500`}>
                            <span className="block truncate text-start">{selected.name}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <IconSelector
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                {dropdownItem.map((person, personIdx) => (
                                    <Listbox.Option
                                        key={personIdx}
                                        className={({ active, selected }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${selected ? 'bg-primaryy-100 text-primaryy-600' : 'text-gray-800'} ${active ? 'bg-primaryy-100 text-gray-900' : 'text-gray-800'}`
                                        }
                                        value={person}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <span
                                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                >
                                                    {person.name}
                                                </span>
                                                {selected ? (
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primaryy-600">
                                                        <IconCheck className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            </div>
        </div>
    )

}


export function SwitchInputStd2({ title, states = {
    enabled: 1,
    disabled: 0
}, name, onChange, value, disabled = false }
) {
    const [enabled, setEnabled] = useState(value)

    const onChangeSwitch = (state) => {
        setEnabled(state);
        if (state === true) {
            onChange({
                target: {
                    name: name,
                    value: states.enabled
                }
            })
        } else {
            onChange({
                target: {
                    name: name,
                    value: states.disabled
                }
            })
        }
    }

    useEffect(
        () => {
            if (value === states.enabled) {
                setEnabled(true)
            } else {
                setEnabled(false)
            }
        }, [value]
    )

    return (
        <div className=" flex gap-2 items-center">
            <label htmlFor="" className=" block text-gray-700 dark:text-gray-400 text-xs font-semibold">{title}</label>

            <Switch
                disabled={disabled}
                checked={enabled}
                onChange={onChangeSwitch}
                className={`${enabled ? 'bg-primaryy-600' : 'bg-gray-300'}
          relative inline-flex h-[20px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className={`${enabled ? 'translate-x-6 bg-primaryy-200 ' : 'translate-x-0 bg-primaryy-600 '}
            pointer-events-none inline-block h-full w-[20px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
        </div>
    )
}


export function SelectBoxInputBox({
    option = [
        { value: 1, name: 'sample 1' },
        { value: 2, name: 'sample 2' },
    ],
    value = '',
    onChange = (value) => { },
    title,
    className = '',
    name,
    autocomplete = "on",
    errMsg,
    disabled = false
}) {

    const [selectedValue, setSelectedValue] = useState(value)
    const [selectedOption, setSelectedOption] = useState({
        name: ''
    })
    const [displayText, setDisplayText] = useState('')
    const [query, setQuery] = useState('')
    const elementRef = useRef(null);
    const elementInputRef = useRef(null);
    const elementSelectedEmpRef = useRef(null);
    const myDivRef = useRef();
    const [topO, setTop] = useState(0)
    const [leftO, setLeft] = useState(0)
    const [rightO, setRight] = useState(0)
    const [bottomO, setBottom] = useState(0)
    const [show, setShow] = useState(false)
    const position = {}
    const padd = 60;
    const [hoveredIndex,setHoveredIndex] = useState(0)

    const getElementPosition = () => {
        if (elementRef.current) {
            const rect = elementInputRef.current.getBoundingClientRect();
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
            const absoluteTop = rect.top + scrollTop;
            const absoluteLeft = rect.left + scrollLeft;
            const absoluteRight = window.innerWidth - rect.right + scrollLeft; // Corrected calculation
            const absoluteBottom = absoluteTop + padd; // Corrected calculation
            setLeft(absoluteLeft)
            setTop(absoluteTop)
            setBottom(absoluteBottom)
            setRight(absoluteRight)


        }
    };

    const selectOrDeselect = (item) => {


        setSelectedValue(item.value)
        // setDisplayText(item.name)
        onChange({
            target: {
                value: item.value,
                name: name
            }
        })
        setHoveredIndex(0)
        setShow(false)

    }

    //position value
    function performPositon() {
        position.top = topO
        position.right = rightO
        position.bottom = bottomO
        position.left = leftO
    }

    performPositon()

    const filteredPeople =
        query === ''
            ? option
            : option.filter((person) =>
                person.name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    const onChangeInput = (event) => {
        setHoveredIndex(0)
        setShow(true)
        setQuery(event.target.value);
        setDisplayText(event.target.value);
    }

    useEffect(
        () => {
            const handleKeyDown = (event) => {
                // console.log('Key down:', event.key);
                let ind=0;
                // console.log(filteredPeople.length)
                switch (event.key) {
                    case 'ArrowDown':
                        if(hoveredIndex>=filteredPeople.length-1){
                            ind= 0
                        }else{
                            ind = hoveredIndex+1
                        }


                        break;

                    case 'ArrowUp':
                        if(hoveredIndex<=0){
                            ind = filteredPeople.length-1
                        }else{
                            ind = hoveredIndex-1
                        }
                        break;

                    case 'Enter':
                        console.log(filteredPeople.length)
                        if(filteredPeople.length>0){
                            selectOrDeselect(filteredPeople[hoveredIndex])
                        }
                        break;
                
                    default:
                        break;
                }
                
                setHoveredIndex(ind)
                // Perform actions on keydown
            };
            // const div = myDivRef.current;

            if (show) {
                getElementPosition()
                window.addEventListener('keydown', handleKeyDown);
                // window.addEventListener('keyup', handleKeyUp);

                // Cleanup function to remove event listeners when component unmounts
                return () => {
                    window.removeEventListener('keydown', handleKeyDown);
                    // window.removeEventListener('keyup', handleKeyUp);
                };

            }
        }, [show, selectedValue,hoveredIndex,query]
    )

    useEffect(() => {

        function handleClickOutside(event) {
            if (myDivRef.current && !myDivRef.current.contains(event.target) && !elementInputRef.current?.contains(event.target) && !elementSelectedEmpRef.current?.contains(event.target)) {
                // event.preventDefault();
                // event.stopPropagation();
                setTimeout(
                    () => setQuery(''),
                    300
                )
                setShow(false)
            }
        }

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };

    }, [elementRef, myDivRef, elementInputRef])

    useMemo(
        () => {
            setSelectedValue(value)
        }, [value]
    )

    useEffect(
        () => {

            if (selectedValue !== '') {
                const item = option.filter(
                    item => item.value == selectedValue
                )[0]
                if (item) {
                    setSelectedOption(item)
                    if (show === false) {
                        setDisplayText(item.name)
                    }
                } else {
                    setSelectedValue('')
                }
            }

        }, [selectedValue, show]
    )

    

    return (

        <div className={className} ref={elementRef} >
            <div ref={elementInputRef}  >

                <InputStd2
                    inputProps={{
                        onFocus: () => setShow(true),
                        onBlur: () => setTimeout(() => { setShow(false); setQuery(''); }, 300)
                    }}
                    onClick={() => setShow(true)} disabled={disabled} autocomplete={autocomplete} title={title} placeholder={'select'} onChange={onChangeInput} value={displayText} errMsg={errMsg} />

            </div>
            {
                ReactDOM.createPortal(
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        show={show}
                    >
                        <div
                            className="fixed z-[100] overflow-hidden rounded-md shadow-3xl shadow-gray-900/30  border-2 border-gray-400 dark:border-gray-300"
                            style={{
                                top: window.innerHeight < (bottomO + 240) ? null : (topO + padd),
                                left: leftO,
                                right: rightO,
                                bottom: window.innerHeight <= (bottomO + 240) ? (window.innerHeight - bottomO + padd - 13) : null
                            }}
                            ref={myDivRef}
                        >
                            <div
                                className=" max-h-60  overflow-auto  bg-gray-100 dark:bg-primaryy-dark-950 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"

                            >
                                {filteredPeople.length === 0 && query !== ''
                                    ? (
                                        <div className="relative cursor-default select-none px-4 py-2 text-gray-700 dark:text-gray-300">
                                            Nothing found.
                                        </div>
                                    )
                                    :
                                    filteredPeople.length === 0
                                        ?
                                        <div className="relative cursor-default select-none px-4 py-2 text-gray-700 dark:text-gray-300">
                                            No items
                                        </div>
                                        :
                                        (
                                            filteredPeople.map((person, index) => {
                                                const isSelected = selectedValue === person.value
                                                return (
                                                    <div
                                                        key={person.value}
                                                        className={`relative cursor-default select-none py-2 px-3 
                                                        ${isSelected ? 'bg-primaryy-400 dark:bg-primaryy-dark-700 text-white ' : ''} 
                                                        ${hoveredIndex===index?'bg-primaryy-200 dark:bg-primaryy-dark-400':''}
                                                          text-gray-900 dark:text-gray-300 flex gap-2 `}
                                                        value={person}
                                                        onClick={() => selectOrDeselect(person)}
                                                        onMouseEnter={()=>setHoveredIndex(index)}
                                                        // onMouseLeave={()=>setHoveredIndex(0)}
                                                        
                                                    >

                                                        <span
                                                            className={`block truncate `}
                                                        >
                                                            {person.name}
                                                        </span>

                                                    </div>
                                                )
                                            }
                                            )
                                        )
                                }
                            </div>
                        </div>

                    </Transition>,
                    document.body
                )
            }
        </div>

    )


}

export function SelectBoxInputMultiBox({
    option = [
        { value: 1, name: 'sample 1' },
        { value: 2, name: 'sample 2' },
    ],
    value = [],
    onChange = (value) => { },
    title,
    noSelectedString = 'nothing selected',
    zIndex = 100,
    disabled = false,
    errMsg,
    name
}) {

    const [selectedValues, setSelectedValues] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [query, setQuery] = useState('')
    const [show, setShow] = useState(false)
    const inputRef = useRef(null);



    const selectOrDeselect = (item) => {

        let selectedOptionsTemp = []
        if (selectedValues.includes(item.value)) {
            selectedOptionsTemp = selectedOptions.filter(
                itemtemp => itemtemp.value !== item.value
            )
            selectedValues.splice(selectedValues.indexOf(item.value), 1)
        } else {
            selectedValues.push(item.value)
            selectedOptions.push(item)
            selectedOptionsTemp = [...selectedOptions]
        }

        setSelectedValues([...selectedValues])
        setSelectedOptions([...selectedOptionsTemp])
        onChange({
            target: {
                name: name,
                value: selectedValues
            }
        })

    }

    const selectOrDeselectAll = () => {

        if (selectedValues.length !== option.length) {

            const selectedValues = option.map(
                item => (item.value)
            )

            setSelectedValues(selectedValues)
            setSelectedOptions([...option])
            onChange({
                target: {
                    name: name,
                    value: selectedValues
                }
            })

        } else {
            setSelectedOptions([])
            setSelectedValues([])
            onChange({
                target: {
                    name: name,
                    value: []
                }
            })
        }

    }

    useEffect(
        () => {

            async function focusinput() {
                // while(inputRef.current===null);
                if (show) {
                    setTimeout(
                        () => {
                            inputRef.current?.focus();
                        }, 100
                    )
                }
            }

            focusinput()


        }, [show]
    )

    useEffect(() => {
        const opt = option.filter(
            items => {
                for (let x in value) {
                    if (value[x] === items.value) {
                        return true
                    }
                }
                return false
            }
        )
        setSelectedValues(value);
        setSelectedOptions(opt)
    }, [])







    const filteredPeople =
        query === ''
            ? option
            : option.filter((person) =>
                person.name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
        <div className=" flex flex-col gap-1">
            {title && <div className=" text-xs font-semibold text-gray-700 dark:text-gray-400 pb-">
                {title}
            </div>
            }
            <div className={`overflow-hidden border rounded-md ${errMsg && 'border-red-500'}  bg-gray-100 dark:bg-primaryy-dark-950`}>
                <div className=" p-1 flex flex-wrap gap-1  max-h-28 overflow-auto ">
                    {
                        selectedOptions.length > 0 ? selectedOptions.map(
                            (item, index) => (
                                <div key={index} className=" flex items-center gap-1 1 bg-gray-300 dark:bg-primaryy-dark-700 dark:text-gray-300 rounded-sm p-1">
                                    <div className=" max-w-24 text-nowrap overflow-hidden truncate">
                                        {item.name}
                                    </div>
                                    <div className=" cursor-pointer flex items-center justify-center 1 dark:bg-primaryy-dark-900 hover:dark:bg-primaryy-dark-950 hover:bg-gray-400 w-8 h-8" onClick={() => { if (disabled === false) selectOrDeselect(item) }}>
                                        <IconX />
                                    </div>
                                </div>
                            )
                        ) :
                            <div className=" p-2 dark:text-gray-300 text-xs">
                                {noSelectedString}
                            </div>
                    }
                </div>
            </div>
            {disabled === false && <div className="select w-min text-xs bg-gray-300 p-1 hover:bg-gray-400 dark:bg-primaryy-dark-800 dark:hover:bg-primaryy-dark-900 dark:text-gray-300 cursor-pointer" onClick={() => setShow(true)}>Select</div>}

            <div >
                {
                    <div >
                        <div onClick={() => setShow(true)} >

                        </div>
                        {
                            ReactDOM.createPortal(
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    show={show}
                                >

                                    <div
                                        className="fixed   bg-gray-700/80 flex flex-col justify-center items-center"
                                        style={{
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            zIndex: zIndex
                                        }}
                                    // ref={myDivRef}
                                    >

                                        <div className=" fixed inset-0"  ></div>
                                        <div style={{ transform: 'translateX(-50%)', top: '10px', left: '50%', zIndex: zIndex + 1 }} className=" fixed flex flex-col gap-3 bg-gray-50 dark:bg-primaryy-dark-950 px-2 md:px-5 pb-6 pt-9 rounded-md ">
                                            <div className=" absolute right-2 top-2">
                                                <div onClick={() => setShow(false)} className="closeButton  cursor-pointer hover:bg-gray-400 bg-gray-300 dark:bg-primaryy-dark-800 dark:hover:bg-primaryy-dark-900 dark:text-gray-300"><IconX /></div>

                                            </div>
                                            <div className="overflow-hidden border rounded-md  bg-gray-100 dark:bg-primaryy-dark-950 ">
                                                <div className=" p-1 flex flex-wrap gap-1 w-[90vw] md:w-[80vw] lg:w-[700px] max-h-28 overflow-auto ">
                                                    {
                                                        selectedOptions.length > 0 ? selectedOptions.map(
                                                            (item, index) => (
                                                                <div key={index} className=" flex items-center gap-1 1 bg-gray-300 dark:bg-primaryy-dark-700 dark:text-gray-300 rounded-sm p-1">
                                                                    <div className=" max-w-24 text-nowrap overflow-hidden truncate">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className=" cursor-pointer flex items-center justify-center 1 dark:bg-primaryy-dark-900 hover:dark:bg-primaryy-dark-950 hover:bg-gray-400 w-8 h-8" onClick={() => { if (disabled === false) selectOrDeselect(item) }}>
                                                                        <IconX />
                                                                    </div>
                                                                </div>
                                                            )
                                                        ) :
                                                            <div className=" p-2 dark:text-gray-300">
                                                                Selected items displays here
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            <InputStd2 ref={inputRef} placeholder={'Search'} onChange={(event) => { setQuery(event.target.value) }} value={query} />

                                            <div className=" relative w-full">
                                                <div className=" absolute top-0 left-0 right-0 bg-gray-100 dark:bg-primaryy-dark-950 overflow-hidden rounded-md shadow-3xl shadow-gray-900/30  border-2 border-gray-400 dark:border-gray-300 flex flex-col">
                                                    {filteredPeople.length === 0 && query !== '' ? null : <div className="flex p-1 justify-between ">
                                                        <div onClick={selectOrDeselectAll} className="selectAll text-xs bg-gray-300 p-1 hover:bg-gray-400 dark:bg-primaryy-dark-800 dark:hover:bg-primaryy-dark-900 dark:text-gray-300 cursor-pointer ">{selectedValues.length === option.length ? 'Deselect All' : 'Select All'}</div>
                                                    </div>}
                                                    <div
                                                        className=" max-h-60  overflow-auto   py-1 text-base  sm:text-sm"

                                                    >
                                                        {filteredPeople.length === 0 && query !== '' ? (
                                                            <div className="relative cursor-default select-none px-4 py-2 text-gray-700 dark:text-gray-300">
                                                                Nothing found.
                                                            </div>
                                                        ) : (
                                                            filteredPeople.map((person, index) => {
                                                                const isSelected = selectedValues.includes(person.value)
                                                                return (
                                                                    <div
                                                                        key={person.value}
                                                                        className={`relative cursor-default select-none py-2 px-3  hover:bg-primaryy-200 hover:dark:bg-primaryy-dark-700 text-gray-900 dark:text-gray-300 flex gap-2 `}
                                                                        value={person}
                                                                        onClick={() => selectOrDeselect(person)}
                                                                    >

                                                                        {
                                                                            isSelected ? <IconSquareCheckFilled /> : <IconSquare />
                                                                        }

                                                                        <span
                                                                            className={`block truncate `}
                                                                        >
                                                                            {person.name}
                                                                        </span>

                                                                    </div>
                                                                )
                                                            }
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </Transition>,
                                document.body
                            )
                        }
                    </div>

                }
            </div>
        </div>

    )


}