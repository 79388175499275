import { createSlice } from "@reduxjs/toolkit";
import { themeDef, themeStateDef } from "../../definitions/definintion";


const initialState = {
    mainBodyBreakPoint:{
        breakPoint:'xxl',
        size:1024
    }
}




const responsiveSlice = createSlice(
    {
        name: 'responsive',
        initialState: initialState,
        reducers: {
            setMainbreakPoint: (state, action) => {
                state.mainBodyBreakPoint.breakPoint = action.payload.breakPoint
                state.mainBodyBreakPoint.size = action.payload.size
            },

        }
    }
)


export const responsiveReducer = responsiveSlice.reducer;
export const { setMainbreakPoint } = responsiveSlice.actions;