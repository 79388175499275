import React, { useState } from "react";
import { SideNavBar } from "./components/sidenavbar";
import { SettingsBar } from "./components/settingsBar";
import { ShopOverview } from "./components/shopOverview";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { BodyWrapperMain } from "../../components/layouts";
import { MainTopNav } from "../../components/topNavs";
import { Dashboard } from "./dashboard.page";
import { Managment } from "./managements/management.page";

export function Home(props) {

    const [selectedTab,setSelectedTab] = useState(0)
    // const routes = [
    //     {
    //         path:'dashboard',
    //         component:<Dashboard />
    //     },
    //     {
    //         path:'notification',
    //         component:<>notification</>
    //     },
    //     {
    //         path:'settings',
    //         component:<>settings</>
    //     },
    // ]



    return (
        <div className=" flex flex-col md:flex-row h-full  ">
            {/* <SideNavBar selectedTab={selectedTab}/> */}
            <BodyWrapperMain>
                <MainTopNav />
                <Routes>
                    <Route index element={<Navigate to={'managements'} replace />} />
                    {/* {
                        routes.map(
                            (route,index)=><Route key={index} path={route.path} Component={()=>{setSelectedTab(index); return (route.component)}} />
                        )
                    } */}
                </Routes>
            </BodyWrapperMain>
        </div>
    );
}

