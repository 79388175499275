import React from 'react'
import { BodyWrapperMain } from '../components/layouts'
import { SquareIconButton } from '../components/button'
import { IconRotate } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export function NetworkError() {
  const navigate = useNavigate()
  const href = useSelector(
    state=>state.config.navigation.tryAgainHref
  )
  return (
    <BodyWrapperMain>
        <div className=' h-full w-full flex flex-col justify-center items-center gap-2 '>
            <div className=' text-4xl font-black theme-smooth dark:bg-gray-200'>Netwok Error</div>
            <SquareIconButton icon={<IconRotate/>} text={'Try Again'}
              onClick={()=>{
                navigate(href)
              }}
             ></SquareIconButton>
        </div>
    </BodyWrapperMain>
  )
}
