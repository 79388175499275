import React, { useState } from 'react'
import MenuSimple from '../../../../../../../components/menu'
import { IconQuestionMark } from '@tabler/icons-react'
import axios from 'axios';
import { useRESTapi } from '../../../../../../../controller/restAPI';
import { Link } from 'react-router-dom';
import { showErrorToast } from '../../../../../../../components/toast';

// export function DownloadFile() {

//     const handleDownloadClick = () => {
//         const fileName = 'Transaction_sample.xlsx'; // Replace with your file name
//         const fileUrl = `/assets/${fileName}`;

//         const link = document.createElement('a');
//         link.href = fileUrl;
//         link.download = fileName;

//         // Append the link to the document body
//         document.body.appendChild(link);

//         // Trigger the click event to initiate the download
//         link.click();

//         // Clean up by removing the temporary anchor element
//         document.body.removeChild(link);
//     };

//     return (
//         <div onClick={handleDownloadClick} className=' text-primaryy-600 underline hover:text-primaryy-500 cursor-pointer text-right text-sm'>Download format</div>
//     );
// }
export function DownloadFile() {

    const fileName = 'Transaction_sample.xlsx'; // Replace with your file name
    const fileUrl = `/assets/${fileName}`;


    return (
        <Link to={fileUrl} target="_blank" download className=' text-primaryy-600 underline hover:text-primaryy-500 cursor-pointer text-right text-sm'>Download format</Link>
    );
}

export function DownloadFile1() {

    const api = useRESTapi();

    const handleDownloadClick = async () => {
        const fileName = 'Transaction_sample.xlsx';

        try {
            const response = await api.get({
                url: `${process.env.REACT_APP_BACKEND_URL}/api/downloadfile`,
                responseType: 'blob',
                // withCredentials:true
            })
            // console.log(response.headers['Content-Disposition'])

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.error('Download error:', error);
            showErrorToast({
                message:'Error downloading !!!'
            })
            // Handle error, e.g., display a message
        }
    };

    return (
        <div onClick={handleDownloadClick} className=' text-primaryy-600 underline hover:text-primaryy-500 cursor-pointer text-right text-sm'>Download format</div>
    );
}
export default function HelperButton() {

    const [open, setOpen] = useState(false)


    return (
        <div className=' fixed right-3 bottom-3 '>
            <MenuSimple
                show={open}
                closeMenu={() => { setOpen(false) }}
                contentClassName={' !p-0 overflow-hidden rounded-md'}
                label={<div onMouseEnter={() => setOpen(true)} className='w-10 h-10 flex items-center justify-center bg-primaryy-500 text-white rounded-full hover:shadow-md cursor-pointer'><IconQuestionMark /></div>}
                right={0}
                // left={0}
                bottom={40}
            >
                <div onMouseLeave={() => setOpen(false)} className=' text-xs   max-w-40 px-3 py-2 flex flex-col gap-1'>
                    <div className=' w-full dark:text-white theme-smooth '>You must upload the table in correct format. Check your format by clicking link below</div>
                    {<DownloadFile />}
                </div>
            </MenuSimple>
        </div>
    )
}
