import { IconFilter, IconPlus, IconTrash } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SquareIconButton } from '../../../../../../components/button'
import { InputStd2, SelectBoxInputBox } from '../../../../../../components/inputs'
import MenuSimple from '../../../../../../components/menu'
import { TablePagination, TableStanderd } from '../../../../../../components/table'
import { showErrorToast, showToast } from '../../../../../../components/toast'
import { useRESTapi } from '../../../../../../controller/restAPI'
import { TransactionDef, partyDef, squareButtonVarient, themeStateDef, transType } from '../../../../../../definitions/definintion'
import { setTopNavText } from '../../../../../../redux/config/configSlice'
import { ModalAddBranch, ModalBranchDetails } from './components/modals'
import { formatNumberWithCommas, formattedDate } from '../../../../../../controller/format'
import { getMonthRange } from '../../../../../../controller/dateTime'
import { QuestionModal } from '../../../../../../components/modal'
// import { formattedDate} from "../";

export function PaymentCRUD() {

    const searchParamDef = {
        fromDate: 'fromDate',
        toDate: 'toDate',
        party: TransactionDef.PartyID,
        type: TransactionDef.TranType
    }

    const [showAddModal, setShowAddModal] = useState(true)
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [selectedEmploye, setSelectedEmploye] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [updateUi, setUpdateUi] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [deleteId,setDeleteId] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [searchParams, setSearchParams] = useState({
        [searchParamDef.fromDate]: getMonthRange().startDate,
        [searchParamDef.toDate]: getMonthRange().endDate,
        [searchParamDef.party]: ''
    })
    const api = useRESTapi()
    const [parties, setParties] = useState([])
    const onChangeSearchParams = (event) => {
        searchParams[event.target.name] = event.target.value;
        console.log(searchParams)
        setSearchParams({
            ...searchParams
        })
    }
    const [totalRow, setTotalRow] = useState(90)
    const [rowPerPage, setRowPerPage] = useState(30)
    const [pageNo, setpageNo] = useState(1)
    const [rows1, setRow1] = useState([
        // {
        //     id: '1',
        //     data: {
        //         [accountLedgerDef.LedgerName]: 'Abu',
        //         [accountLedgerDef.OpeningBalance]: '10000',
        //         [accountLedgerDef.CreditPeriod]: '12000',
        //         [accountLedgerDef.CreditLimit]: '13000',
        //         [accountLedgerDef.AccountGroupId]: 'sample',
        //         [accountLedgerDef.IsDefault]: 'sample',
        //         [accountLedgerDef.CrOrDr]: 'sample',
        //         [accountLedgerDef.Narration]: 'sample',
        //         [accountLedgerDef.MailingName]: 'sample',
        //         [accountLedgerDef.Address]: 'sample',
        //         [accountLedgerDef.Phone]: 'sample',
        //         [accountLedgerDef.Mobile]: 'sample',
        //         [accountLedgerDef.Email]: 'sample',
        //         [accountLedgerDef.IsActive]: 1,
        //     }
        // },
    ])
    const rebuildUi = () => {
        setUpdateUi(!updateUi)
    }
    const dispatch = useDispatch()



    useEffect(
        () => {

            const initialLoad = async () => {

                try {
                    
                    setIsLoading(true)
                    const res1 = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/parties`
                    })
                    if (res1.data.success === true) {
                        setParties(res1.data.data.map(items => ({
                            name: items[partyDef.PartyName],
                            value: items[partyDef.PartyID],
                        }))
                        )
                    } else {
                        showErrorToast({
                            message: res1.data.message
                        })
                        setIsLoading(false)
                        return;
                    }

                    const res = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/transactions`,
                        params: {
                            [searchParamDef.type]: transType.payment,
                            [searchParamDef.fromDate]: searchParams[searchParamDef.fromDate],
                            [searchParamDef.toDate]: searchParams[searchParamDef.toDate],
                            [searchParamDef.party]: searchParams[searchParamDef.party],
                            perPage:rowPerPage,
                            page:pageNo
                        }
                    });

                    if (res?.data?.success === true) {
                        const employees = res.data.data;
                        setTotalRow(res.data.totalTransactions)
                        const rows = employees.map(
                            (emp, index) => {
                                emp[TransactionDef.SerialNo] = ((pageNo-1)*rowPerPage)+(index + 1);
                                emp[partyDef.PartyName] = <div className=' whitespace-nowrap'>{emp.party?.[partyDef.PartyName]}</div>
                                emp['commaAmount'] = formatNumberWithCommas(emp[TransactionDef.ConvertedAmount])
                                emp['actualDescription'] = emp[TransactionDef.Description] === 'Opening balance &^&%^%$%$#%%^%^%^%^^%' ? 'Opening Balance' : emp[TransactionDef.Description]
                                emp['dateFormatted'] = <div className=' whitespace-nowrap'>{formattedDate(emp[TransactionDef.Date])}</div>
                                emp[TransactionDef.FT02_expected_received_date] = <div className=' whitespace-nowrap'>{formattedDate(emp[TransactionDef.FT02_expected_received_date])}</div>
                                emp['deleteButton'] = (
                                    emp[TransactionDef.Description] !== 'Opening balance &^&%^%$%$#%%^%^%^%^^%'?<TableStanderd.Button onClick={(event)=>{event.stopPropagation();setDeleteId(emp[TransactionDef.TransactionID]);setShowDeleteModal(true)}} ><IconTrash size={15}/></TableStanderd.Button>:null
                                )
                                return ({
                                    id: emp[TransactionDef.TransactionID],
                                    data: emp
                                })
                            }
                        )
                        setRow1(rows)
                    } else {
                        showErrorToast({
                            message: res?.data?.message || 'something went wrong',
                            showAtTimeOne: true,
                            id: 'paymentCRUD',
                            timeOut: 5000
                        })
                    }


                } catch (error) {
                    showErrorToast({
                        message: 'Internal Error !!!',
                        showAtTimeOne: true,
                        id: 'INTERNAL ERROR',
                        showAtTimeOne: true,
                        id: 'paymentCRUD',
                        timeOut: 5000
                    })
                }
                setIsLoading(false)
            }

            initialLoad()
            dispatch(setTopNavText('Payment'))
            return () => {
                dispatch(setTopNavText(''))
            }

        }, [updateUi,pageNo]
    )

    const headers = [


        {
            id: '4',
            name: 'No',
            label: TransactionDef.SerialNo
        },
        {
            id: '1',
            name: 'Date',
            label: 'dateFormatted'
        },
        {
            id: '6',
            name: 'Description',
            label: 'actualDescription'
        },
        {
            id: '2',
            name: 'Party',
            label: partyDef.PartyName
        },
        {
            id: '5',
            name: 'Amount',
            label: 'commaAmount'
        },
        {
            id: '8',
            name: 'Recieve Date',
            label: TransactionDef.FT02_expected_received_date
        },
        {
            id: '7',
            name: '',
            label:'deleteButton'
        },

    ]



    const openDetailsModal = (id, index) => {
        setSelectedEmploye(id)
        setShowDetailsModal(true)
    }

    const theme = useSelector(
        state => state.config.theme
    )

    const elements = [
        { delay: -1600 },
        { delay: -1550 },
        { delay: -1500 },
        { delay: -1450 },
        { delay: -1400 },
        { delay: -1350 },
        { delay: -1300 },
        { delay: -1250 },
        { delay: -1200 },
        { delay: -1150 },
        { delay: -1100 },
        { delay: -1050 },
        { delay: -1000 },
    ];
    async function deleteTransaction() {
        setIsLoading(true)
        try {
            const res = await api.Delete({
                url: `${process.env.REACT_APP_BACKEND_URL}/api/transactions/${deleteId}`
            });
            if (res?.data?.success === true) {
                showToast({
                    message: res.data?.message || 'Transaction Deleted'
                })
                // closeTheModal();
                rebuildUi();
            } else {
                showErrorToast({
                    message: res?.data?.message || 'Error Deleting Transaction'
                })
            }

        } catch (error) {
            console.log(error)
            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR'
            })
        }
        setShowDeleteModal(false)
        setIsLoading(false)
    }


    return (
        <div className=' grow flex flex-col overflow-hidden'>
            <div className='flex justify-end items-center gap-2 '>
            <QuestionModal show={showDeleteModal} Question="Do you want to delete transaction?" onNegative={() => setShowDeleteModal(false)} onPositive={deleteTransaction} />

                <MenuSimple
                    closeInsideTouch={false}
                    show={showFilterModal}
                    closeMenu={() => setShowFilterModal(false)}
                    top={32}
                    right={0}
                    label={<SquareIconButton onClick={() => setShowFilterModal(true)} varient={squareButtonVarient.outlined} icon={<IconFilter />} text={'Filter'} />}
                >
                    <div className=' max-w-60 w-60 flex flex-col py-3'>
                        <div className=' text-sm font-bold flex pb-1 justify-start w-full'>By Date</div>
                        <div className='flex flex-col ps-1'>
                            <InputStd2 title='From' type='date' onChange={onChangeSearchParams} value={searchParams[searchParamDef.fromDate]} name={searchParamDef.fromDate} />
                            <InputStd2 title='To' onChange={onChangeSearchParams} value={searchParams[searchParamDef.toDate]} name={searchParamDef.toDate} type='date' />
                        </div>
                        <div className=' text-sm font-bold flex pb-1 justify-start w-full pt-2'>By Party</div>
                        <div className='ps-1'>
                            <SelectBoxInputBox option={[{
                                name: 'ALL',
                                value: null,
                            }, ...parties]} onChange={onChangeSearchParams} value={searchParams[searchParamDef.party]} name={searchParamDef.party} className={' grow  basis-full sm:basis-auto'} placeholder={'Party'} title={'Party'} />
                        </div>
                        <div className=' w-full flex justify-end pt-2'>
                            <SquareIconButton text={'Search'} onClick={() => { rebuildUi(); setShowFilterModal(false); setpageNo(1);}} />
                        </div>
                    </div>
                </MenuSimple>
                <SquareIconButton icon={<IconPlus />} text={'Add'} onClick={() => setShowAddModal(true)} />
                <ModalAddBranch show={showAddModal} parties={parties} closeModal={() => setShowAddModal(false)} updateUi={rebuildUi} pushData={(data) => { rows1.push({ id: rows1.length + 1, data }); setRow1([...rows1]) }} />
                <ModalBranchDetails fiscalFull={rows1} id={selectedEmploye} show={showDetailsModal} closeModal={() => setShowDetailsModal(false)} updateUi={rebuildUi} />

            </div>
            <div className='pt-1 pb-5 grow  overflow-hidden flex flex-col gap-2' >
                {isLoading === false ?
                    rows1.length > 0
                        ?
                        <div className=" flex overflow-hidden relative shadow-md sm:rounded-lg">
                            <TableStanderd>
                                <TableStanderd.Head>
                                    {
                                        headers.map(
                                            header => (<th key={header.id} scope="col" className="py-3 px-6">{header.name}</th>)
                                        )
                                    }
                                </TableStanderd.Head>
                                <TableStanderd.Body>
                                    {
                                        rows1.map(
                                            (row, index) => {
                                                if (index === rows1.length - 1) {
                                                    return (
                                                        <TableStanderd.TbRowEnd onClick={() => openDetailsModal(row.id, index)} key={row.id} >
                                                            {
                                                                headers.map(
                                                                    (header, index) => (
                                                                        <TableStanderd.Td key={index} >{row.data[header.label]}</TableStanderd.Td >
                                                                    )
                                                                )
                                                            }

                                                        </TableStanderd.TbRowEnd>
                                                    )
                                                }

                                                return (
                                                    <TableStanderd.TbRow onClick={() => openDetailsModal(row.id, index)} key={row.id} className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 hover:dark:bg-gray-600`}>
                                                        {
                                                            headers.map(
                                                                (header, index) => (
                                                                    <TableStanderd.Td key={index} >{row.data[header.label]}</TableStanderd.Td>
                                                                )
                                                            )
                                                        }

                                                    </TableStanderd.TbRow>
                                                )
                                            }
                                        )
                                    }
                                </TableStanderd.Body>
                            </TableStanderd>
                        </div>
                        :
                        <div className=' flex grow dark:text-gray-300 text-3xl font-bold  items-center justify-center theme-smooth'>No Records Found</div>
                    :
                    <div className=' grow flex flex-col gap-2'>


                        {
                            elements.map(
                                (element, index) => (
                                    <div
                                        key={index}
                                        className={`w-full ${index === 0 ? 'h-16' : 'h-11'} bg-gray-600 rounded-md animate-bubblee`}
                                        style={{ animationDelay: `${element.delay}ms` }}
                                    ></div>
                                )
                            )
                        }



                    </div>
                }
                <TablePagination selectedPage={pageNo} totalRows={totalRow} rowsPerPage={rowPerPage} onClickIndex={(index) => setpageNo(index)} />

            </div>
        </div>
    )
}
