import { Transition } from "@headlessui/react"
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from "react-redux"

export default function MenuSimple({
    show = false,
    label,
    children,
    className,
    closeMenu,
    top = null,
    bottom = null,
    right = null,
    left = null,
    closeInsideTouch = true,
    contentClassName,
    beforeEnter=()=>{}

}) {

    const theme = useSelector(
        state => state.config.theme
    )
    const ref = useRef();
    const dispatch = useDispatch()
    const elementRef = useRef(null);
    const [topO, setTop] = useState(0)
    const [leftO, setLeft] = useState(0)
    const [rightO, setRight] = useState(0)
    const [bottomO, setBottom] = useState(0)
    const position = {}

    const getElementPosition = () => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
            const absoluteTop = rect.top ;
            const absoluteLeft = rect.left ;
            const absoluteRight = window.innerWidth - rect.right + scrollLeft; // Corrected calculation
            const absoluteBottom = window.innerHeight - rect.bottom + scrollTop; // Corrected calculation

            setLeft(absoluteLeft)
            setTop(absoluteTop)
            setBottom(absoluteBottom)
            setRight(absoluteRight)


        }
    };

    //position value
    function performPositon() {
        if (top !== null) {
            position.top = topO + top
        }
        if (right !== null) {
            position.right = rightO + right
        }
        if (bottom !== null) {
            position.bottom = bottomO + bottom
        }
        if (left !== null) {
            position.left = leftO + left
        }
    }

    performPositon()


    useEffect(
        () => {

            if (show) {
                getElementPosition()
            }

        }, [show]
    )
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            closeMenu()
        }
    };

    /*************
        click outside close modal
    **************/
    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);

    //     // Cleanup function to remove event listener on unmount
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [ref.current]);

    return (
        <div ref={elementRef} className={`relative ${className}`}>
            {label}
            {
                ReactDOM.createPortal(
                    <div onClick={closeInsideTouch === true ? closeMenu : null} >
                        
                        <Transition
                            className={` fixed z-50   h-screen w-screen top-0 left-0`}
                            show={show}
                            beforeEnter={beforeEnter}
                        >
                            <Transition.Child
                                style={{
                                    position: 'absolute',
                                    ...position

                                }}
                                
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-50"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-100"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-50"
                            >
                                <div onClick={closeMenu} className="fixed inset-0 z-[-5]"></div>
                                <div
                                    ref={ref}
                                    className={`   theme-smooth   rounded-md shadow-2xl shadow-gray-800 bg-white dark:bg-gray-950  border-2 border-gray-400 dark:border dark:border-gray-600 ring-1 ring-black ring-opacity-5 z-10 `}>
                                    <div className={`py-2 p-2 ${contentClassName}`} role="menu" aria-orientation="vertical" aria-labelledby="dropdown-button">
                                        {children}
                                    </div>
                                </div>
                            </Transition.Child>

                        </Transition>
                    </div>,
                    document.body
                )
            }

        </div>

    )
}
