import { IconList, IconWindowMaximize, IconWindowMinimize } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { sideNavStatesDef, windowStateDef } from "../definitions/definintion";
import { notPendingSideNav, openSideNav, pendingSideNav, toggleFullScreen } from "../redux/config/configSlice";
import { DarkModeButton, IconButton } from "./button";
import { BodyWrapperSubNoPadding } from "./layouts";
import './topNavs.css';
import { useEffect } from "react";
export function MainTopNav() {

    const topNavText = useSelector(
        state => state.config.globalElements.topNavText
    )

    const fullScreenState = useSelector(
        state => state.config.window.state
    )

    const dispatch = useDispatch();

    const onClickListButton = () => {
        dispatch(openSideNav())
        dispatch(pendingSideNav())
        const timout = setTimeout(
            () => {
                dispatch(notPendingSideNav())
                clearTimeout(timout)
            },
            sideNavStatesDef.pendingTime
        )
    }
    return (
        <BodyWrapperSubNoPadding className={'sticky top-0 z-30'}>
            <div className="w-full     z-20 min-h-[50px]  ">
                <div className=" w-full relative   flex items-center py-1 px-2  overflow-hidden ">
                    {/* <div className=" dark:opacity-0 theme-smooth absolute bg-gradient-to-b  from-gray-50 via-gray-50/60 to-gray-50/0 inset-0"></div> */}
                    <div className=" mainTopNavBlur"></div>
                    {/* <div className=" opacity-0 dark:opacity-100 theme-smooth absolute dark:bg-gradient-to-b dark:from-primaryy-dark-1000 dark:via-primaryy-dark-1000/50 dark:to-primaryy-dark-1000/0 inset-0"></div> */}
                    <div className="   z-30 theme-smooth  dark:text-gray-300 flex  leading-none gap-3">
                        <IconButton className={'block md:hidden'} icon={<IconList />} onClick={onClickListButton} />
                    </div>
                    <div className="z-30 grow truncate theme-smooth text-2xl font-montserrat font-bold text-gray-700  dark:text-gray-400 text-nowrap whitespace-nowrap ps-1 md:ps-5">
                        {topNavText}
                    </div>

                    <div className="z-30 flex " >
                        <IconButton icon={fullScreenState === windowStateDef.notFullScreen ? <IconWindowMaximize size={18} /> : <IconWindowMinimize size={18} />} onClick={() => dispatch(toggleFullScreen())} />
                        <DarkModeButton />
                    </div>
                </div>

            </div>
        </BodyWrapperSubNoPadding>
    )
}
export function MainTopNav2() {

    const topNavText = useSelector(
        state => state.config.globalElements.topNavText
    )

    const dispatch = useDispatch();

    const onClickListButton = () => {
        dispatch(openSideNav())
        dispatch(pendingSideNav())
        const timout = setTimeout(
            () => {
                dispatch(notPendingSideNav())
                clearTimeout(timout)
            },
            sideNavStatesDef.pendingTime
        )
    }
    return (
        <div className="w-full sticky top-0  md:hidden z-20 min-h-[50px]  ">
            <div className="relative   flex items-center justify-between   py-1 px-2">
                <div className=" dark:opacity-0 theme-smooth absolute bg-gradient-to-b  from-gray-50 via-gray-50/60 to-gray-50/0 inset-0"></div>
                <div className=" opacity-0 dark:opacity-100 theme-smooth absolute dark:bg-gradient-to-b dark:from-primaryy-dark-1000 dark:via-primaryy-dark-1000/50 dark:to-primaryy-dark-1000/0 inset-0"></div>
                <div className="   z-30 theme-smooth  dark:text-gray-300 flex items-center leading-none gap-3">
                    <IconButton icon={<IconList />} onClick={onClickListButton} />
                    <div className=" theme-smooth text-3xl font-montserrat font-bold text-gray-800 dark:text-gray-300">
                        {topNavText}
                    </div>
                </div>

                <div >
                    <DarkModeButton />
                </div>
            </div>

        </div>
    )
}