import { Menu, Transition } from "@headlessui/react"
import { IconDeviceDesktop, IconMoon, IconMoonFilled, IconSun, IconSunFilled } from "@tabler/icons-react"
import { Fragment, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { squareButtonVarient, themeDef, themeStateDef } from "../definitions/definintion"
import { EndThemeChange, PendingThemeChange, setDarkMode, setLightMode, setSystemTheme, startThemeChange } from "../redux/config/configSlice"
import MenuSimple from "./menu"

export function SquareButton(
    {
        text,
        className,
        onClick,
        varient = squareButtonVarient.primary
    }
) {

    switch (varient) {
        case squareButtonVarient.primary:
            return (
                <button
                    onClick={onClick}
                    className={`middle none center  rounded-md bg-primaryy-500 max-h-[30px] min-h-[30px] px-2 font-montserrat text-sm font-semibold  text-white shadow-md shadow-primaryy-500/20 transition-all hover:shadow-lg hover:shadow-primaryy-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none ${className}`}
                    data-ripple-light="true">
                    {text}
                </button>
            )

        case squareButtonVarient.outlined:
            return (
                <button
                    onClick={onClick}
                    className={`middle none center  rounded-md bg-gray-50 max-h-[30px] min-h-[30px] px-2 font-montserrat text-sm font-semibold  text-gray-900 border border-gray-300 shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:shadow-gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none flex items-center gap-1 ${className}`}
                    data-ripple-light="true">
                    {text}
                </button>
            )

        case squareButtonVarient.danger:
            return (
                <button
                    onClick={onClick}
                    className={`middle none center  rounded-md bg-red-500 max-h-[30px] min-h-[30px] px-2 font-montserrat text-sm font-semibold  text-gray-50 border border-red-300 shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:shadow-gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none flex items-center gap-1 ${className}`}
                    data-ripple-light="true">
                    {text}
                </button>
            )

        default:
            return (
                <button
                    onClick={onClick}
                    className={`middle none center  rounded-md bg-primaryy-500 max-h-[30px] min-h-[30px] px-2 font-montserrat text-sm font-semibold  text-white shadow-md shadow-primaryy-500/20 transition-all hover:shadow-lg hover:shadow-primaryy-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none ${className}`}
                    data-ripple-light="true">
                    {text}
                </button>
            )
    }

}

export function SquareIconButton(
    {
        text,
        className,
        onClick,
        varient = squareButtonVarient.primary,
        icon
    }
) {

    switch (varient) {

        case squareButtonVarient.primary:
            return (
                <button
                    onClick={onClick}
                    className={`middle none center w-min rounded-md text-nowrap whitespace-nowrap bg-primaryy-500 max-h-[30px] min-h-[30px] px-2 pe-3 font-montserrat text-sm font-semibold  text-white shadow-md shadow-primaryy-500/20 transition-all hover:shadow-lg hover:shadow-primaryy-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none flex items-center gap-1 ${className}`}
                    data-ripple-light="true">
                    <div className=" text-sm">
                        {icon}
                    </div>
                    <div>
                        {text}
                    </div>
                </button>
            )

        case squareButtonVarient.outlined:
            return (
                <button
                    onClick={onClick}
                    className={`middle none center w-min rounded-md text-nowrap whitespace-nowrap bg-gray-50 max-h-[30px] min-h-[30px] px-2 pe-3 font-montserrat text-sm font-semibold  text-gray-900 border border-gray-300 shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:shadow-gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none flex items-center gap-1 ${className}`}
                    data-ripple-light="true">
                    <div className=" text-sm">
                        {icon}
                    </div>
                    <div>
                        {text}
                    </div>
                </button>
            )

        default:
            return (
                <button
                    onClick={onClick}
                    className={`middle none center w-min rounded-md text-nowrap whitespace-nowrap bg-primaryy-500 max-h-[30px] min-h-[30px] px-2 pe-3 font-montserrat text-sm font-semibold  text-white shadow-md shadow-primaryy-500/20 transition-all hover:shadow-lg hover:shadow-primaryy-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none flex items-center gap-1 ${className}`}
                    data-ripple-light="true">
                    <div className=" text-sm">
                        {icon}
                    </div>
                    <div>
                        {text}
                    </div>
                </button>
            )
    }


}

//button to change theme
export function DarkModeButton() {

    const theme = useSelector(
        state => state.config.theme
    )
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)



    const options = [
        {
            name: 'Light',
            icon: <IconSun size={18} />,
            iconFilled: <IconSunFilled size={18} />,
            state: themeDef.LIGHT,

        },
        {
            name: 'Dark',
            icon: <IconMoon size={18} />,
            iconFilled: <IconMoonFilled size={18} />,
            state: themeDef.DARK,

        },
        {
            name: 'System',
            icon: <IconDeviceDesktop size={18} />,
            iconFilled: <IconDeviceDesktop size={18} />,
            state: themeDef.SYSTEM,

        },
    ]

    const icon = options.filter(
        item => item.state === theme.theme
    )

    const onClickOptions = (state) => {

        if (state === theme.theme) {
            return;
        }
        dispatch(startThemeChange())
        dispatch(PendingThemeChange())
        switch (state) {

            case themeDef.LIGHT:
                dispatch(setLightMode());
                break;
            case themeDef.DARK:
                dispatch(setDarkMode())
                break;
            case themeDef.SYSTEM:
                dispatch(setSystemTheme())
                break;
            default:
                break;

        }

        setTimeout(
            () => {
                dispatch(EndThemeChange())
            },
            2000
        )

    }


    return (

        <MenuSimple
            show={open}
            closeMenu={() => { setOpen(false) }}
            contentClassName={'right-0 top-1'}
            label={
                <IconButton
                    hovered={open}
                    icon={icon[0]?.iconFilled}
                    onClick={() => setOpen(true)}
                />
            }
            top={45}
            right={0}
        >
            <div className="px-1 py-1 flex flex-col gap-1">
                {
                    options.map(
                        (item, index) => (
                            <button
                                key={index}
                                onClick={() => { onClickOptions(item.state); setOpen(false) }}
                                className={`${item.state === theme.theme ? 'text-gray-900 dark:text-gray-300 bg-gray-100 dark:bg-gray-900' : 'text-gray-700 dark:text-gray-400'
                                    } ${theme.state === themeStateDef.PENDING ? ' theme-smooth' : 'transition-colors duration-100'} w-full rounded-md pe-6 py-1 text-sm cursor-pointer flex items-end
                                    hover:text-gray-900 hover:dark:text-gray-300 hover:bg-gray-100 hover:dark:bg-gray-900`}

                            >

                                <div className="leading-none px-1 text-lg">{item.iconFilled}</div> {item.name}
                            </button>
                        )
                    )
                }
            </div>
        </MenuSimple>
    )
}
export function DarkModeButton2() {

    const theme = useSelector(
        state => state.config.theme
    )
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)



    const options = [
        {
            name: 'Light',
            icon: 'bx-sun',
            iconFilled: 'bxs-sun',
            state: themeDef.LIGHT,

        },
        {
            name: 'Dark',
            icon: 'bx-moon',
            iconFilled: 'bxs-moon',
            state: themeDef.DARK,

        },
        {
            name: 'System',
            icon: 'bx-desktop',
            iconFilled: 'bx-desktop',
            state: themeDef.SYSTEM,

        },
    ]

    const icon = options.filter(
        item => item.state === theme.theme
    )

    const onClickOptions = (state) => {

        if (state === theme.theme) {
            return;
        }
        dispatch(startThemeChange())
        dispatch(PendingThemeChange())
        switch (state) {

            case themeDef.LIGHT:
                dispatch(setLightMode());
                break;
            case themeDef.DARK:
                dispatch(setDarkMode())
                break;
            case themeDef.SYSTEM:
                dispatch(setSystemTheme())
                break;
            default:
                break;

        }

        setTimeout(
            () => {
                dispatch(EndThemeChange())
            },
            2000
        )

    }


    return (

        <Menu as="div" className="relative inline-block text-left">
            {
                ({ open }) => (
                    <>
                        <div>
                            <Menu.Button as="div" >
                                <IconButton
                                    hovered={open}
                                    icon={<i className={`bx ${icon[0]?.iconFilled} `}></i>}


                                />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-50"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-100"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-50"
                        >
                            <Menu.Items >
                                <div className="absolute theme-smooth right-1 mt-1 w-28 origin-top-right rounded-md shadow-lg bg-white dark:bg-gray-950 dark:border dark:border-gray-600 ring-1 ring-black ring-opacity-5 z-10">
                                    <div className="px-1 py-1 flex flex-col gap-1">
                                        {
                                            options.map(
                                                (item, index) => (
                                                    <Menu.Item key={index} >
                                                        {({ active }) => (
                                                            <button
                                                                onClick={() => onClickOptions(item.state)}
                                                                className={`${active || item.state === theme.theme ? 'text-gray-900 dark:text-gray-300 bg-gray-100 dark:bg-gray-900' : 'text-gray-700 dark:text-gray-400'
                                                                    } ${theme.state === themeStateDef.PENDING ? ' theme-smooth' : 'transition-colors duration-100'} w-full rounded-md pe-6 py-1 text-sm cursor-pointer flex items-end`}

                                                            >

                                                                <div className="leading-none px-1 text-lg"><i className={`bx ${item.iconFilled} `}></i></div> {item.name}
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                )
                                            )
                                        }
                                    </div>

                                </div>
                            </Menu.Items>
                        </Transition>
                    </>
                )
            }
        </Menu >
    )
}



export function IconButton({ icon,
    hoverText = '',
    onClick,
    className,
    hovered = false,
    top = 50,
    bottom = null,
    right = null,
    left = 0
}) {

    const theme = useSelector(
        state => state.config.theme
    )
    const elementRef = useRef(null);
    const [topO, setTop] = useState(0)
    const [leftO, setLeft] = useState(0)
    const [rightO, setRight] = useState(0)
    const [bottomO, setBottom] = useState(0)
    const [show, setShow] = useState(false)
    const position = {}

    const getElementPosition = () => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
            const absoluteTop = rect.top + scrollTop;
            const absoluteLeft = rect.left + scrollLeft;
            const absoluteRight = window.innerWidth - rect.right + scrollLeft; // Corrected calculation
            const absoluteBottom = window.innerHeight - rect.bottom + scrollTop; // Corrected calculation

            setLeft(absoluteLeft)
            setTop(absoluteTop)
            setBottom(absoluteBottom)
            setRight(absoluteRight)
            

        }
    };

    function performPositon() {
        if (top !== null) {
            position.top = topO + top
        }
        if (right !== null) {
            position.right = rightO + right
        }
        if (bottom !== null) {
            position.bottom = bottomO + bottom
        }
        if (left !== null) {
            position.left = leftO + left
        }
    }

    performPositon()
    console.log(position)


    const onHover = () => {
        setShow(true)
    }

    const onHoverExit = () => {
        setShow(false)
    }

    useEffect(
        () => {

            if (show) {
            }
            getElementPosition()

        }, [show]
    )

    return (
        <div ref={elementRef}>
            <button
                className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ' transition-colors duration-100'} group  text-gray-500 ${hovered === true ? 'dark:bg-gray-950 bg-gray-transparent-700' : ' dark:hover:bg-gray-950 hover:bg-gray-transparent-700'}   focus:outline-none  focus:ring-gray-200 rounded-lg text-sm p-2.5 ${className}`}
                onClick={onClick}
                onMouseEnter={onHover}
                
                // onMouseLeave={onHoverExit}
            >
                <div className={`leading-none text-lg theme-smooth dark:text-gray-400 text-gray-600 ${hovered === true ? 'text-gray-800 dark:text-gray-200' : 'group-hover:text-gray-800 group-hover:dark:text-gray-200'}  `}>
                    {icon}
                </div>
            </button>
            {/* {
                ReactDOM.createPortal(
                    <div style={{
                        position: 'fixed',
                        ...position,
                        zIndex:100

                    }} className={` h-10 w-10  theme-smooth   rounded-md shadow-lg bg-white dark:bg-gray-950 dark:border dark:border-gray-600 ring-1 ring-black ring-opacity-5 z-10 `}>
                        .
                    </div>,
                    document.body
                )
            } */}
        </div>
    )
}

export function IconOnlyButton({ icon, hoverText = '', onClick, className }) {

    const theme = useSelector(
        state => state.config.theme
    )

    return (
        <button
            className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : 'transition-all duration-200'} group  text-gray-700 dark:hover:bg-gray-950 hover:bg-gray-600/10 focus:outline-none  focus:ring-gray-200 rounded-lg text-sm p-1 ${className}`}
            onClick={onClick}
        >
            <div className={`leading-none text-lg ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : 'transition-all duration-200'} dark:text-gray-400 text-gray-500 group-hover:text-gray-700 group-hover:dark:text-gray-200`}>
                {icon}
            </div>
        </button>
    )
}

export function RoundedIconOnlyButton({ icon, onClick = () => { }, className }) {

    return (
        <div onClick={onClick} className={` transition-all duration-200 bg-gray-200 dark:bg-primaryy-800 rounded-full h-8 w-8 border-2 border-gray-50 flex justify-center items-center leading-none cursor-pointer hover:shadow-md text-gray-400 dark:text-gray-300 text-lg ${className}`}>
            {icon}
        </div>
    )

}