import { createSlice } from "@reduxjs/toolkit";
import { sideNavSizesDef, sideNavStatesDef, themeDef, themeStateDef, windowStateDef } from "../../definitions/definintion";


const initialState = {
    theme: {
        theme: '',
        state: themeStateDef.END
    },
    globalElements: {
        topNavText: '',
        checkingAuth:false          //is the app checking the authentication from backend
    },
    sideNav: {
        state: sideNavStatesDef.close,
        isPending:sideNavStatesDef.notPening,
        size:sideNavSizesDef.expanded
    },
    window:{
        state:windowStateDef.notFullScreen      //screen is full screen or not
    },
    navigation:{
        tryAgainHref:'/'
    }
}

function darkthemeListener(e) {

    document.documentElement.className = ''
    if (!("theme" in localStorage)) {
        if (e.matches) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.add('light')
        }
    }
}

const darkQuery = window.matchMedia("(prefers-color-scheme: dark");



const configSlice = createSlice(
    {
        name: 'config',
        initialState: initialState,
        reducers: {
            initThemeMode: (state, action) => {
                // state.value = action.payload.color;
                darkQuery.removeEventListener('change', darkthemeListener)
                darkQuery.addEventListener('change', darkthemeListener)
                document.documentElement.className = ''
                if (!("theme" in localStorage)) {
                    state.theme.theme = themeDef.SYSTEM
                    if (darkQuery.matches === true) {
                        document.documentElement.classList.add('dark')
                    } else {
                        document.documentElement.classList.add('light')
                    }
                } else {
                    const theme = localStorage.getItem('theme')
                    document.documentElement.classList.add(theme)
                    state.theme.theme = theme

                }
            },
            setDarkMode: (state, action) => {
                document.documentElement.className = ''
                darkQuery.removeEventListener('change', darkthemeListener)
                document.documentElement.classList.add('dark')
                localStorage.setItem('theme', themeDef.DARK)
                state.theme.theme = themeDef.DARK
            },
            setLightMode: (state, action) => {
                document.documentElement.className = ''
                darkQuery.removeEventListener('change', darkthemeListener)
                document.documentElement.classList.add('light')
                localStorage.setItem('theme', themeDef.LIGHT)

                state.theme.theme = themeDef.LIGHT
            },
            setSystemTheme: (state, action) => {
                document.documentElement.className = ''
                darkQuery.removeEventListener('change', darkthemeListener)
                darkQuery.addEventListener('change', darkthemeListener)
                localStorage.removeItem('theme')
                if (!("theme" in localStorage)) {
                    state.theme.theme = themeDef.SYSTEM
                    if (darkQuery.matches === true) {
                        document.documentElement.classList.add('dark')
                    } else {
                        document.documentElement.classList.add('light')
                    }
                }
            },
            startThemeChange: (state, action) => {
                state.theme.state = themeStateDef.START
            },
            PendingThemeChange: (state, action) => {
                state.theme.state = themeStateDef.PENDING
            },
            EndThemeChange: (state, action) => {
                state.theme.state = themeStateDef.END
            },

            //
            setTopNavText: (state, action) => {
                state.globalElements.topNavText = action.payload
            },

            //
            closeSideNav: (state, action) => {
                state.sideNav.state = sideNavStatesDef.close
            },
            openSideNav: (state, action) => {
                state.sideNav.state = sideNavStatesDef.open
            },
            pendingSideNav:(state,action)=>{
                state.sideNav.isPending = sideNavStatesDef.pending
            },
            
            notPendingSideNav:(state,action)=>{
                state.sideNav.isPending = sideNavStatesDef.notPening
            }
            ,
            //close and open
            toggleSideNav: (state, action) => {
                state.sideNav.state = state.sideNav.state === sideNavStatesDef.open ?
                    sideNavStatesDef.close :
                    sideNavStatesDef.open
            },
            shrinkSideNav:(state,action)=>{
                state.sideNav.size = sideNavSizesDef.shrink
            },
            expandeSideNav:(state,action)=>{
                state.sideNav.size = sideNavSizesDef.expanded
            },
            toggleFullScreen:(state,action)=>{
                if(!document.fullscreenElement){
                    document.documentElement.requestFullscreen();
                    state.window.state = windowStateDef.fullScreen
                }else{
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                        state.window.state = windowStateDef.notFullScreen;
                    }
                }
            },
            setisCheckingLogin:(state,action)=>{
                state.globalElements.checkingAuth = action.payload
            },
            setTryAgainHref:(state,action)=>{
                state.navigation.tryAgainHref = action.payload
            }

        }
    }
)


export const configReducer = configSlice.reducer;
export const { initThemeMode, setDarkMode,
    setLightMode, setSystemTheme,
    EndThemeChange, PendingThemeChange,
    startThemeChange, setTopNavText ,
    closeSideNav , openSideNav , toggleSideNav,
    pendingSideNav,notPendingSideNav,
    shrinkSideNav,expandeSideNav,
    toggleFullScreen,setisCheckingLogin,setTryAgainHref
} = configSlice.actions;