import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { IconOnlyButton, SquareButton, SquareIconButton } from "../../../../../../../components/button";
import { InputStd2, InputTextAreaStd2, SelectBoxInputBox, SelectInputStd2, SwitchInputStd2 } from "../../../../../../../components/inputs";
import { ModalLoading, ModalStanderd, QuestionModal } from "../../../../../../../components/modal";
import { showErrorToast, showToast } from "../../../../../../../components/toast";
import { useRESTapi } from '../../../../../../../controller/restAPI';
import { accountGroupDef, accountLedgerDef, employeeDef, squareButtonVarient } from "../../../../../../../definitions/definintion";



export function ModalAddBranch({ show, closeModal, updateUi, pushData }) {


    const [errdatas, setErrDatas] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const user = useSelector(state => state.user.user)
    const [acGroups, setAcGroups] = useState([])
    const [data, setData] = useState({
        [accountLedgerDef.AccountGroupId]: '',
        [accountLedgerDef.LedgerName]: '',
        [accountLedgerDef.OpeningBalance]: '0',
        [accountLedgerDef.IsDefault]: 0,
        [accountLedgerDef.CrOrDr]: '',
        [accountLedgerDef.Narration]: '',
        // [accountLedgerDef.MailingName]: '',
        [accountLedgerDef.Address]: '',
        [accountLedgerDef.Phone]: '',
        [accountLedgerDef.Mobile]: '',
        [accountLedgerDef.Email]: '',
        [accountLedgerDef.CreditPeriod]: '',
        [accountLedgerDef.CreditLimit]: '',
        [accountLedgerDef.IsActive]: 1,
        [accountLedgerDef.CreatedBy]: user[employeeDef.EmployeeId],
    })
    const api = useRESTapi();

    const validationObj = {
        [accountLedgerDef.AccountGroupId]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [accountLedgerDef.LedgerName]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },

        [accountLedgerDef.OpeningBalance]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
                (value) => {
                    const number = Number(value);
                    if (Number.isFinite(number)) {
                        return true
                    } else {
                        return 'Not valid'
                    }
                }
            ]
        },
        [accountLedgerDef.IsDefault]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        // [accountLedgerDef.CrOrDr]: {
        //     message: 'required',
        //     validaton: [
        //         (value) => {
        //             if (value !== null && value !== undefined && value !== '') {
        //                 return true
        //             }
        //             return 'required'
        //         },
        //     ]
        // },
        // [accountLedgerDef.Narration]: {
        //     message: 'required',
        //     validaton: [
        //         (value) => {
        //             if (value !== null && value !== undefined && value !== '') {
        //                 return true
        //             }
        //             return 'required'
        //         },
        //     ]
        // },
        // [accountLedgerDef.MailingName]: {
        //     message: 'required',
        //     validaton: [
        //         (value) => {
        //             if (value !== null && value !== undefined && value !== '') {
        //                 return true
        //             }
        //             return 'required'
        //         },
        //     ]
        // },
        [accountLedgerDef.Address]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        // [accountLedgerDef.Phone]: {
        //     message: 'required',
        //     validaton: [
        //         (value) => {
        //             if (value !== null && value !== undefined && value !== '') {
        //                 return true
        //             }
        //             return 'required'
        //         },
        //         (value) => {
        //             if (/^\d{10}$/.test(value)) {
        //                 return true
        //             }
        //             return 'not valid'
        //         },
        //     ]
        // },
        [accountLedgerDef.Mobile]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
                (value) => {
                    if (/^\d{10}$/.test(value)) {
                        return true
                    }
                    return 'not valid'
                },
            ]
        },
        [accountLedgerDef.Email]: {
            message: 'required',
            validaton: [
                
                (value) => {

                    if (value === null || value === undefined || value === '') {
                        return true
                    }

                    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                        return true
                    }
                    return 'Not valid email'
                }
            ]
        },
        [accountLedgerDef.CreditPeriod]: {
            validaton: [
                // (value) => {
                //     if (value !== null && value !== undefined && value !== '') {
                //         return true
                //     }
                //     return 'required'
                // },
                (value) => {
                    if (value === '') {
                        return true
                    }
                    if (/^\d+$/.test(value)) {
                        return true
                    }
                    return 'Not valid'
                }
            ]
        },
        [accountLedgerDef.CreditLimit]: {
            message: 'required',
            validaton: [

                (value) => {
                    const number = Number(value);
                    if (Number.isFinite(number)) {
                        return true
                    } else {
                        return 'Not valid'
                    }
                }
            ]
        },
        [accountLedgerDef.IsActive]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [accountLedgerDef.CreatedBy]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
    }

    const performValidation = (obj = {}) => {

        let errObj = {}
        let errNotOccured = true;

        for (const key in validationObj) {

            let isPass = true;

            for (let i = 0; i < validationObj[key].validaton.length; i++) {
                isPass = validationObj[key].validaton[i](obj[key])
                if (isPass !== true) {
                    errNotOccured = false
                    errObj[key] = isPass;
                    break;
                }
            }



        }
        // console.log(err)

        setErrDatas(errObj)
        return errNotOccured;

    }

    const onChangeInput = (event) => {

        let valueObj
        valueObj = {
            ...data,
            [event.target.name]: event.target.value
        }

        setData(valueObj)


    }

    const clearData = () => {
        setData({
            [accountLedgerDef.AccountGroupId]: '',
            [accountLedgerDef.LedgerName]: '',
            [accountLedgerDef.OpeningBalance]: '0',
            [accountLedgerDef.IsDefault]: 0,
            [accountLedgerDef.CrOrDr]: '',
            [accountLedgerDef.Narration]: '',
            // [accountLedgerDef.MailingName]: '',
            [accountLedgerDef.Address]: '',
            [accountLedgerDef.Phone]: '',
            [accountLedgerDef.Mobile]: '',
            [accountLedgerDef.Email]: '',
            [accountLedgerDef.CreditPeriod]: '',
            [accountLedgerDef.CreditLimit]: '',
            [accountLedgerDef.IsActive]: 1,
            [accountLedgerDef.CreatedBy]: user[employeeDef.EmployeeId],
        })
        setErrDatas({})
    }



    const addRole = async () => {
        setIsLoading(true)
        data[accountLedgerDef.CreatedBy] = user[employeeDef.EmployeeId]
        console.log(data)
        try {

            if (performValidation(data)) {
                const res = await api.post({
                    url: `${process.env.REACT_APP_BACKEND_URL}/api/account-ledgers`,
                    body: data,
                });

                if (res.data.success === true) {
                    showToast({
                        message: res.data.message || 'Ledger added'
                    })
                    clearData()
                    closeModal();
                    updateUi();
                } else {
                    if (res && res.data) {
                        const res1 = res.data;
                        if (res1.error) {
                            const error = {};
                            for (let key in res1.error) {
                                error[key] = res1.error[key][0]
                            }
                            console.log(error)
                            setErrDatas(error)
                        }
                    }
                    showErrorToast({
                        message: res?.data?.message || 'Error adding ledger',
                        timeOut: 5000
                    })
                }



            } else {
                showErrorToast({
                    message: 'Validation error',
                    timeOut: 2000
                })
            }

        } catch (err) {
            console.log(err)
            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR',
            })
        }
        setIsLoading(false)
    }

    useEffect(
        () => {
            async function initialize() {

                setIsLoading(true)
                try {
                    const res = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/account-group`
                    });
                    if (res.data.success === true) {
                        setAcGroups(res.data.data.map(items => ({
                            name: items[accountGroupDef.AccountGroupName],
                            value: items[accountGroupDef.AccountGroupId],
                        })))
                    } else {
                        showErrorToast({
                            message: res.data?.message || 'something went wrong',
                            showAtTimeOne: true,
                            id: 'accountLedger',
                            timeOut: 5000
                        })
                        closeModal()
                        clearData();
                    }

                } catch (error) {
                    showErrorToast({
                        message: 'Internal Error !!!',
                        showAtTimeOne: true,
                        id: 'INTERNAL ERROR'
                    })
                    closeModal()
                    clearData();
                }
                setIsLoading(false)

            }
            if (show === true) {

                initialize();

            }
        }, [show]
    )

    return (
        <>
            <ModalLoading show={isLoading} zIndex={100} />

            <ModalStanderd
                show={show}
                title="Add Account Ledger"
                closeModal={closeModal}
                closeClickOutside={false}
            >
                <ModalStanderd.Body>
                    <div className="sm:flex sm:items-start w-full">

                        <div className="mt-3 text-center  sm:mt-0 sm:text-left w-full pb-1">

                            <div className=" w-full flex flex-wrap gap-2 ">
                                <InputStd2 className={'grow basis-full sm:basis-auto'} rows={3} placeholder={'ledger'} title={'Ledger name'} errMsg={errdatas[accountLedgerDef.LedgerName]} value={data[accountLedgerDef.LedgerName]} name={accountLedgerDef.LedgerName} onChange={onChangeInput} />
                                <SelectBoxInputBox option={acGroups} errMsg={errdatas[accountLedgerDef.AccountGroupId]} className={' grow  basis-full sm:basis-auto'} placeholder={'account group'} title={'Account Group'} value={data[accountLedgerDef.AccountGroupId]} name={accountLedgerDef.AccountGroupId} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <InputStd2 className={'grow basis-full sm:basis-[49%]'} placeholder={'Opening balance'} title={'Opening balance'} errMsg={errdatas[accountLedgerDef.OpeningBalance]} value={data[accountLedgerDef.OpeningBalance]} name={accountLedgerDef.OpeningBalance} onChange={onChangeInput} />
                                <InputStd2 className={'grow basis-full sm:basis-[49%]'} placeholder={'Narration'} title={'Narration'} errMsg={errdatas[accountLedgerDef.Narration]} value={data[accountLedgerDef.Narration]} name={accountLedgerDef.Narration} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <InputStd2 className={'grow'} placeholder={'Mobile'} title={'Mobile'} errMsg={errdatas[accountLedgerDef.Mobile]} value={data[accountLedgerDef.Mobile]} name={accountLedgerDef.Mobile} onChange={onChangeInput} />
                                <InputStd2 className={'grow'} placeholder={'Email'} title={'Email'} errMsg={errdatas[accountLedgerDef.Email]} value={data[accountLedgerDef.Email]} name={accountLedgerDef.Email} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <InputTextAreaStd2 className={'grow'} placeholder={'Address'} title={'Address'} errMsg={errdatas[accountLedgerDef.Address]} value={data[accountLedgerDef.Address]} name={accountLedgerDef.Address} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <div className='flex justify-end basis-full gap-2'>
                                    <SwitchInputStd2 className={'grow'} placeholder={'Is Default'} title={'is default'} errMsg={errdatas[accountLedgerDef.IsDefault]} value={data[accountLedgerDef.IsDefault]} name={accountLedgerDef.IsDefault} onChange={onChangeInput} />
                                    <SwitchInputStd2 className={'grow'} placeholder={'Is Active'} title={'Is Active'} errMsg={errdatas[accountLedgerDef.IsActive]} value={data[accountLedgerDef.IsActive]} name={accountLedgerDef.IsActive} onChange={onChangeInput} />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalStanderd.Body>
                <ModalStanderd.Bottom>
                    <SquareIconButton icon={<IconPlus />} text={'Add'} onClick={addRole} />
                    <SquareButton text={'Cancel'} varient={squareButtonVarient.outlined} onClick={closeModal} />
                </ModalStanderd.Bottom>
            </ModalStanderd>
        </>
    )
}

export function ModalBranchDetails({ show, closeModal, updateUi, id }) {

    const [errdatas, setErrDatas] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [allowEdit, setallowEdit] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const user = useSelector(state => state.user.user)
    const [acGroups, setAcGroups] = useState([])
    const api = useRESTapi();
    const [editable, setEditable] = useState(false);

    const [data, setData] = useState({
        [accountLedgerDef.AccountGroupId]: '',
        [accountLedgerDef.LedgerName]: '',
        [accountLedgerDef.OpeningBalance]: '0',
        [accountLedgerDef.IsDefault]: 0,
        [accountLedgerDef.CrOrDr]: '',
        [accountLedgerDef.Narration]: '',
        // [accountLedgerDef.MailingName]: '',
        [accountLedgerDef.Address]: '',
        [accountLedgerDef.Phone]: '',
        [accountLedgerDef.Mobile]: '',
        [accountLedgerDef.Email]: '',
        [accountLedgerDef.CreditPeriod]: '',
        [accountLedgerDef.CreditLimit]: '',
        [accountLedgerDef.IsActive]: 1,
        [accountLedgerDef.CreatedBy]: user[employeeDef.EmployeeId],
    })

    const [initialData, setInitialData] = useState({})

    const validationObj = {
        [accountLedgerDef.AccountGroupId]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [accountLedgerDef.LedgerName]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },

        [accountLedgerDef.OpeningBalance]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
                (value) => {
                    const number = Number(value);
                    if (Number.isFinite(number)) {
                        return true
                    } else {
                        return 'Not valid'
                    }
                }
            ]
        },
        // [accountLedgerDef.IsDefault]: {
        //     message: 'required',
        //     validaton: [
        //         (value) => {
        //             if (value !== null && value !== undefined && value !== '') {
        //                 return true
        //             }
        //             return 'required'
        //         },
        //     ]
        // },
        // [accountLedgerDef.CrOrDr]: {
        //     message: 'required',
        //     validaton: [
        //         (value) => {
        //             if (value !== null && value !== undefined && value !== '') {
        //                 return true
        //             }
        //             return 'required'
        //         },
        //     ]
        // },
        // [accountLedgerDef.Narration]: {
        //     message: 'required',
        //     validaton: [
        //         (value) => {
        //             if (value !== null && value !== undefined && value !== '') {
        //                 return true
        //             }
        //             return 'required'
        //         },
        //     ]
        // },
        // [accountLedgerDef.MailingName]: {
        //     message: 'required',
        //     validaton: [
        //         (value) => {
        //             if (value !== null && value !== undefined && value !== '') {
        //                 return true
        //             }
        //             return 'required'
        //         },
        //     ]
        // },
        [accountLedgerDef.Address]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        // [accountLedgerDef.Phone]: {
        //     message: 'required',
        //     validaton: [
        //         (value) => {
        //             if (value !== null && value !== undefined && value !== '') {
        //                 return true
        //             }
        //             return 'required'
        //         },
        //         (value) => {
        //             if (/^\d{10}$/.test(value)) {
        //                 return true
        //             }
        //             return 'not valid'
        //         },
        //     ]
        // },
        [accountLedgerDef.Mobile]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
                (value) => {
                    if (/^\d{10}$/.test(value)) {
                        return true
                    }
                    return 'not valid'
                },
            ]
        },
        [accountLedgerDef.Email]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
                (value) => {
                    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                        return true
                    }
                    return 'Not valid email'
                }
            ]
        },
        [accountLedgerDef.CreditPeriod]: {
            validaton: [
                // (value) => {
                //     if (value !== null && value !== undefined && value !== '') {
                //         return true
                //     }
                //     return 'required'
                // },
                (value) => {
                    if (value === '') {
                        return true
                    }
                    if (/^\d+$/.test(value)) {
                        return true
                    }
                    return 'Not valid'
                }
            ]
        },
        [accountLedgerDef.CreditLimit]: {
            message: 'required',
            validaton: [

                (value) => {
                    const number = Number(value);
                    if (Number.isFinite(number)) {
                        return true
                    } else {
                        return 'Not valid'
                    }
                }
            ]
        },
        [accountLedgerDef.IsActive]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [accountLedgerDef.CreatedBy]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== null && value !== undefined && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
    }


    const performValidation = (obj = {}) => {

        let errObj = {}
        let errNotOccured = true;

        for (const key in validationObj) {

            let isPass = true;

            for (let i = 0; i < validationObj[key].validaton.length; i++) {
                isPass = validationObj[key].validaton[i](obj[key])
                if (isPass !== true) {
                    errNotOccured = false
                    errObj[key] = isPass;
                    break;
                }
            }



        }

        setErrDatas(errObj)
        return errNotOccured;

    }

    const onChangeInput = (event) => {

        let valueObj
        valueObj = {
            ...data,
            [event.target.name]: event.target.value
        }


        setData(valueObj)


    }

    async function deleteParty() {
        setIsLoading(true)
        try {
            const res = await api.Delete({
                url: `${process.env.REACT_APP_BACKEND_URL}/api/account-ledgers/${id}`
            });

            if (res?.data?.success === true) {
                showToast({
                    message: res.data?.message || 'Ledger Deleted'
                })
                closeTheModal();
                updateUi();
            } else {
                showErrorToast({
                    message: res?.data?.message || 'Error Deleting Ledger'
                })
            }

        } catch (error) {
            console.log(error)
            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR'
            })
        }
        setShowDeleteModal(false)
        setIsLoading(false)
    }

    const closeTheModal = () => {
        setShowDeleteModal(false)
        setallowEdit(false)
        clearData()
        closeModal()
    }

    const disAllowEdit = () => {
        setallowEdit(false);
        setErrDatas({})
        setData(initialData)
    }

    const clearData = () => {
        setData({
            [accountLedgerDef.AccountGroupId]: '',
            [accountLedgerDef.LedgerName]: '',
            [accountLedgerDef.OpeningBalance]: '0',
            [accountLedgerDef.IsDefault]: 0,
            [accountLedgerDef.CrOrDr]: '',
            [accountLedgerDef.Narration]: '',
            // [accountLedgerDef.MailingName]: '',
            [accountLedgerDef.Address]: '',
            [accountLedgerDef.Phone]: '',
            [accountLedgerDef.Mobile]: '',
            [accountLedgerDef.Email]: '',
            [accountLedgerDef.CreditPeriod]: '',
            [accountLedgerDef.CreditLimit]: '',
            [accountLedgerDef.IsActive]: 1,
            [accountLedgerDef.CreatedBy]: user[employeeDef.EmployeeId],
        })
        setErrDatas({})
    }

    const addRole = async () => {
        setIsLoading(true)
        data[accountLedgerDef.CreatedBy] = user[employeeDef.EmployeeId]
        // data[accountLedgerDef.IsDefault] = data[accountLedgerDef.IsDefault]===""||data[accountLedgerDef.IsDefault]===null || data[accountLedgerDef.IsDefault]===undefined?0:data[accountLedgerDef.IsDefault]
        console.log(data)
        try {

            if (performValidation(data)) {
                const res = await api.put({
                    url: `${process.env.REACT_APP_BACKEND_URL}/api/account-ledgers/${id}`,
                    body: data,
                });

                if (res?.data?.success === true) {
                    showToast({
                        message: res.data.message || 'Ledger added'
                    })
                    clearData()
                    closeModal();
                    updateUi();
                } else {
                    if (res && res.data) {
                        const res1 = res.data;
                        if (res1.error) {
                            const error = {};
                            for (let key in res1.error) {
                                error[key] = res1.error[key][0]
                            }
                            console.log(error)
                            setErrDatas(error)
                        }
                    }
                    showErrorToast({
                        message: res?.data?.message || 'Error adding ledger',
                        timeOut: 5000
                    })
                }



            } else {
                showErrorToast({
                    message: 'Validation error',
                    timeOut: 2000
                })
            }

        } catch (err) {
            console.log(err)
            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR',
            })
        }
        setIsLoading(false)
    }




    useEffect(
        () => {
            const loadEmployee = async () => {

                setIsLoading(true)
                try {
                    const res1 = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/account-group`
                    });

                    if (res1.data.success === true) {
                        setAcGroups(res1.data.data.map(items => ({
                            name: items[accountGroupDef.AccountGroupName],
                            value: items[accountGroupDef.AccountGroupId],
                        })))
                        const res = await api.get({
                            url: `${process.env.REACT_APP_BACKEND_URL}/api/account-ledgers/${id}`
                        });
                        if (res.data.success === true) {
                            const employee = res.data.data;
                            for (const key in employee) {
                                employee[key] = employee[key] === null || employee[key] === undefined ? '' : employee[key]
                                // employee[key] = undefined
                            }
                            if (employee[accountLedgerDef.IsDefault] == 1) {
                                setEditable(false)
                            } else {
                                setEditable(true)
                            }

                            setData(employee)
                            setInitialData(employee)
                        } else {
                            showErrorToast({
                                message: res.data?.message || 'Error loading ledger'
                            })
                        }
                    } else {
                        showErrorToast({
                            message: res1.data?.message || 'Error loading ledger'
                        })
                        closeModal()
                    }


                } catch (error) {
                    console.log(error)
                    showErrorToast({
                        message: 'Internal Error !!!',
                        showAtTimeOne: true,
                        id: 'INTERNAL ERROR'
                    })
                    closeModal()
                }
                setIsLoading(false)

            }
            if (show) {
                loadEmployee();
            }
        }, [show]
    )



    return (
        <>
            <ModalLoading show={isLoading} zIndex={1000} />
            <QuestionModal show={showDeleteModal} Question="Do you want to delete Ledger?" onNegative={() => setShowDeleteModal(false)} onPositive={deleteParty} />

            <ModalStanderd title="Ledger Details" show={show} closeModal={closeModal} afterLeave={closeTheModal} >
                <ModalStanderd.Body>
                    <div className=" flex justify-end gap-1 px-1 pt-1">
                        {editable && <IconOnlyButton onClick={() => setallowEdit(true)} icon={<IconEdit />} />}
                        {editable && <IconOnlyButton onClick={() => setShowDeleteModal(true)} icon={<IconTrash />} />}
                    </div>
                    <div className="sm:flex sm:items-start w-full">

                        <div className="mt-3 text-center  sm:mt-0 sm:text-left w-full pb-1">

                            <div className=" w-full flex flex-wrap gap-2 ">

                                <InputStd2 disabled={!allowEdit} className={'grow basis-full sm:basis-auto'} rows={3} placeholder={'ledger'} title={'Ledger name'} errMsg={errdatas[accountLedgerDef.LedgerName]} value={data[accountLedgerDef.LedgerName]} name={accountLedgerDef.LedgerName} onChange={onChangeInput} />
                                <SelectBoxInputBox disabled={!allowEdit} option={acGroups} errMsg={errdatas[accountLedgerDef.AccountGroupId]} className={' grow  basis-full sm:basis-auto'} placeholder={'account group'} title={'Account Group'} value={data[accountLedgerDef.AccountGroupId]} name={accountLedgerDef.AccountGroupId} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <InputStd2 disabled={true} className={'grow basis-full sm:basis-[49%]'} placeholder={'Opening balance'} title={'Opening balance'} errMsg={errdatas[accountLedgerDef.OpeningBalance]} value={data[accountLedgerDef.OpeningBalance]} name={accountLedgerDef.OpeningBalance} onChange={onChangeInput} />
                                <InputStd2 disabled={!allowEdit} className={'grow basis-full sm:basis-[49%]'} placeholder={'Narration'} title={'Narration'} errMsg={errdatas[accountLedgerDef.Narration]} value={data[accountLedgerDef.Narration]} name={accountLedgerDef.Narration} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <InputStd2 disabled={!allowEdit} className={'grow'} placeholder={'Mobile'} title={'Mobile'} errMsg={errdatas[accountLedgerDef.Mobile]} value={data[accountLedgerDef.Mobile]} name={accountLedgerDef.Mobile} onChange={onChangeInput} />
                                <InputStd2 disabled={!allowEdit} className={'grow'} placeholder={'Email'} title={'Email'} errMsg={errdatas[accountLedgerDef.Email]} value={data[accountLedgerDef.Email]} name={accountLedgerDef.Email} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <InputTextAreaStd2 disabled={!allowEdit} className={'grow'} placeholder={'Address'} title={'Address'} errMsg={errdatas[accountLedgerDef.Address]} value={data[accountLedgerDef.Address]} name={accountLedgerDef.Address} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <div className='flex justify-end basis-full gap-2'>
                                    <SwitchInputStd2 disabled={!allowEdit} className={'grow'} placeholder={'Is Default'} title={'is default'} errMsg={errdatas[accountLedgerDef.IsDefault]} value={data[accountLedgerDef.IsDefault]} name={accountLedgerDef.IsDefault} onChange={onChangeInput} />
                                    <SwitchInputStd2 disabled={!allowEdit} className={'grow'} placeholder={'Is Active'} title={'Is Active'} errMsg={errdatas[accountLedgerDef.IsActive]} value={data[accountLedgerDef.IsActive]} name={accountLedgerDef.IsActive} onChange={onChangeInput} />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalStanderd.Body>
                <ModalStanderd.Bottom>
                    {
                        allowEdit ?
                            <><SquareButton className={'w-min'} text={'Edit'} onClick={addRole} />
                                <SquareButton text={'Cancel'} varient={squareButtonVarient.outlined} onClick={disAllowEdit} /></>
                            :
                            <SquareButton className={' w-[90px]'} text={'Cancel'} onClick={closeModal} />}
                </ModalStanderd.Bottom>
            </ModalStanderd>
        </>
    )

}

