import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { useEffect, useState } from "react";
import { IconOnlyButton, SquareButton, SquareIconButton } from "../../../../../../../components/button";
import { InputStd2, InputTextAreaStd2 } from "../../../../../../../components/inputs";
import { ModalLoading, ModalStanderd, QuestionModal } from "../../../../../../../components/modal";
import { showErrorToast, showToast } from "../../../../../../../components/toast";
import { useRESTapi } from '../../../../../../../controller/restAPI';
import { TransactionDef, accountLedgerDef, partyDef, squareButtonVarient } from "../../../../../../../definitions/definintion";







export function ModalAddBranch({ show, closeModal, updateUi, pushData }) {


    const [errdatas, setErrDatas] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const api = useRESTapi()

    const [data, setData] = useState({
        [partyDef.PartyName]: '',
        [partyDef.Address]: '',
        [partyDef.MobileNumber]: '',
        [partyDef.Email]: '',
        [accountLedgerDef.OpeningBalance]: 0
    })




    const validationObj = {
        [partyDef.PartyName]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },

        // [partyDef.Address]: {
        //     message: 'required',
        //     validaton: [
        //         (value) => {
        //             if (value && value !== '') {
        //                 return true
        //             }
        //             return 'required'
        //         },
        //     ]
        // },
        [partyDef.MobileNumber]: {
            message: 'required',
            validaton: [
                // (value) => {
                //     if (value && value !== '') {
                //         return true
                //     }
                //     return 'required'
                // },
                (value) => {
                    if (value === "") {
                        return true; // Return empty string for empty input
                    }

                    // Validate for 10-digit phone number format
                    if (/^\d{10}$/.test(value)) {
                        return true; // Return true for valid phone number
                    }
                    return 'not valid'
                },
            ]
        },
        [partyDef.Email]: {
            message: 'required',
            validaton: [
                
                (value) => {
                    if (value === "") {
                        return true; // Return empty string for empty input
                    }
                    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                        return true
                    }
                    return 'Not valid email'
                }
            ]
        },
        [accountLedgerDef.OpeningBalance]: {
            validaton: [
                (value) => {
                    const number = Number(value);
                    if (Number.isFinite(number)) {
                        return true
                    } else {
                        return 'Not valid'
                    }
                },
            ]
        },


    }

    const performValidation = (obj = {}) => {

        let errObj = {}
        let errNotOccured = true;

        for (const key in validationObj) {

            let isPass = true;

            for (let i = 0; i < validationObj[key].validaton.length; i++) {
                isPass = validationObj[key].validaton[i](obj[key])
                if (isPass !== true) {
                    errNotOccured = false
                    errObj[key] = isPass;
                    break;
                }
            }



        }
        // console.log(err)

        setErrDatas(errObj)
        return errNotOccured;

    }

    const onChangeInput = (event) => {

        let valueObj
        valueObj = {
            ...data,
            [event.target.name]: event.target.value
        }

        setData(valueObj)


    }

    const clearData = () => {
        setData({
            [partyDef.PartyName]: '',
            [partyDef.Address]: '',
            [partyDef.MobileNumber]: '',
            [partyDef.Email]: '',
            [accountLedgerDef.OpeningBalance]: 0,
        })
        setErrDatas({})
    }



    const addRole = async () => {
        setIsLoading(true)
        console.log(data)
        try {

            if (performValidation(data)) {
                if (Number(data[accountLedgerDef.OpeningBalance]) !== 0) {
                    data[TransactionDef.Description] = 'Opening balance &^&%^%$%$#%%^%^%^%^^%'
                }
                const res = await api.post({
                    url: `${process.env.REACT_APP_BACKEND_URL}/api/parties`,
                    body: data
                });

                if (res?.data?.success === true) {
                    showToast({
                        message: res.data.message || 'Party added'
                    })
                    clearData()
                    closeModal();
                    updateUi();
                } else {
                    if (res && res.data) {
                        const res1 = res.data;
                        if (res1.error) {
                            const error = {};
                            for (let key in res1.error) {
                                error[key] = res1.error[key][0]
                            }
                            console.log(error)
                            setErrDatas(error)
                        }
                    }
                    showErrorToast({
                        message: res?.data?.message || 'Error adding ledger',
                        timeOut: 5000
                    })
                }



            } else {
                showErrorToast({
                    message: 'Please fill the form',
                    timeOut: 2000
                })
            }

        } catch (err) {

            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR',
                timeOut: 5000
            })

        }
        setIsLoading(false)
    }

    useEffect(
        () => {
            async function initialize() {


            }
            if (show === true) {

                initialize();

            }
        }, [show]
    )


    return (
        <>
            <ModalLoading show={isLoading} zIndex={100} />

            <ModalStanderd
                show={show}
                title="Add Party"
                closeModal={closeModal}
                closeClickOutside={false}
            >
                <ModalStanderd.Body>
                    <div className="sm:flex sm:items-start w-full">

                        <div className="mt-3 text-center  sm:mt-0 sm:text-left w-full pb-1">

                            <div className=" w-full flex flex-wrap gap-2 ">
                                <InputStd2 errMsg={errdatas[partyDef.PartyName]} className={' grow '} placeholder={'name'} title={'Party Name'} value={data[partyDef.PartyName]} name={partyDef.PartyName} onChange={onChangeInput} />
                                <InputStd2 errMsg={errdatas[accountLedgerDef.OpeningBalance]} className={' grow '} placeholder={'opening balance'} title={'Opening balance'} value={data[accountLedgerDef.OpeningBalance]} name={accountLedgerDef.OpeningBalance} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <InputStd2 className={'grow'} placeholder={'Mobile'} title={'Mobile'} errMsg={errdatas[partyDef.MobileNumber]} value={data[partyDef.MobileNumber]} name={partyDef.MobileNumber} onChange={onChangeInput} />
                                <InputStd2 className={'grow'} placeholder={'Email'} title={'Email'} errMsg={errdatas[partyDef.Email]} value={data[partyDef.Email]} name={partyDef.Email} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <InputTextAreaStd2 className={'basis-full'} rows={3} placeholder={'Address'} title={'Address'} errMsg={errdatas[partyDef.Address]} value={data[partyDef.Address]} name={partyDef.Address} onChange={onChangeInput} />

                            </div>
                        </div>
                    </div>
                </ModalStanderd.Body>
                <ModalStanderd.Bottom>
                    <SquareIconButton icon={<IconPlus />} text={'Add'} onClick={addRole} />
                    <SquareButton text={'Cancel'} varient={squareButtonVarient.outlined} onClick={closeModal} />
                </ModalStanderd.Bottom>
            </ModalStanderd>
        </>
    )

}
export function ModalBranchDetails({ show, closeModal, updateUi, fiscalFull, id }) {

    const [errdatas, setErrDatas] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [allowEdit, setallowEdit] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const api = useRESTapi();

    const [data, setData] = useState({
        [partyDef.PartyName]: '',
        [partyDef.Address]: '',
        [partyDef.MobileNumber]: '',
        [partyDef.Email]: '',
        [accountLedgerDef.OpeningBalance]: ''
    })

    const [initialData, setInitialData] = useState({})

    const validationObj = {
        [partyDef.PartyName]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },

        // [partyDef.Address]: {
        //     message: 'required',
        //     validaton: [
        //         (value) => {
        //             if (value && value !== '') {
        //                 return true
        //             }
        //             return 'required'
        //         },
        //     ]
        // },
        [partyDef.MobileNumber]: {
            message: 'required',
            validaton: [
                // (value) => {
                //     if (value && value !== '') {
                //         return true
                //     }
                //     return 'required'
                // },
                (value) => {
                    if (value === "") {
                        return true; // Return empty string for empty input
                    }

                    // Validate for 10-digit phone number format
                    if (/^\d{10}$/.test(value)) {
                        return true; // Return true for valid phone number
                    }
                    return 'not valid'
                },
            ]
        },
        [partyDef.Email]: {
            message: 'required',
            validaton: [
                
                (value) => {
                    if (value === "") {
                        return true; // Return empty string for empty input
                    }
                    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                        return true
                    }
                    return 'Not valid email'
                }
            ]
        },
        [accountLedgerDef.OpeningBalance]: {
            validaton: [
                (value) => {
                    const number = Number(value);
                    if (Number.isFinite(number)) {
                        return true
                    } else {
                        return 'Not valid'
                    }
                },
            ]
        },


    }


    const performValidation = (obj = {}) => {

        let errObj = {}
        let errNotOccured = true;

        for (const key in validationObj) {

            let isPass = true;

            for (let i = 0; i < validationObj[key].validaton.length; i++) {
                isPass = validationObj[key].validaton[i](obj[key])
                if (isPass !== true) {
                    errNotOccured = false
                    errObj[key] = isPass;
                    break;
                }
            }



        }

        setErrDatas(errObj)
        return errNotOccured;

    }



    const onChangeInput = (event) => {

        let valueObj
        valueObj = {
            ...data,
            [event.target.name]: event.target.value
        }


        setData(valueObj)


    }

    async function deleteParty() {
        setIsLoading(true)
        try {
            const res = await api.Delete({
                url: `${process.env.REACT_APP_BACKEND_URL}/api/parties/${id}`
            });
            if (res?.data?.success === true) {
                showToast({
                    message: res.data?.message || 'Party Deleted'
                })
                closeTheModal();
                updateUi();
            } else {
                showErrorToast({
                    message: res?.data?.message || 'Error Deleting party'
                })
            }

        } catch (error) {
            console.log(error)
            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR'
            })
        }
        setIsLoading(false)
        setShowDeleteModal(false)
    }


    const closeTheModal = () => {
        setShowDeleteModal(false)
        setallowEdit(false)
        clearData()
        closeModal()
    }


    const disAllowEdit = () => {
        setallowEdit(false);
        setErrDatas({})
        setData(initialData)
    }




    const clearData = () => {
        setData({
            [partyDef.PartyName]: '',
            [partyDef.Address]: '',
            [partyDef.MobileNumber]: '',
            [partyDef.Email]: '',
            [accountLedgerDef.OpeningBalance]: ''
        })
        setErrDatas({})
    }

    // const closeAndClearModal

    const addRole = async () => {
        setIsLoading(true)
        console.log(data)
        try {

            if (performValidation(data)) {

                if (Number(data[accountLedgerDef.OpeningBalance]) !== 0) {
                    data[TransactionDef.Description] = 'Opening balance &^&%^%$%$#%%^%^%^%^^%'
                }

                const res = await api.put({
                    url: `${process.env.REACT_APP_BACKEND_URL}/api/parties/${id}`,
                    body: data
                },)

                if (res?.data?.success === true) {
                    showToast({
                        message: res.data.message || 'Party Edited'
                    })
                    clearData()
                    closeModal();
                    updateUi();
                } else {
                    if (res && res.data) {
                        const res1 = res.data;
                        if (res1.error) {
                            const error = {};
                            for (let key in res1.error) {
                                error[key] = res1.error[key][0]
                            }
                            console.log(error)
                            setErrDatas(error)
                        }
                    }
                    showErrorToast({
                        message: res?.data?.message || 'Error adding party',
                        timeOut: 5000
                    })
                }

            } else {
                showErrorToast({
                    message: 'Please fill the form',
                    timeOut: 2000
                })
            }

        } catch (err) {
            console.log(err)
            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR',
            })
        }
        setIsLoading(false)
    }




    useEffect(
        () => {
            const loadEmployee = async () => {

                setIsLoading(true)
                try {
                    const res = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/parties/${id}`
                    });
                    if (res?.data?.success === true) {
                        const employee = res.data.data;
                        for (const key in employee) {
                            employee[key] = employee[key] === null || employee[key] === undefined ? '' : employee[key]
                            // employee[key] = undefined
                        }
                        const transaction = employee.transactions[0]
                        if(transaction&&transaction.FT02_TranType ==='R'){
                            employee[accountLedgerDef.OpeningBalance] = -1 * employee[accountLedgerDef.OpeningBalance]
                        }
                        // employee[accountLedgerDef.OpeningBalance] = employee[accountLedgerDef.]
                        setData(employee)
                        setInitialData(employee)
                    } else {
                        showErrorToast({
                            message: res.data?.message || 'Error loading branch'
                        })
                        closeModal()
                    }
                } catch (error) {
                    console.log(error)
                    showErrorToast({
                        message: 'Internal Error !!!',
                        showAtTimeOne: true,
                        id: 'INTERNAL ERROR'
                    })
                    closeModal()

                }

                setIsLoading(false)

            }
            if (show) {
                loadEmployee();
            }
        }, [show]
    )



    return (
        <>
            <ModalLoading show={isLoading} zIndex={1000} />
            <QuestionModal show={showDeleteModal} Question="Do you want to delete party?" onNegative={() => setShowDeleteModal(false)} onPositive={deleteParty} />

            <ModalStanderd title="Party Details" show={show} closeModal={closeModal} afterLeave={closeTheModal} >
                <ModalStanderd.Body>
                    <div className=" flex justify-end gap-1 px-1 pt-1">
                        <IconOnlyButton onClick={() => setallowEdit(true)} icon={<IconEdit />} />
                        <IconOnlyButton onClick={() => setShowDeleteModal(true)} icon={<IconTrash />} />
                    </div>
                    <div className="sm:flex sm:items-start w-full">

                        <div className="mt-3 text-center  sm:mt-0 sm:text-left w-full pb-1">

                            <div className=" w-full flex flex-wrap gap-2 ">
                                <InputStd2 disabled={!allowEdit} errMsg={errdatas[partyDef.PartyName]} className={' grow '} placeholder={'name'} title={'Party Name'} value={data[partyDef.PartyName]} name={partyDef.PartyName} onChange={onChangeInput} />
                                <InputStd2 disabled={!allowEdit} errMsg={errdatas[accountLedgerDef.OpeningBalance]} className={' grow '} placeholder={'opening balance'} title={'Opening balance'} value={data[accountLedgerDef.OpeningBalance]} name={accountLedgerDef.OpeningBalance} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <InputStd2 disabled={!allowEdit} className={'grow'} placeholder={'Mobile'} title={'Mobile'} errMsg={errdatas[partyDef.MobileNumber]} value={data[partyDef.MobileNumber]} name={partyDef.MobileNumber} onChange={onChangeInput} />
                                <InputStd2 disabled={!allowEdit} className={'grow'} placeholder={'Email'} title={'Email'} errMsg={errdatas[partyDef.Email]} value={data[partyDef.Email]} name={partyDef.Email} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                <InputTextAreaStd2 disabled={!allowEdit} className={'basis-full'} rows={3} placeholder={'Address'} title={'Address'} errMsg={errdatas[partyDef.Address]} value={data[partyDef.Address]} name={partyDef.Address} onChange={onChangeInput} />

                            </div>
                        </div>
                    </div>
                </ModalStanderd.Body>
                <ModalStanderd.Bottom>
                    {
                        allowEdit ?
                            <><SquareButton className={'w-min'} text={'Edit'} onClick={addRole} />
                                <SquareButton text={'Cancel'} varient={squareButtonVarient.outlined} onClick={disAllowEdit} /></>
                            :
                            <SquareButton className={' w-[90px]'} text={'Ok'} onClick={closeModal} />}
                </ModalStanderd.Bottom>
            </ModalStanderd>
        </>
    )

}

