import { configureStore } from "@reduxjs/toolkit";
import { configReducer } from "./config/configSlice";
import { responsiveReducer } from "./responsive/responsiveSlice";
import { toastReducer } from "./toast/toastSlice";
import { userReducer } from "./user/userSlice";

export const store = configureStore(
    {
        reducer: {
            config:configReducer,
            responsive:responsiveReducer,
            toast:toastReducer,
            user:userReducer
        }
    }
)