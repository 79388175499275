import React, { useEffect, useState } from 'react'
import { TablePagination, TableStanderd } from '../../../../../../components/table'
import { TransactionDef, partyDef, themeStateDef, transType } from '../../../../../../definitions/definintion';
import { useDispatch, useSelector } from 'react-redux';
import { IconLoader, IconPin, IconPinned, IconTrash } from '@tabler/icons-react';
import { useRESTapi } from '../../../../../../controller/restAPI';
import { showErrorToast } from '../../../../../../components/toast';
import { getMonthRange } from '../../../../../../controller/dateTime';
import { formatNumberWithCommas, formattedDate } from '../../../../../../controller/format';
import { setTopNavText } from '../../../../../../redux/config/configSlice';
import { useNavigate } from 'react-router-dom';
import { IconArrowBackUp } from '@tabler/icons-react';

export default function PinnedTransactions() {

    const searchParamDef = {
        fromDate: 'fromDate',
        toDate: 'toDate',
        party: TransactionDef.PartyID,
        type: TransactionDef.TranType
    }
    const [isLoading, setIsLoading] = useState(false)
    const [selectedEmploye, setSelectedEmploye] = useState('')
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [updateUi, setUpdateUi] = useState(false)
    const [searchParams, setSearchParams] = useState({
        [searchParamDef.fromDate]: getMonthRange().startDate,
        [searchParamDef.toDate]: getMonthRange().endDate,
        [searchParamDef.party]: ''
    })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const api = useRESTapi()
    const theme = useSelector(
        state => state.config.theme
    )
    const [rows1, setRow1] = useState([
        // {
        //     id: '1',
        //     data: {
        //         [accountLedgerDef.LedgerName]: 'Abu',
        //         [accountLedgerDef.OpeningBalance]: '10000',
        //         [accountLedgerDef.CreditPeriod]: '12000',
        //         [accountLedgerDef.CreditLimit]: '13000',
        //         [accountLedgerDef.AccountGroupId]: 'sample',
        //         [accountLedgerDef.IsDefault]: 'sample',
        //         [accountLedgerDef.CrOrDr]: 'sample',
        //         [accountLedgerDef.Narration]: 'sample',
        //         [accountLedgerDef.MailingName]: 'sample',
        //         [accountLedgerDef.Address]: 'sample',
        //         [accountLedgerDef.Phone]: 'sample',
        //         [accountLedgerDef.Mobile]: 'sample',
        //         [accountLedgerDef.Email]: 'sample',
        //         [accountLedgerDef.IsActive]: 1,
        //     }
        // },
    ])
    const [totalRow, setTotalRow] = useState(90)
    const [rowPerPage, setRowPerPage] = useState(30)
    const [pageNo, setpageNo] = useState(1)

    const elements = [
        { delay: -1600 },
        { delay: -1550 },
        { delay: -1500 },
        { delay: -1450 },
        { delay: -1400 },
        { delay: -1350 },
        { delay: -1300 },
        { delay: -1250 },
        { delay: -1200 },
        { delay: -1150 },
        { delay: -1100 },
        { delay: -1050 },
        { delay: -1000 },
    ];

    const headers = [


        {
            id: '4',
            name: 'No',
            label: TransactionDef.SerialNo
        },
        {
            id: '1',
            name: 'Date',
            label: 'dateFormatted'
        },
        {
            id: '6',
            name: 'Description',
            label: 'actualDescription'
        },
        {
            id: '3',
            name: 'Type',
            label: 'type'
        },
        {
            id: '2',
            name: 'Party',
            label: partyDef.PartyName
        },
        {
            id: '5',
            name: 'Amount',
            label: 'commaAmount'
        },
        {
            id: '8',
            name: 'Due Date',
            label: TransactionDef.FT02_expected_received_date
        },
        // {
        //     id: '9',
        //     name: '',
        //     label: 'navigate'
        // },
        {
            id: '7',
            name: '',
            label: 'pinButton'
        },

    ]

    const openDetailsModal = (id, index) => {
        setSelectedEmploye(id)
        setShowDetailsModal(true)
    }

    const rebuildUi = () => {
        setUpdateUi(!updateUi)
    }

    const unPinTransaction = async (id, className) => {

        const index = rows1.findIndex(row => row.data[TransactionDef.TransactionID] == id)
        if (index >= 0) {
            try {
                rows1[index].data.isPinning = true
                setRow1([...rows1])
                const res1 = await api.put({
                    url: `${process.env.REACT_APP_BACKEND_URL}/api/transactions/unpin/${id}`
                })
                rebuildUi()

                if (res1.data.success === true) {
                }
            } catch (error) {

            }
            rows1[index].data.isPinning = false
            setRow1([...rows1])
        }

    }

    const navigateToOther = async (transactionType="P"|"R",transactionDate,transactionId)=>{

        switch (transactionType) {
            case 'P':
                navigate('/home/managements/accounts/transaction/payment')
                break;
        
            default:
                break;
        }
        // navigate('')
    }

    useEffect(
        () => {

            const initialLoad = async () => {

                try {

                    setIsLoading(true)

                    const res = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/transactions_pinned`,
                        params: {
                            // [searchParamDef.type]: transType.payment,
                            // [searchParamDef.fromDate]: searchParams[searchParamDef.fromDate],
                            // [searchParamDef.toDate]: searchParams[searchParamDef.toDate],
                            // [searchParamDef.party]: searchParams[searchParamDef.party],
                            perPage: rowPerPage,
                            page: pageNo
                        }
                    });

                    if (res?.data?.success === true) {
                        const employees = res.data.data;
                        setTotalRow(res.data.totalTransactions)
                        const rows = employees.map(
                            (emp, index) => {
                                emp[TransactionDef.SerialNo] = ((pageNo - 1) * rowPerPage) + (index + 1);
                                emp[partyDef.PartyName] = <div className=' whitespace-nowrap'>{emp.party?.[partyDef.PartyName]}</div>
                                emp['commaAmount'] = formatNumberWithCommas(emp[TransactionDef.ConvertedAmount])
                                switch (emp[TransactionDef.TranType]) {
                                    case transType.payment:
                                        emp['type'] = 'Payment'
                                        break;
                                    case transType.reciept:
                                        emp['type'] = 'Reciept'
                                        break;
                                    case transType.expense:
                                        emp['type'] = 'Expense'
                                        break;

                                    default:
                                        break;
                                }
                                emp['navigate'] =  <TableStanderd.Button onClick={()=>navigateToOther(emp[TransactionDef.TranType],emp[TransactionDef.Date],emp[TransactionDef.TransactionID])} tooltip={'navigate'}> <IconArrowBackUp size={15} /></TableStanderd.Button>
                                emp['actualDescription'] = emp[TransactionDef.Description] === 'Opening balance &^&%^%$%$#%%^%^%^%^^%' ? 'Opening Balance' : emp[TransactionDef.Description]
                                emp['dateFormatted'] = <div className=' whitespace-nowrap'>{formattedDate(emp[TransactionDef.Date])}</div>
                                emp[TransactionDef.FT02_expected_received_date] = <div className=' whitespace-nowrap'>{formattedDate(emp[TransactionDef.FT02_expected_received_date])}</div>

                                return ({
                                    id: emp[TransactionDef.TransactionID],
                                    data: emp
                                })
                            }
                        )
                        setRow1(rows)
                    } else {
                        showErrorToast({
                            message: res?.data?.message || 'something went wrong',
                            showAtTimeOne: true,
                            id: 'paymentCRUD',
                            timeOut: 5000
                        })
                    }


                } catch (error) {
                    showErrorToast({
                        message: 'Internal Error !!!',
                        showAtTimeOne: true,
                        // id: 'INTERNAL ERROR',
                        // showAtTimeOne: true,
                        id: 'paymentCRUD',
                        timeOut: 5000
                    })
                }
                setIsLoading(false)
            }

            initialLoad()
            dispatch(setTopNavText('HOME'))
            return () => {
                dispatch(setTopNavText(''))
            }

        }, [updateUi, pageNo]
    )

    return (
        <div className=' flex flex-col w-full overflow-hidden  rounded-md p-2 sm:p-5'>
            <div className='text-black text-xl font-bold flex gap-1'>
                <IconPin /> <span>Pinned Transactions</span>
            </div>
            <div className='pt-1 pb-5 grow  overflow-hidden flex flex-col gap-2' >
                {isLoading === false ?
                    rows1.length > 0
                        ?
                        <div className=" flex overflow-hidden relative shadow-md sm:rounded-lg">
                            <TableStanderd>
                                <TableStanderd.Head>
                                    {
                                        headers.map(
                                            header => (<th key={header.id} scope="col" className="py-3 px-6">{header.name}</th>)
                                        )
                                    }
                                </TableStanderd.Head>
                                <TableStanderd.Body>
                                    {
                                        rows1.map(
                                            (row, index) => {
                                                if (index === rows1.length - 1) {
                                                    return (
                                                        <TableStanderd.TbRowEnd onClick={() => openDetailsModal(row.id, index)} key={row.id} >
                                                            {
                                                                headers.map(
                                                                    (header, index) => {
                                                                        switch (header.label) {
                                                                            case 'pinButton':
                                                                                if (row.data.isPinning) {
                                                                                    return <TableStanderd.Td key={index}><TableStanderd.Button  > <IconLoader className=' animate-spin' size={15} /></TableStanderd.Button></TableStanderd.Td>

                                                                                } else {
                                                                                    return <TableStanderd.Td key={index}><TableStanderd.Button tooltip={'Unpin'} onClick={(event) => { event.stopPropagation(); unPinTransaction(row.data[TransactionDef.TransactionID], `unpinButtonClassName_${row.data[TransactionDef.TransactionID]}`) }}> <IconPinned className={`unpinButtonClassName_${row.data[TransactionDef.TransactionID]}`} size={15} /></TableStanderd.Button></TableStanderd.Td>
                                                                                }

                                                                            default:
                                                                                return <TableStanderd.Td key={index} >{row.data[header.label]}</TableStanderd.Td>
                                                                        }
                                                                    }
                                                                )
                                                            }

                                                        </TableStanderd.TbRowEnd>
                                                    )
                                                }

                                                return (
                                                    <TableStanderd.TbRow onClick={() => openDetailsModal(row.id, index)} key={row.id} className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 hover:dark:bg-gray-600`}>
                                                        {
                                                            headers.map(
                                                                (header, index) => {
                                                                    switch (header.label) {
                                                                        case 'pinButton':
                                                                            if (row.data.isPinning) {
                                                                                return <TableStanderd.Td key={index}><TableStanderd.Button  > <IconLoader className=' animate-spin' size={15} /></TableStanderd.Button></TableStanderd.Td>

                                                                            } else {
                                                                                return <TableStanderd.Td key={index}><TableStanderd.Button tooltip={'Unpin'} onClick={(event) => { event.stopPropagation(); unPinTransaction(row.data[TransactionDef.TransactionID], `unpinButtonClassName_${row.data[TransactionDef.TransactionID]}`) }}> <IconPinned className={`unpinButtonClassName_${row.data[TransactionDef.TransactionID]}`} size={15} /></TableStanderd.Button></TableStanderd.Td>
                                                                            }

                                                                        default:
                                                                            return <TableStanderd.Td key={index} >{row.data[header.label]}</TableStanderd.Td>
                                                                    }
                                                                }
                                                            )
                                                        }

                                                    </TableStanderd.TbRow>
                                                )
                                            }
                                        )
                                    }
                                </TableStanderd.Body>
                            </TableStanderd>
                        </div>
                        :
                        <div className=' flex grow dark:text-gray-300 text-xl font-medium   items-center justify-center theme-smooth'>No pinned transactions</div>
                    :
                    <div className=' grow flex flex-col gap-2'>


                        {
                            elements.map(
                                (element, index) => (
                                    <div
                                        key={index}
                                        className={`w-full ${index === 0 ? 'h-16' : 'h-11'} bg-gray-600 rounded-md animate-bubblee`}
                                        style={{ animationDelay: `${element.delay}ms` }}
                                    ></div>
                                )
                            )
                        }



                    </div>
                }
                <div className=' flex justify-end'>
                    <TablePagination selectedPage={pageNo} totalRows={totalRow} rowsPerPage={rowPerPage} onClickIndex={(index) => setpageNo(index)} />
                </div>

            </div>
        </div>
    )
}
