
import React from 'react'
import { brandName } from '../../../definitions/staticFiles'
import { useSelector } from 'react-redux'
import { Transition } from '@headlessui/react'
import { sideNavSizesDef } from '../../../definitions/definintion'

export function CompanyProfileSideTab() {
    const sideNav = useSelector(
        state => state.config.sideNav
    )
    return (
        <div className="w-full mt-3 mb-7 flex justify-center items-end">
            <img src="/assets/logo.png" alt="" className="h-[50px] w-[50px]" />
            <Transition
                className={'grow block'}
                show={sideNav.size === sideNavSizesDef.expanded ? true : false}
                enter="ease-in duration-300 w-0"
                enterFrom="opacity-0 w-auto"
                enterTo="opacity-100 grow"
                leave="ease-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0 w-0 grow-0"
            >
                <p className=" theme-smooth font-montserrat leading-10 text-2xl font-extrabold text-gray-900 dark:text-gray-200 ">{brandName} </p>
            </Transition>
        </div>
    )
}
