import React, { useEffect } from 'react'
import { BodyWrapperSub, BodyWrapperSubNoPadding } from '../../../../../components/layouts'
import { useDispatch } from 'react-redux'
import { setTopNavText } from '../../../../../redux/config/configSlice'
import PinnedTransactions from './components/pinnedTransactions'

export default function HomePage() {


  const dispatch = useDispatch()

  useEffect(
    () => {
      dispatch(setTopNavText(''))
      return () => {
        dispatch(setTopNavText(''))
      }
    }, []
  )
  return (
    <BodyWrapperSubNoPadding className={'flex flex-col  grow'}>
      <div className=' grow p-5 flex'>
        {/* <div className=" bg-cover bg-center rounded-md h-full shadow-md" style={{ backgroundImage: 'url("/assets/homeBg.jpg")' }}>
          
        </div> */}
        <PinnedTransactions/>

      </div>

    </BodyWrapperSubNoPadding>
  )
}
