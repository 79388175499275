import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
// import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import 'simplebar-react/dist/simplebar.min.css';
import ReactDOM from 'react-dom'
import { SquareButton } from './button';
import { squareButtonVarient } from '../definitions/definintion';
import { IconX } from '@tabler/icons-react';
import { IconAlertOctagonFilled } from '@tabler/icons-react';

export function Modalsimple({
  show = false,
  closeModal = () => { },
  children,
  afterLeave = () => { },
  closeClickOutside = true
}) {

  const initialFocusRef = useRef(null)

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={() => { if (closeClickOutside) closeModal() }} initialFocus={initialFocusRef}  >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"


        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray-800/80 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              afterLeave={afterLeave}
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-lg bg-white dark:bg-primaryy-dark-1000 dark:border-2 border-gray-500 text-left shadow-xl transition-all sm:mx-5 w-full sm:max-w-3xl">
                <div ref={initialFocusRef} className=''></div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function ModalStanderd({
  show = false,
  closeModal = () => { },
  children,
  title = 'Title Here',
  afterLeave = () => { },
  closeClickOutside = true
}) {

  const initialFocusRef = useRef(null)

  return (
    <Modalsimple
      show={show}
      closeModal={closeModal}
      afterLeave={afterLeave}
      closeClickOutside={closeClickOutside}
    >
      <div className=' w-full' >
        <div onClick={closeModal} className=' absolute right-4 top-2 h-7 w-7 text-gray-500'>
          <IconX className=' cursor-pointer hover:text-gray-700 dark:hover:text-gray-400' />
        </div>
        <Dialog.Title as="h3" className="bg-gray-100 dark:bg-primaryy-dark-950/20 text-center font-bold leading-6 text-gray-900 dark:text-gray-300 h-11 flex items-center justify-center">
          {title}
        </Dialog.Title>

        {children}
      </div>
    </Modalsimple>
  )
}
ModalStanderd.Bottom = ({ children }) => {
  return (
    <div className="bg-gray-100 dark:bg-primaryy-dark-950/20 gap-x-3 px-4 py-3 flex flex-row-reverse sm:px-6">
      {children}
    </div>
  )
}

ModalStanderd.Body = ({
  children
}) => {
  return (
    <div className=" max-h-[80vh] overflow-x-hidden overflow-y-auto px-4 pb-4 pt-1 sm:px-6 sm:pb-4 w-full">
      {children}
    </div>
  )
}



export function ModalLoading({ show = false, closeModal = () => { }, children, zIndex = 50 }) {


  return (
    <Transition.Root show={show} >
      <Transition.Child
        // as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"

      >
        {
          ReactDOM.createPortal(
            <div onClick={() => { }} className={`fixed inset-0  bg-gray-500/50 transition-opacity`} style={{ zIndex: zIndex }} >
              <div className={`flex space-x-1 justify-center items-center  h-screen z-50 `}>
                <span className='sr-only'>Loading...</span>
                <div className='h-2 w-2 bg-primaryy-400 rounded-full animate-bounce  [animation-delay:-0.3s]'></div>
                <div className='h-2 w-2 bg-primaryy-400 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                <div className='h-2 w-2 bg-primaryy-400 rounded-full animate-bounce'></div>
              </div>
            </div>,
            document.body
          )
        }
      </Transition.Child>


    </Transition.Root>
  )
}


export function ModalAlert({ show = false, closeModal = () => { }, children, zIndex = 100,className,centered=false }) {


  return (


    ReactDOM.createPortal(
      <Transition
        show={show}


      >
        <Transition.Child
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-0"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 "
        >

          <div onClick={() => { }} className={`fixed inset-0  bg-gray-500/50 transition-opacity flex justify-center`} style={{ zIndex: zIndex }} >

          </div>
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-0 translate-y-[10vh]"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-50 -translate-y-[50vh]"
        >

          <div onClick={() => { }} className={`fixed inset-0   flex justify-center ${centered===true?'items-center':''}`} style={{ zIndex: zIndex }} >
            <div className={`mt-3 relative transform  overflow-hidden rounded-lg bg-white dark:bg-primaryy-dark-1000 dark:border-2 border-gray-500 text-left shadow-xl transition-all sm:mx-5 sm:max-w-3xl h-min ${className}`}>
              {children}
            </div>
          </div>
        </Transition.Child>
      </Transition>
      ,
      document.body
    )

  )
}



export function QuestionModal({ show = false, zIndex = 100, Question = '', onPositive = () => { }, onNegative = () => { } }) {


  return (
    <ModalAlert show={show} zIndex={zIndex}>
      <div className=' p-3 min-w-[150px] max-w-[200px] flex flex-col  '>
        <div className=' theme-smooth text-xs font-semibold text-center text-gray-950 dark:text-gray-300'>{Question}</div>
        <div className=' flex flex-row justify-end gap-2 pt-3'>
          <SquareButton varient={squareButtonVarient.outlined} text={'No'} onClick={onNegative} />
          <SquareButton varient={squareButtonVarient.primary} text={'Yes'} onClick={onPositive} className={' w-min  '} />
        </div>
      </div>
    </ModalAlert>
  )

}

export function OkModal({ show = false, zIndex = 100, content = '', onPositive = () => { } }) {


  return (
    <ModalAlert show={show} zIndex={zIndex} centered={true}>
      <div className=' p-3 min-w-[150px] max-w-[200px] flex flex-col  '>
        <div className=' theme-smooth text-xs font-semibold text-center text-gray-950 dark:text-gray-300'>{content}</div>
        <div className=' flex flex-row justify-center gap-2 pt-3'>
          <SquareButton varient={squareButtonVarient.primary} text={'Ok'} onClick={onPositive} className={' w-min min-w-12 '} />
        </div>
      </div>
    </ModalAlert>
  )

}

export function ErrorModal({ show = false, zIndex = 100, Error = '', onPositive = () => { }, onNegative = () => { } }) {


  return (
    <ModalAlert show={show} zIndex={zIndex} className={'!bg-transparent dark:!bg-transparent !border-none'}  >
      <div className=' px-5 py-3 min-w-[150px] max-w-[200px] flex flex-col items-center gap-3 border-2 rounded-lg bg-white dark:bg-primaryy-dark-1000 border-red-300  '>
        <IconAlertOctagonFilled className='text-red-700' />
        <div className=' theme-smooth text-xs font-semibold text-center text-red-500 dark:text-red-300'>{Error}</div>
        <div className=' flex flex-row justify-center gap-2 '>
          {/* <SquareButton varient={squareButtonVarient.outlined} text={'No'} onClick={onNegative} /> */}
          <SquareButton varient={squareButtonVarient.danger} text={'Ok'} onClick={onPositive} className={' w-min  '} />
        </div>
      </div>
    </ModalAlert>
  )

}


export { ModalStanderd }