import { createSlice } from "@reduxjs/toolkit";
import { themeDef, themeStateDef, toastTypeDef } from "../../definitions/definintion";


const initialState = {
    toasts: [],
    id: 0
}




const toastSlice = createSlice(
    {
        name: 'toast',
        initialState: initialState,
        reducers: {
            showToast: (state, action) => {
                // {
                //     message:'',
                //     timout:2000,
                //     type:
                // }
                if (action.payload.showAtTimeOne === true) {
                    const index = state.toasts.findIndex(tost => tost.id === action.payload.id)
                    if (index < 0) {
                        state.toasts.push(
                            {
                                message: action.payload.message,
                                timeOut: action.payload.timeOut ? action.payload.timeOut : 4000,
                                id: action.payload.id,
                                type: action.payload.type ? action.payload.type : toastTypeDef.success
                            }
                        )
                    }
                } else {

                    state.toasts.push(
                        {
                            message: action.payload.message,
                            timeOut: action.payload.timeOut ? action.payload.timeOut : 4000,
                            id: state.id,
                            type: action.payload.type ? action.payload.type : toastTypeDef.success
                        }
                    )
                    state.id = state.id + 1
                }
            },
            deleteToastWithId: (state, action) => {
                const idToDelete = action.payload;

                // Filter out the toast with the matching id
                state.toasts = state.toasts.filter((toast) => toast.id !== idToDelete);
                // console.log('filtered array',finalArray)
            }

        }
    }
)


export const toastReducer = toastSlice.reducer;
export const { showToast, deleteToastWithId } = toastSlice.actions;