import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { sideNavSizesDef, themeDef, themeStateDef } from "../../../definitions/definintion"
import { Link, useNavigate } from "react-router-dom"
import { IconDotsVertical } from "@tabler/icons-react"
import { IconButton } from "../../../components/button"
import MenuSimple from "../../../components/menu"
import { Transition } from "@headlessui/react"
import axios from "axios"
import { showToast } from "../../../components/toast"



export function UserProfile() {
    const [show, setShow] = useState(false)
    const navigate = useNavigate();
    const theme = useSelector(
        state => state.config.theme
    )
    const user = useSelector(
        state => state.user.user
    )
    const sideNav = useSelector(
        state => state.config.sideNav
    )
    const toggleSettings = () => {
        setShow(!show)
    }


    const options = [
        {
            name: 'Settings',
            icon: 'bx-cog',
            iconFilled: 'bxs-cog',
            state: 'Settings',
            link: ''

        },
        {
            name: 'Logout',
            icon: 'bx-log-out',
            iconFilled: 'bxs-log-out',
            state: 'Logout',
            // link: '/',
            onClick:async()=>{
                const headers = {
                    Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE)}`,
                };
                localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE)
                try {
                    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/logout`,{ headers })
                    if(res.data.success===true){
                        showToast({
                            message:res.data.message
                        })
                    }
                } catch (error) {
                    console.log(error)
                }
                navigate('/',{replace:true})
            }

        },
    ]
    return (
        <div className=" flex h-[50px] mt-5 w-full gap-1  items-center justify-center" >
            <img src="https://pics.craiyon.com/2023-07-15/dc2ec5a571974417a5551420a4fb0587.webp"
                alt=""
                className={` theme-smooth h-[40px] w-[40px] object-cover object-center ${sideNav.size === sideNavSizesDef.expanded?'rounded-md':'rounded-full'} border-2 border-black dark:border-gray-500`}
            />
            <Transition
                className={'grow flex overflow-hidden '}
                show={sideNav.size === sideNavSizesDef.expanded ? true : false}
                enter="ease-in duration-300"
                enterFrom="opacity-0 grow-0"
                enterTo="opacity-100  grow"
                leave="ease-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0 w-0 grow-0"
            >
                <div className="grow  flex flex-row truncate overflow-hidden ">
                    <div className="theme-smooth flex flex-col w-full text-gray-800 dark:text-gray-200 font-montserrat  text-xs">
                        <p className=" truncate font-bold w-full">{user?.email}</p>
                        <p className=" truncate text-sm font-light w-full">{user?.email}</p>
                    </div>

                </div>

            </Transition>

            {
                sideNav.size === sideNavSizesDef.expanded ?
                    <MenuSimple
                        show={show}
                        closeMenu={() => setShow(false)}
                        className={'h-full'}
                        top={-75}
                        left={0}
                        label={<div className={` ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ' transition-colors duration-100]'} h-full ${show === true ? 'bg-gray-500/20' : 'hover:bg-gray-500/20'}  rounded-md flex items-center dark:text-gray-200 cursor-pointer `} onClick={toggleSettings} ><IconDotsVertical /></div>}
                    >
                        {
                            options.map(
                                (item) => {
                                    return (
                                        <div
                                            onClick={item.onClick}
                                            key={item.state} className="min-w-[20px] rounded-md pe-6 py-1 text-sm text-gray-700 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900  active:bg-blue-100 cursor-pointer flex items-end" role="menuitem">
                                            <div className="leading-none px-1 text-lg"><i className={`bx ${item.icon} `}></i></div> {item.name}
                                        </div>
                                    )
                                }
                            )
                        }
                    </MenuSimple> :
                    null
            }

        </div>
    )

}
export function UserProfile2() {
    const [show, setShow] = useState(false)
    const theme = useSelector(
        state => state.config.theme
    )
    const toggleShowDropdown = () => {
        setShow(!show)
    }
    const divRef = useRef();

    const options = [
        {
            name: 'Settings',
            icon: 'bx-cog',
            iconFilled: 'bxs-cog',
            state: 'Settings',
            link: ''

        },
        {
            name: 'Logout',
            icon: 'bx-log-out',
            iconFilled: 'bxs-log-out',
            state: 'Logout',
            link: '/login'

        },
    ]

    const clickOutside = (event) => {
        if (!divRef.current?.contains(event.target)) {
            setShow(false)
        }
    }



    useEffect(
        () => {

            // Close the dropdown when clicking outside of it
            window.addEventListener('click', clickOutside);

            return () => {

                // Close the dropdown when clicking outside of it
                window.removeEventListener('click', clickOutside);

            }

        }, []
    )
    return (
        <div className=" relative flex h-[50px] w-full gap-2 items-center" ref={divRef}>
            <img src="https://pics.craiyon.com/2023-07-15/dc2ec5a571974417a5551420a4fb0587.webp"
                alt=""
                className=" theme-smooth h-[40px] w-[40px] object-cover object-center rounded-md border-2 border-black dark:border-gray-500"
            />
            <div className=" flex flex-row truncate overflow-hidden ">
                <div className="theme-smooth flex flex-col w-full text-gray-800 dark:text-gray-200 font-montserrat  text-xs">
                    <p className=" truncate font-bold w-full">Vela Aguilar</p>
                    <p className=" truncate text-sm font-light w-full">vela@dashboard.comldsfjlsdf</p>
                </div>

            </div>
            <div className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} me-1 hover:bg-gray-200 dark:hover:bg-primaryy-dark-1000 text-gray-800 dark:text-gray-300 rounded cursor-pointer`} onClick={toggleShowDropdown}>
                <i className='bx bx-chevron-down'></i>
            </div>

            <div className={`${show ? 'visible opacity-100' : 'hidden opacity-0'} transition-opacity duration-100 origin-top-right absolute ml-48 mb-[130px]  rounded-md shadow-lg bg-white dark:bg-gray-950 dark:border dark:border-gray-600 ring-1 ring-black ring-opacity-5 z-10`}>
                <div className="py-2 p-2" role="menu" aria-orientation="vertical" aria-labelledby="dropdown-button">
                    {
                        options.map(
                            (item) => {
                                return (
                                    <Link
                                        onClick={() => {
                                            // onClickOptions(item.state)
                                            toggleShowDropdown()
                                        }}
                                        to={item.link}
                                        key={item.state} className="flex block rounded-md pe-6 py-1 text-sm text-gray-700 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900  active:bg-blue-100 cursor-pointer flex items-end" role="menuitem">
                                        <div className="leading-none px-1 text-lg"><i className={`bx ${item.icon} `}></i></div> {item.name}
                                    </Link>
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
    )

}