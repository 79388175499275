import { useDispatch, useSelector } from "react-redux"
import { TableMenu, TablePagination, TableStanderd } from "../../../../../../components/table"
import { TransactionDef, accountGroupDef, ledgerPostingDef, squareButtonVarient, themeStateDef } from "../../../../../../definitions/definintion"
import { setTopNavText } from "../../../../../../redux/config/configSlice"
import { showErrorToast } from "../../../../../../components/toast"
import { useEffect, useState } from "react"
import { useRESTapi } from "../../../../../../controller/restAPI"
import { formatNumberWithCommas } from "../../../../../../controller/format"
import MenuSimple from "../../../../../../components/menu"
import { SquareIconButton } from "../../../../../../components/button"
import { InputStd2, SelectBoxInputBox } from "../../../../../../components/inputs"
import { IconFileDownload, IconFilter, IconRotateClockwise2 } from "@tabler/icons-react"
// import { TransactionDebit } from "./components/transactionDebit"

export function ProfitAndLoss() {

    const [showAddModal, setShowAddModal] = useState(false)
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [selectedEmploye, setSelectedEmploye] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [updateUi, setUpdateUi] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [showTransactionDebit, setshowTransactionDebit] = useState(false)
    const [isDebitedOrCredited, setisDebitedOrCredited] = useState('')
    const [rowEndValues, setRowEndValues] = useState({
        totalCredit: 0,
        totalDebit: 0
    })
    const [heading, setHeading] = useState('')
    const [transactionId, setTransactionId] = useState(null)
    const searchParamDef = {
        fromDate: 'start_date',
        toDate: 'end_date',
        party: TransactionDef.PartyID,
        type: TransactionDef.TranType
    }

    const profitAndLossDef = {
        totatlCredit: 'totalRevenue',
        totalDebit: 'totalExpenses',
        netProfit: 'netProfit',
        revenueDetails: 'revenueDetails',
        expenseDetails: 'expenseDetails',
        groupName: 'groupName',
        groupTotal: 'total',
        groupId: 'groupId'
    }

    const [searchParams, setSearchParams] = useState({
        [searchParamDef.fromDate]: '',
        [searchParamDef.toDate]: '',
    })
    const [showFilterModal, setShowFilterModal] = useState(false)
    const api = useRESTapi()
    const [rows1, setRow1] = useState([

    ])
    const rebuildUi = () => {
        setUpdateUi(!updateUi)
    }
    const dispatch = useDispatch()



    const onChangeSearchParams = (event) => {
        searchParams[event.target.name] = event.target.value;
        console.log(searchParams)
        setSearchParams({
            ...searchParams
        })
    }


    useEffect(
        () => {

            const initialLoad = async () => {

                setIsLoading(true)

                try {
                    const res = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/account-groups/profit-and-loss`,
                        params: {
                            [searchParamDef.fromDate]: searchParams[searchParamDef.fromDate],
                            [searchParamDef.toDate]: searchParams[searchParamDef.toDate],
                        }
                    });

                    if (res?.data?.success === true) {
                        const debit = res.data.data.expenseDetails;
                        const credit = res.data.data.revenueDetails;

                        const rowCount = Math.max(debit.length, credit.length)
                        const rows = []
                        for (let i = 0; i < rowCount; i++) {
                            const row = {}
                            //debit amount storing in row
                            if (i < debit.length) {
                                row.debitId = debit[i][profitAndLossDef.groupId];
                                row.debitAmount = formatNumberWithCommas(debit[i][profitAndLossDef.groupTotal])
                                row.debitGroup = debit[i][profitAndLossDef.groupName]
                            }
                            //credit amount storing in row
                            if (i < credit.length) {
                                row.creditId = credit[i][profitAndLossDef.groupId];
                                row.creditAmount = formatNumberWithCommas(credit[i][profitAndLossDef.groupTotal])
                                row.creditGroup = credit[i][profitAndLossDef.groupName]
                            }
                            rows.push({ id: i, data: row })

                        }
                        // console.log(rows)

                        setRow1(rows)
                        rowEndValues.totalCredit = res.data.data[profitAndLossDef.totatlCredit]
                        rowEndValues.totalDebit = res.data.data[profitAndLossDef.totalDebit]
                        // console.log(rowEndValues)
                        setRowEndValues({ ...rowEndValues })
                    } else {
                        showErrorToast({
                            message: res?.data?.message || 'something went wrong',
                            showAtTimeOne: true,
                            id: 'trialBalance',
                            timeOut: 5000
                        })
                    }


                } catch (error) {
                    console.log(error)
                    showErrorToast({
                        message: 'Internal Error !!!',
                        showAtTimeOne: true,
                        id: 'INTERNAL ERROR'
                    })
                }
                setIsLoading(false)
            }

            initialLoad()
            dispatch(setTopNavText('Profit And Loss'))
            return () => {
                dispatch(setTopNavText(''))
            }

        }, [updateUi]
    )

    const options = [
        {
            name: 'Download',
            // icon: <IconFileDownload />,
            icon: isDownloading === false ? <IconFileDownload /> : <IconRotateClockwise2 className=' animate-spin duration-100' />,
            state: 'download',
            link: '',
            onClick: async ({ closeMenu }) => {
                if (isDownloading === false) {
                    const fileName = 'profit_and_loss.xlsx';
                    setIsDownloading(true)
                    try {
                        const response = await api.get({
                            url: `${process.env.REACT_APP_BACKEND_URL}/api/account-groups/profit-and-loss`,
                            params: {
                                [searchParamDef.fromDate]: searchParams[searchParamDef.fromDate],
                                [searchParamDef.toDate]: searchParams[searchParamDef.toDate],
                                export: 'excel',
                            },
                            responseType: 'blob',
                        });
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        closeMenu()

                    } catch (error) {
                        console.error('Download error:', error);
                        showErrorToast({
                            message: 'Error downloading !!!'
                        })

                    }
                    setIsDownloading(false)
                }

            }

        },
    ]

    const headers = [


        {
            id: '1',
            name: 'Particulars',
            label: 'debitGroup'
        },
        {
            id: '2',
            name: 'Amount',
            label: 'debitAmount'
        },
        {
            id: '3',
            name: 'Particulars',
            label: 'creditGroup'
        },
        {
            id: '4',
            name: 'Amount',
            label: 'creditAmount'
        },


    ]


    const openDetailsModal = (id, index) => {
        setSelectedEmploye(id)
        setShowDetailsModal(true)
    }

    const theme = useSelector(
        state => state.config.theme
    )

    const elements = [
        { delay: -1600 },
        { delay: -1550 },
        { delay: -1500 },
        { delay: -1450 },
        { delay: -1400 },
        { delay: -1350 },
        { delay: -1300 },
        { delay: -1250 },
        { delay: -1200 },
        { delay: -1150 },
        { delay: -1100 },
        { delay: -1050 },
        { delay: -1000 },
    ];


    return (
        <div className=' grow flex flex-col overflow-hidden '>
            <div className='flex justify-end items-center gap-2 '>

                <MenuSimple
                    closeInsideTouch={false}
                    show={showFilterModal}
                    closeMenu={() => setShowFilterModal(false)}
                    top={32}
                    right={0}
                    label={<SquareIconButton onClick={() => setShowFilterModal(true)} varient={squareButtonVarient.outlined} icon={<IconFilter />} text={'Filter'} />}
                >
                    <div className=' max-w-60 w-60 flex flex-col py-3'>
                        <div className=' text-sm font-bold flex pb-1 justify-start w-full'>By Date</div>
                        <div className='flex flex-col ps-1'>
                            <InputStd2 title='From' type='date' onChange={onChangeSearchParams} value={searchParams[searchParamDef.fromDate]} name={searchParamDef.fromDate} />
                            <InputStd2 title='To' onChange={onChangeSearchParams} value={searchParams[searchParamDef.toDate]} name={searchParamDef.toDate} type='date' />
                        </div>
                        {/* <div className=' text-sm font-bold flex pb-1 justify-start w-full pt-2'>By Party</div> */}

                        <div className=' w-full flex justify-end pt-2'>
                            <SquareIconButton text={'Search'} onClick={() => { rebuildUi(); setShowFilterModal(false); }} />
                        </div>
                    </div>
                </MenuSimple>
                <TableMenu options={options} />

            </div>
            <div className='pt-1 pb-5 grow  overflow-hidden flex flex-col ' >
                {isLoading === false ?
                    rows1.length > 0
                        ?
                        <>
                            <div className=" flex overflow-hidden relative shadow-md sm:rounded-lg">
                                <TableStanderd>
                                    <TableStanderd.Head>
                                        {
                                            headers.map(
                                                header => (<th key={header.id} scope="col" className="py-3 px-6">{header.name}</th>)
                                            )
                                        }
                                    </TableStanderd.Head>
                                    <TableStanderd.Body>
                                        {
                                            rows1.map(
                                                (row, index) => {

                                                    return (
                                                        <TableStanderd.TbRow onClick={() => openDetailsModal(row.id, index)} key={row.id} className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 hover:dark:bg-gray-600`}>
                                                            {
                                                                headers.map(
                                                                    (header, index) => (
                                                                        <TableStanderd.Td key={index} >{row.data[header.label]}</TableStanderd.Td>
                                                                    )
                                                                )
                                                            }

                                                        </TableStanderd.TbRow>
                                                    )
                                                }
                                            )
                                        }
                                        {/* TOTAL BALANCE */}
                                        {
                                            <TableStanderd.TbRowEnd
                                                coloured={true}

                                            >
                                                {
                                                    headers.map(
                                                        (header, index) => {
                                                            if (index === headers.length - 1) {

                                                            }
                                                            switch (index) {
                                                                case 0:
                                                                    return <TableStanderd.Td key={index} ></TableStanderd.Td >

                                                                case 1:
                                                                    return <TableStanderd.Td key={index} >{formatNumberWithCommas(rowEndValues.totalDebit)}</TableStanderd.Td >

                                                                case 2:
                                                                    return <TableStanderd.Td key={index} ></TableStanderd.Td >

                                                                case 3:
                                                                    return <TableStanderd.Td key={index} >{formatNumberWithCommas(rowEndValues.totalCredit)}</TableStanderd.Td >
                                                                default:
                                                                    break;
                                                            }
                                                            return <TableStanderd.Td key={index} ></TableStanderd.Td >
                                                        }
                                                    )
                                                }

                                            </TableStanderd.TbRowEnd>
                                        }
                                    </TableStanderd.Body>
                                </TableStanderd>
                            </div>
                            {/* TRANSACTIONS DEBIT */}
                            {/* <TransactionDebit show={showTransactionDebit} id={transactionId} fromDate={searchParams[searchParamDef.fromDate]} type={isDebitedOrCredited} toDate={searchParams[searchParamDef.toDate]} closeScreen={() => setshowTransactionDebit(false)} heading={heading} /> */}
                        </>
                        :
                        <div className=' flex grow dark:text-gray-300 text-3xl font-bold  items-center justify-center theme-smooth'>No Records Found</div>
                    :
                    <div className=' grow flex flex-col gap-2'>


                        {
                            elements.map(
                                (element, index) => (
                                    <div
                                        key={index}
                                        className={`w-full ${index === 0 ? 'h-16' : 'h-11'} bg-gray-600 rounded-md animate-bubblee`}
                                        style={{ animationDelay: `${element.delay}ms` }}
                                    ></div>
                                )
                            )
                        }



                    </div>
                }

            </div>
        </div>
    )
}