import { IconFileDownload, IconFilter, IconRotateClockwise2 } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { BsBoxArrowUpRight } from "react-icons/bs"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SquareIconButton } from '../../../../../../components/button'
import { SelectBoxInputBox } from '../../../../../../components/inputs'
import MenuSimple from '../../../../../../components/menu'
import { TableMenu, TableStanderd } from '../../../../../../components/table'
import { showErrorToast } from '../../../../../../components/toast'
import { formatNumberWithCommas } from '../../../../../../controller/format'
import { useRESTapi } from '../../../../../../controller/restAPI'
import { TransactionDef, partyDef, squareButtonVarient } from '../../../../../../definitions/definintion'
import { setTopNavText } from '../../../../../../redux/config/configSlice'
export function PartyBalanceSummery() {

    const searchParamDef = {
        party: TransactionDef.PartyID,
    }
    const navigate = useNavigate()
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [selectedEmploye, setSelectedEmploye] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [updateUi, setUpdateUi] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [balance, setBalance] = useState()
    const [isDownloading, setIsDownloading] = useState(false)
    const [searchParams, setSearchParams] = useState({
        [searchParamDef.party]: null
    })
    const [rowEndValues, setRowEndValues] = useState({
        totalReceipts: 0,
        totalPayments: 0,
        balance:0
    })
    const api = useRESTapi()
    const [parties, setParties] = useState([])
    const onChangeSearchParams = (event) => {
        searchParams[event.target.name] = event.target.value;
        setSearchParams({
            ...searchParams
        })
    }

    const partySummeryDef = {
        "partyId": 'partyId',
        "partyName": "partyName",
        "totalReceipts": 'totalReceipts',
        "totalPayments": 'totalPayments',
        "balance": 'balance'
    }

    const [rows1, setRow1] = useState([
        // {
        //     id: '1',
        //     data: {
        //         [accountLedgerDef.LedgerName]: 'Abu',
        //         [accountLedgerDef.OpeningBalance]: '10000',
        //         [accountLedgerDef.CreditPeriod]: '12000',
        //         [accountLedgerDef.CreditLimit]: '13000',
        //         [accountLedgerDef.AccountGroupId]: 'sample',
        //         [accountLedgerDef.IsDefault]: 'sample',
        //         [accountLedgerDef.CrOrDr]: 'sample',
        //         [accountLedgerDef.Narration]: 'sample',
        //         [accountLedgerDef.MailingName]: 'sample',
        //         [accountLedgerDef.Address]: 'sample',
        //         [accountLedgerDef.Phone]: 'sample',
        //         [accountLedgerDef.Mobile]: 'sample',
        //         [accountLedgerDef.Email]: 'sample',
        //         [accountLedgerDef.IsActive]: 1,
        //     }
        // },
    ])
    const rebuildUi = () => {
        setUpdateUi(!updateUi)
    }
    const dispatch = useDispatch()



    useEffect(
        () => {

            const initialLoad = async () => {

                try {
                    setIsLoading(true)
                    const res1 = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/parties`
                    })
                    if (res1.data.success === true) {
                        setParties([
                            ...res1.data.data.map(items => ({
                                name: items[partyDef.PartyName],
                                value: items[partyDef.PartyID],
                            }))
                        ])
                    } else {
                        showErrorToast({
                            message: res1.data.message
                        })
                        setIsLoading(false)
                        return;
                    }

                    const res = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/listPartySums`,
                        params: {
                            // [searchParamDef.type]: searchParams[searchParamDef.type],
                            // [searchParamDef.fromDate]: searchParams[searchParamDef.fromDate],
                            // [searchParamDef.toDate]: searchParams[searchParamDef.toDate],
                            [searchParamDef.party]: searchParams[searchParamDef.party],
                        }
                    });

                    if (res?.data?.success === true) {
                        const employees = res.data.data.parties;

                        const rows = employees.map(
                            (emp, index) => {
                                // emp = emp.transaction_details
                                emp['Serial No'] = index + 1;
                                emp['commaReciept'] = formatNumberWithCommas(emp[partySummeryDef.totalReceipts])
                                emp['commaPayment'] = formatNumberWithCommas(emp[partySummeryDef.totalPayments])
                                emp['commabalance'] = formatNumberWithCommas(emp[partySummeryDef.balance])
                                emp['forwardButton'] = (
                                    <div onClick={() => navigate(`../partybalance/${emp[partySummeryDef.partyId]}`)} className=' p-2 border border-transparent hover:border-gray-400/50 w-min h-min rounded-[4px] cursor-pointer hover:shadow shadow-gray-300 duration-100'><BsBoxArrowUpRight size={15} /></div>
                                )
                                // emp.balance = 10000
                                return ({
                                    id: emp[partySummeryDef.partyId],
                                    data: emp
                                })
                            }
                        )
                        setRow1(rows)
                        if(rows.length<=1){
                            setRowEndValues(null)
                        }else{
                            const rowEndValues={}
                            rowEndValues.totalReceipts = res.data.data.grandTotals.totalReceipts
                            rowEndValues.totalPayments = res.data.data.grandTotals.totalPayments
                            rowEndValues.balance = res.data.data.grandTotals.balance
                            // console.log(rowEndValues)
                            setRowEndValues({ ...rowEndValues })
                        }
                    } else {
                        showErrorToast({
                            message: res?.data?.message || 'something went wrong',
                            showAtTimeOne: true,
                            id: 'transactionReport',
                            timeOut: 5000
                        })
                        setIsLoading(false)
                        return;
                    }

                } catch (error) {
                    console.log(error)
                    showErrorToast({
                        message: 'Internal Error !!!',
                        showAtTimeOne: true,
                        id: 'INTERNAL ERROR',
                        timeOut: 5000
                    })
                }
                setIsLoading(false)
            }

            initialLoad()
            dispatch(setTopNavText('Party Balance Summary'))
            return () => {
                dispatch(setTopNavText(''))
            }

        }, [updateUi]
    )

    

    const headers = [


        {
            id: '4',
            name: 'No',
            label: 'Serial No'
        },
        {
            id: '3',
            name: 'Name',
            label: partySummeryDef.partyName
        },
        {
            id: '1',
            name: 'Reciept',
            label: 'commaReciept'
        },
        {
            id: '2',
            name: 'Payments',
            label: 'commaPayment'
        },
        {
            id: '5',
            name: 'Balance',
            label: 'commabalance'
        },
        {
            id: '6',
            name: '',
            label: 'forwardButton'
        },

    ]


    const openDetailsModal = (id, index) => {
        setSelectedEmploye(id)
        setShowDetailsModal(true)
    }

    const theme = useSelector(
        state => state.config.theme
    )

    const elements = [
        { delay: -1600 },
        { delay: -1550 },
        { delay: -1500 },
        { delay: -1450 },
        { delay: -1400 },
        { delay: -1350 },
        { delay: -1300 },
        { delay: -1250 },
        { delay: -1200 },
        { delay: -1150 },
        { delay: -1100 },
        { delay: -1050 },
        { delay: -1000 },
    ];

    const options = [
        {
            name: 'Download',
            // icon: <IconFileDownload />,
            icon: isDownloading === false ? <IconFileDownload /> : <IconRotateClockwise2 className=' animate-spin duration-100' />,
            state: 'download',
            link: '',
            onClick: async ({ closeMenu }) => {
                if (isDownloading === false) {
                    const fileName = 'Party_balance_summery.xlsx';
                    setIsDownloading(true)
                    try {
                        const response = await api.get({
                            url: `${process.env.REACT_APP_BACKEND_URL}/api/listPartySums`,
                            params: {
                                [searchParamDef.party]: searchParams[searchParamDef.party],
                                export: 'excel'
                            },
                            responseType: 'blob',
                        });
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        closeMenu()

                    } catch (error) {
                        console.error('Download error:', error);
                        showErrorToast({
                            message: 'Error downloading !!!'
                        })

                    }
                    setIsDownloading(false)
                }

            }

        },
    ]


    return (
        <div className=' grow flex flex-col overflow-hidden'>
            <div className='flex justify-end items-center gap-2 '>
                <MenuSimple
                    closeInsideTouch={false}
                    show={showFilterModal}
                    closeMenu={() => setShowFilterModal(false)}
                    top={32}
                    right={0}
                    label={<SquareIconButton onClick={() => setShowFilterModal(true)} varient={squareButtonVarient.outlined} icon={<IconFilter />} text={'Filter'} />}
                >
                    <div className=' max-w-60 w-60 flex flex-col py-3'>

                        <div className=' text-sm font-bold flex pb-1 justify-start w-full pt-2'>By Party</div>
                        <div className='ps-1'>
                            <SelectBoxInputBox option={[
                                {
                                    value: null,
                                    name: 'All'
                                },
                                ...parties
                            ]} onChange={onChangeSearchParams} value={searchParams[searchParamDef.party]} name={searchParamDef.party} className={' grow  basis-full sm:basis-auto'} placeholder={'Party'} title={'Party'} />
                        </div>
                        <div className=' w-full flex justify-end pt-2'>
                            <SquareIconButton text={'Search'} onClick={() => { rebuildUi(); setShowFilterModal(false) }} />
                        </div>
                    </div>
                </MenuSimple>
                <TableMenu options={options} />
                

            </div>
            <div className='pt-1 pb-5 grow  overflow-hidden flex flex-col' >
                {isLoading === false
                    ?

                    rows1.length > 0
                        ?
                        <div className=" flex overflow-hidden relative shadow-md sm:rounded-lg">
                            <TableStanderd>
                                <TableStanderd.Head>
                                    {
                                        headers.map(
                                            header => (<th key={header.id} scope="col" className="py-3 px-6">{header.name}</th>)
                                        )
                                    }
                                </TableStanderd.Head>
                                <TableStanderd.Body>
                                    {
                                        rows1.map(
                                            (row, index) => {

                                                // if (index === rows1.length - 1) {
                                                //     return (
                                                //         <TableStanderd.TbRowEnd hovering={false} key={row.id}   >
                                                //             {
                                                //                 headers.map(
                                                //                     (header, index) => (
                                                //                         <TableStanderd.Td key={index} >
                                                //                             {
                                                //                                 header.label === 'commabalance'
                                                //                                     ?
                                                //                                     <div className={`${Number(row.data[partySummeryDef.balance]) > 0
                                                //                                         ?
                                                //                                         'text-lime-500'
                                                //                                         :
                                                //                                         Number(row.data[partySummeryDef.balance]) < 0
                                                //                                             ?
                                                //                                             'text-red-500'
                                                //                                             :
                                                //                                             ''} `}>
                                                //                                         {row.data[header.label]}
                                                //                                     </div>
                                                //                                     :
                                                //                                     row.data[header.label]

                                                //                             }
                                                //                         </TableStanderd.Td>
                                                //                     )
                                                //                 )
                                                //             }

                                                //         </TableStanderd.TbRowEnd>
                                                //     )
                                                // }

                                                return (
                                                    <TableStanderd.TbRow hovering={false} key={row.id}
                                                    // className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 hover:dark:bg-gray-600`}
                                                    >
                                                        {
                                                            headers.map(
                                                                (header, index) => (
                                                                    <TableStanderd.Td key={index} >
                                                                        {
                                                                            header.label === 'commabalance'
                                                                                ?
                                                                                <div className={`${Number(row.data[partySummeryDef.balance]) > 0
                                                                                    ?
                                                                                    'text-lime-500'
                                                                                    :
                                                                                    Number(row.data[partySummeryDef.balance]) < 0
                                                                                        ?
                                                                                        'text-red-500'
                                                                                        :
                                                                                        ''} `}>
                                                                                    {row.data[header.label]}
                                                                                </div>
                                                                                :
                                                                                row.data[header.label]

                                                                        }
                                                                    </TableStanderd.Td>
                                                                )
                                                            )
                                                        }

                                                    </TableStanderd.TbRow>
                                                )
                                            }
                                        )
                                    }
                                    {rowEndValues!==null&&<TableStanderd.TbRowEnd coloured>
                                        {
                                            headers.map(
                                                (header, index) => {
                                                    if (index === headers.length - 1) {

                                                    }
                                                    switch (index) {
                                                        case 0:
                                                            return <TableStanderd.Td key={index} ></TableStanderd.Td >

                                                        case 1:
                                                            return <TableStanderd.Td key={index} ></TableStanderd.Td >
                                                        case 2:
                                                            return <TableStanderd.Td key={index} >{formatNumberWithCommas(rowEndValues.totalReceipts)}</TableStanderd.Td >

                                                        case 3:
                                                            return <TableStanderd.Td key={index} >{formatNumberWithCommas(rowEndValues.totalPayments)}</TableStanderd.Td >

                                                        case 4:
                                                            return <TableStanderd.Td key={index} >{formatNumberWithCommas(rowEndValues.balance)}</TableStanderd.Td >
                                                        case 5:
                                                            return <TableStanderd.Td key={index} ></TableStanderd.Td >
                                                        default:
                                                            break;
                                                    }
                                                    return <TableStanderd.Td key={index} ></TableStanderd.Td >
                                                }
                                            )
                                        }
                                    </TableStanderd.TbRowEnd>}

                                </TableStanderd.Body>
                            </TableStanderd>
                        </div>
                        :
                        <div className=' flex flex-col gap-3 grow dark:text-gray-300 text-3xl font-bold  items-center justify-center theme-smooth'>
                            No Record Found
                        </div>
                    :
                    <div className=' grow flex flex-col gap-2'>


                        {
                            elements.map(
                                (element, index) => (
                                    <div
                                        key={index}
                                        className={`w-full ${index === 0 ? 'h-16' : 'h-11'} bg-gray-600 rounded-md animate-bubblee`}
                                        style={{ animationDelay: `${element.delay}ms` }}
                                    ></div>
                                )
                            )
                        }



                    </div>
                }
            </div>
        </div>
    )
}
