import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { sideNavSizesDef, themeStateDef } from "../../../definitions/definintion";
import { Transition } from "@headlessui/react";
import { IconChevronDown } from "@tabler/icons-react";
import { useState } from "react";

export function SideTabs({ icon, text, href, isSelected }) {

    const theme = useSelector(
        state => state.config.theme
    )
    const sideNav = useSelector(
        state => state.config.sideNav
    )

    return (
        <NavLink to={href} className={({ isActive, isPending }) => {
            return `${isSelected || isActive ? 'bg-primaryy-300/40 group dark:bg-primaryy-dark-800 border-white dark:border-gray-300 font-bold' : 'hover:bg-white/20 font-semibold dark:hover:bg-primaryy-dark-900 hover:border-white/80 dark:hover:dark:border-gray-500'} ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} h-[65px] flex rounded-[50px]  border-2 border-transparent items-center justify-items-start gap-2 w-full  px-4`
        }}
        >
            <div className="  theme-smooth text-gray-700 dark:text-gray-300 min-w-[28px] flex justify-center">{icon}</div>
            <Transition
                className={' block'}
                show={sideNav.size === sideNavSizesDef.expanded ? true : false}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0 hidden"
            >
                <div className=" theme-smooth text-xs font-montserrat   text-gray-700 dark:text-gray-300 ">{text}</div>
            </Transition>
        </NavLink>
    )

}

export function SideTabsStanderd({ icon, text, href, isSelected }) {

    const theme = useSelector(
        state => state.config.theme
    )
    const sideNav = useSelector(
        state => state.config.sideNav
    )

    return (
        <NavLink to={href} className={({ isActive, isPending }) => {
            return `${isSelected || isActive ? 'bg-primaryy-300/40 dark:bg-primaryy-dark-800 text-primaryy-600 border-white dark:border-gray-300 font-bold' : 'text-gray-700 dark:text-gray-300 font-semibold hover:bg-white/20  dark:hover:bg-primaryy-dark-900 hover:border-white/80'} ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''}  py-1 flex rounded-md border-2 border-transparent items-center justify-start gap-2 w-full  px-4`
        }}>
            <div className=" min-w-[29px] flex justify-center ">{icon}</div>
            <Transition
                className={' block'}
                show={sideNav.size === sideNavSizesDef.expanded ? true : false}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0 hidden"
            >
                <div className="  text-xs font-montserrat ">{text}</div>
            </Transition>
        </NavLink>
    )

}


export function SideTabsInner({ icon, text, href, isSelected }) {

    const theme = useSelector(
        state => state.config.theme
    )
    const sideNav = useSelector(
        state => state.config.sideNav
    )

    return (
        <NavLink to={href} className={({ isActive, isPending }) => {
            return `${isSelected || isActive ? 'bg-primaryy-300/40 dark:bg-primaryy-dark-800 text-primaryy-600 border-white dark:border-gray-300 font-bold' : 'text-gray-700 dark:text-gray-300 font-semibold hover:bg-white/20  dark:hover:bg-primaryy-dark-900 hover:border-white/80'} ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''}  py-1 flex  border-y-2 border-s-2 border-transparent items-center  gap-2 w-full  item-center justify-center px-2 ms-1 rounded-s-md `
        }}>
            <div className=" flex justify-center ">{icon}</div>
            <Transition
                className={' block grow'}
                show={sideNav.size === sideNavSizesDef.expanded ? true : false}
                enter="ease-out duration-300 "
                enterFrom="opacity-0 "
                enterTo="opacity-100 "
                leave="ease-in duration-100 "
                leaveFrom="opacity-100 "
                leaveTo="opacity-0 hidden "
            >
                <div className="  text-xs font-montserrat  ">{text}</div>
            </Transition>
        </NavLink>
    )

}

export function SideTabsStanderdNested({ icon, text, href, isSelected, onClick = () => { }, options = [], selectedInnerTab = null }) {


    const [isCollapse, setIsCollapse] = useState(true)
    const theme = useSelector(
        state => state.config.theme
    )
    const sideNav = useSelector(
        state => state.config.sideNav
    )

    const toggleCollapse = () => {
        setIsCollapse(!isCollapse)
    }
    const singleTabHeight = 40;
    const innerheight = options.length > 0 ? options.length * singleTabHeight : singleTabHeight
    let isInnerSelected = options.filter(
        item => selectedInnerTab === item.selectionId
    ).length > 0 ? true : false
    if(options.length===0){
        return (
            <NavLink to={options.length === 0 ? href : null} className={({ isActive, isPending }) => {
                return ` ${isSelected || isInnerSelected ? ' bg-gray-50/80 dark:bg-primaryy-700 border-white text-gray-950 dark:text-gray-200 dark:border-gray-300 font-semibold' : 'text-gray-800 dark:text-gray-200 font-semibold  dark:hover:bg-primaryy-dark-900 hover:border-white/80'} ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : 'transition-all duration-200'}  flex flex-col rounded-md border-2 border-transparent items-center justify-start w-full  ${isCollapse===false?'bg-gray-50/40 dark:bg-primaryy-950 ':''}`
            }}>
            <div onClick={options.length > 0 ? toggleCollapse : () => { }} className={`  flex  w-full items-center justify-center   px-1 py-1 ${isCollapse===false?' border-b-2  border-gray-500/20 dark:border-gray-300/20 ':''} `}>
                <div className=" flex w-full items-center h-full justify-center ">
                    <div className=" min-w-[29px] flex items-center ">{icon}</div>
                    <Transition
                        className={' grow block'}
                        show={sideNav.size === sideNavSizesDef.expanded ? true : false}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0 hidden"
                    >
                        <div className="  text-xs font-montserrat ">{text}</div>
                    </Transition>
                    <IconChevronDown size={18} style={{
                        transform: isCollapse === false ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transition: 'all 200ms',
                        display: options.length > 0 ? 'block' : 'none'
                    }} />
                </div>
            </div>
            </NavLink>
        )
    }
    return (
        <div to={options.length === 0 ? href : null} className={` ${isSelected || isInnerSelected ? ' bg-gray-50/80 dark:bg-primaryy-700 border-white text-gray-950 dark:text-gray-200 dark:border-gray-300 font-semibold' : 'text-gray-800 dark:text-gray-200 font-semibold  dark:hover:bg-primaryy-dark-900 hover:border-white/80'} ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : 'transition-all duration-200'}  flex flex-col rounded-md border-2 border-transparent items-center justify-start w-full cursor-pointer  ${isCollapse===false?'bg-gray-50/40 dark:bg-primaryy-950 ':''}`} >
            <div onClick={options.length > 0 ? toggleCollapse : () => { }} className={`  flex  w-full items-center justify-center   px-1 py-1 ${isCollapse===false?' border-b-2  border-gray-500/20 dark:border-gray-300/20 ':''} `}>
                <div className=" flex w-full items-center h-full justify-center ">
                    <div className=" min-w-[29px] flex items-center ">{icon}</div>
                    <Transition
                        className={' grow block'}
                        show={sideNav.size === sideNavSizesDef.expanded ? true : false}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0 hidden"
                    >
                        <div className="  text-xs font-montserrat ">{text}</div>
                    </Transition>
                    <IconChevronDown size={18} style={{
                        transform: isCollapse === false ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transition: 'all 200ms',
                        display: options.length > 0 ? 'block' : 'none'
                    }} />
                </div>
            </div>
            <div className=" w-full flex flex-col justify-center" style={{
                height: isCollapse ? 0 : innerheight + 6,
                transition: 'all 300ms',
                overflow: 'hidden',
            }}>

                <Transition
                    className={'w-full  '}
                    show={!isCollapse}
                    enter="ease-out duration-300 "
                    enterFrom={`opacity-0 h-0`}
                    enterTo='opacity-100 '
                    leave="ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0 hidden"

                >
                    <Transition.Child>
                        <div className="flex flex-col gap-1">
                            {
                                options.map(
                                    (option, index) => (
                                        <SideTabsInner key={index} isSelected={option.selectionId === selectedInnerTab} icon={option.icon} text={option.name} href={option.href} />
                                    )
                                )
                            }
                        </div>
                    </Transition.Child>
                </Transition>
            </div>
        </div>
    )

}