import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    user:{

    }
}





const userSlice = createSlice(
    {
        name: 'user',
        initialState: initialState,
        reducers: {
            setUser:(state,action)=>{
                state.user = action.payload
            },

        }
    }
)


export const userReducer = userSlice.reducer;
export const { 
    setUser
} = userSlice.actions;