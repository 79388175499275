
import { IconBell, IconBuildingStore, IconChartDots, IconHelpSquareRounded, IconLayoutDashboard, IconSettings, IconUsersGroup } from "@tabler/icons-react"
import { UserProfile } from "../../../components/userProfile"
import { SideTabsStanderd, SideTabsStanderdNested } from "../../../components/sideTabs"
import { SideNavBar as SideNavBarLayout } from "../../../../../components/layouts"
import { CompanyProfileSideTab } from "../../../components/companyProfile.jsx";
import { useEffect } from "react";

export function SideNavBar({ generalTabs, supportTabs, selectedTab, selectedInnerTab }) {

    // useEffect(
    //     ()=>{
    //         // console.log('side bar first')
    //     },[]
    // )


    return (
        <SideNavBarLayout>
            <CompanyProfileSideTab />
            <div className=" w-full my-3">
                <p className="theme-smooth font-bold text-xs text-gray-500 dark:text-gray-400 " >General</p>
            </div>
            <div className=" flex flex-col w-full gap-2 mb-8">
                
                {
                    generalTabs.map(
                        (tab, index) => <SideTabsStanderdNested key={`sideBarNestedManagement${index}`}  isSelected={index === selectedTab} icon={tab.icon} text={tab.name} href={tab.href} options={tab.children} selectedInnerTab={selectedInnerTab} />
                    )
                }

            </div>
            <div className=" w-full my-3">
                <p className="theme-smooth font-bold text-xs text-gray-500 dark:text-gray-400" >Support</p>
            </div>
            <div className=" flex flex-col w-full gap-2">
                {
                    supportTabs.map(
                        (tab, index) => <SideTabsStanderd key={`sideBarStdManagment${index}`} icon={tab.icon} text={tab.name} href={tab.href} />
                    )
                }
            </div>
            <div className=" grow"></div>
            <div className=" w-full mb-3">
                <UserProfile />
            </div>
        </SideNavBarLayout>
        // <div className=" theme-smooth relative justify-center items-center min-w-[220px] max-w-[220px] self-stretch  bg-gray-100 dark:bg-primaryy-dark-950 flex flex-col px-3 border-r border-solid border-r-gray-200 dark:border-r-gray-700 max-md:px-5">


        // </div>
    )

}