import { format } from 'indian-number-format'

export function formatNumberWithCommas(number) {
    try {
        // Convert the input to a number for robust handling
        number = parseFloat(number);
        return format(number); // Use 'en-US' for commas as separators
    } catch (error) {
        console.error("Invalid input:", error);
        return "Invalid number";
    }
}

export const formattedDate = (dateString) => {
    if (dateString) {
        const dateObj = new Date(dateString); // Create a Date object

        const day = dateObj.getDate().toString().padStart(2,'0'); // Get day with leading zero
        const month = dateObj.toLocaleString('en-US', { month: 'short' }); // Get month name (short)
        const year = dateObj.getFullYear();

        return `${day}-${month}-${year}`;
    }
    return ''
};