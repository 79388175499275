import { Navigate, Route, Routes } from "react-router-dom";
import { BodyWrapperSub } from "../../../../../components/layouts";
import { useEffect } from "react";

export function AccMaster({routes,onChangeTab=()=>{}}) {


    useEffect(
        ()=>{
            console.log('outer first')
        },[]
    )


    return (
        <>
            {/* <BodyWrapperSub>
                <SettingsBarScrlParent name={'Master'} />
                <div className="theme-smooth text-gray-800 dark:text-gray-400 mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry</div>
            </BodyWrapperSub> */}
            {/* <Tab selectedTab={selectedTab} tabs={AccountSubTabs} className={``} /> */}
            <BodyWrapperSub className={'flex flex-col  overflow-auto grow'}>
                <Routes>
                    <Route index element={<Navigate to={routes[0].path} replace />} />
                    {
                        routes.map(
                            (route,index)=><Route key={`accountMaster${index}`} path={route.path} Component={()=>{onChangeTab(route.selectionId); return (route.component)}} />
                        )
                    }
                </Routes>
            </BodyWrapperSub>
        </>
    )
}