import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { useEffect, useState } from "react";
import { IconOnlyButton, SquareButton, SquareIconButton } from "../../../../../../../components/button";
import { InputStd2, SwitchInputStd2 } from "../../../../../../../components/inputs";
import { ModalLoading, ModalStanderd, QuestionModal } from "../../../../../../../components/modal";
import { showErrorToast, showToast } from "../../../../../../../components/toast";
import { useRESTapi } from '../../../../../../../controller/restAPI';
import { employeeDef, squareButtonVarient } from "../../../../../../../definitions/definintion";







export function ModalAddBranch({ show, closeModal, updateUi, pushData }) {


    const [errdatas, setErrDatas] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const [employees, setEmployees] = useState([]);

    // const [data, setData] = useState(
    //     {
    //         "FM08_EmployeeName": "naseema",
    //         "FM08_EmployeeCode": "EMP1",
    //         // "FM08_M08_ReportingTo": 12,
    //         // "FM08_M01_CountryID": "12",
    //         // "FM08_M05_BranchId": "1",
    //         // "FM08_Designation": "developer",
    //         // "FM08_DOB": "2000-02-12",
    //         // "FM08_MaritalStatus": "Married",
    //         // "FM08_Gender": "Female",
    //         // "FM08_Qualification": "BTECH",
    //         "FM08_MobileNumber": "8998989899",
    //         "FM08_Email": "naseema@gmail.com",
    //         // "FM08_LoginUserName": "",
    //         "FM08_Password": "",
    //         // "FM08_JoiningDate": "2022-02-14",
    //         // "FM08_TerminationDate": "2024-02-29",
    //         // "FM08_BloodGroup": "O+",
    //         "FM08_AllowLogin": 1,
    //         "FM08_IsActive": 1,
    //         // "FM08_CreatedBy": 13,
    //         // "FM08_CreatedDate": "",
    //         // "FM08_Address": "kottakkal city"
    //     }
    // )
    const [data, setData] = useState({
        [employeeDef.EmployeeName]: '',
        [employeeDef.EmployeeCode]: '',
        // [employeeDef.ReportingTo]: '',
        // [employeeDef.CountryID]: '',
        // [employeeDef.BranchId]: '',
        // [employeeDef.Designation]: '',
        // [employeeDef.DOB]: '',
        // [employeeDef.MaritalStatus]: '',
        // [employeeDef.Gender]: '',
        // [employeeDef.Qualification]: '',
        [employeeDef.MobileNumber]: '',
        [employeeDef.email]: '',
        [employeeDef.LoginUserName]: '',
        // [employeeDef.JoiningDate]: '',
        // [employeeDef.TerminationDate]: '',
        // [employeeDef.BloodGroup]: '',
        [employeeDef.AllowLogin]: 1,
        [employeeDef.IsActive]: 1,
        // [employeeDef.CreatedBy]: '',
        // [employeeDef.CreatedDate]: '',
    })

    const api = useRESTapi();




    const validationObj = {
        [employeeDef.EmployeeName]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [employeeDef.EmployeeCode]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },

        [employeeDef.MobileNumber]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
                (value) => {
                    if (/^\d{10}$/.test(value)) {
                        return true
                    }
                    return 'Enter valid number'
                }
            ]
        },
        [employeeDef.email]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
                (value) => {
                    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                        return true
                    }
                    return 'Not valid email'
                }
            ]
        },
        [employeeDef.LoginUserName]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },

        [employeeDef.AllowLogin]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },


    }

    const performValidation = (obj = {}) => {

        let errObj = {}
        let errNotOccured = true;

        for (const key in validationObj) {

            let isPass = true;

            for (let i = 0; i < validationObj[key].validaton.length; i++) {
                isPass = validationObj[key].validaton[i](obj[key])
                if (isPass !== true) {
                    errNotOccured = false
                    errObj[key] = isPass;
                    break;
                }
            }



        }
        // console.log(err)

        setErrDatas(errObj)
        return errNotOccured;

    }

    const onChangeInput = (event) => {

        let valueObj
        valueObj = {
            ...data,
            [event.target.name]: event.target.value
        }

        setData(valueObj)


    }

    const clearData = () => {
        setData({
            [employeeDef.EmployeeName]: '',
            [employeeDef.EmployeeCode]: '',
            // [employeeDef.ReportingTo]: '',
            // [employeeDef.CountryID]: '',
            // [employeeDef.BranchId]: '',
            // [employeeDef.Designation]: '',
            // [employeeDef.DOB]: '',
            // [employeeDef.MaritalStatus]: '',
            // [employeeDef.Gender]: '',
            // [employeeDef.Qualification]: '',
            [employeeDef.MobileNumber]: '',
            [employeeDef.email]: '',
            [employeeDef.LoginUserName]: '',

            // [employeeDef.JoiningDate]: '',
            // [employeeDef.TerminationDate]: '',
            // [employeeDef.BloodGroup]: '',
            [employeeDef.AllowLogin]: 1,
            // [employeeDef.IsActive]: '',
            // [employeeDef.CreatedBy]: '',
            // [employeeDef.CreatedDate]: '',
        })
        setErrDatas({})
    }






    const addRole = async () => {
        setIsLoading(true)
        // data.employeesSelected = filterIds();
        // data[employeeDef.CreatedDate] = formatDate(new Date())
        data[employeeDef.LoginUserName] = data[employeeDef.email]
        if (!data[employeeDef.AllowLogin]) {
            data[employeeDef.AllowLogin] = 0;
        }
        try {

            if (performValidation(data)) {
                const headers = {
                    Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE)}`,
                };
                const res = await api.post({
                    url: `${process.env.REACT_APP_BACKEND_URL}/api/employees`,
                    body: data
                });

                if (res.data.success === true) {
                    showToast({
                        message: res.data.message || 'Employee added'
                    })
                    clearData()
                    closeModal();
                    updateUi();
                } else {
                    if (res && res.data) {
                        const res1 = res.data;
                        if (res1.error && typeof res1.error === "object" && !Array.isArray(res1.error)) {
                            const error = {};
                            for (let key in res1.error) {
                                error[key] = res1.error[key][0]
                            }
                            console.log(error)
                            setErrDatas(error)
                        }
                    }
                    showErrorToast({
                        message: res?.data?.message || 'Error adding employee',
                        timeOut: 5000
                    })
                }



            } else {
                showErrorToast({
                    message: 'Please fill the form',
                    timeOut: 2000
                })
            }

        } catch (err) {
            console.log(err)

            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR',
                timeOut: 5000
            })
        }
        setIsLoading(false)
    }






    return (
        <>
            <ModalLoading show={isLoading} zIndex={100} />

            <ModalStanderd
                show={show}
                title="Add Employee"
                closeModal={closeModal}
                closeClickOutside={false}
            >
                <ModalStanderd.Body>
                    <div className="sm:flex sm:items-start w-full">

                        <div className="mt-3 text-center  sm:mt-0 sm:text-left w-full pb-1">

                            <div className=" w-full flex flex-wrap  gap-2 py-3 ">
                                <InputStd2 errMsg={errdatas[employeeDef.EmployeeCode]} className={' grow basis-full xm:basis-auto'} placeholder={'code'} title={'Employee code'} value={data[employeeDef.EmployeeCode]} name={employeeDef.EmployeeCode} onChange={onChangeInput} />
                                <InputStd2 errMsg={errdatas[employeeDef.EmployeeName]} className={' grow basis-full xm:basis-auto'} placeholder={'name'} title={'Employee name'} value={data[employeeDef.EmployeeName]} name={employeeDef.EmployeeName} onChange={onChangeInput} />
                                {/* <div className=" basis-full"> </div>
                                <InputStd2 errMsg={errdatas[employeeDef.Designation]} className={'w-auto grow '} placeholder={'Designation'} title={'Designation'} value={data[employeeDef.Designation]} name={employeeDef.Designation} onChange={onChangeInput} />
                                <div className=" basis-full"> </div> */}
                                {/* <InputStd2 type="number" className={'w-auto grow'} placeholder={'Branch Id'} title={'Branch Id'} errMsg={errdatas[employeeDef.BranchId]} value={data[employeeDef.BranchId]} name={employeeDef.BranchId} onChange={onChangeInput} />
                                <SelectBoxInputBox option={employees} className={'grow'} placeholder={'name'} title={'Reporting to'} errMsg={errdatas[employeeDef.ReportingTo]} value={data[employeeDef.ReportingTo]} name={employeeDef.ReportingTo} onChange={onChangeInput} />
                                <div className=" basis-full"> </div> */}

                                {/* <InputStd2 className={'basis-full sm:basis-auto sm:grow'} placeholder={'Dob'} title={'Dob'} type="date" errMsg={errdatas[employeeDef.DOB]} value={data[employeeDef.DOB]} name={employeeDef.DOB} onChange={onChangeInput} /> */}
                                {/* <SelectInputStd2 className={'grow'} title={'Marital Status'} items={maritalStatusItems} errMsg={errdatas[employeeDef.MaritalStatus]} value={data[employeeDef.MaritalStatus]} name={employeeDef.MaritalStatus} onChange={onChangeInput} /> */}
                                {/* <SelectInputStd2 className={'grow'} items={genderItems} title={'Gender'} errMsg={errdatas[employeeDef.Gender]} value={data[employeeDef.Gender]} name={employeeDef.Gender} onChange={onChangeInput} /> */}
                                <div className=" basis-full"> </div>
                                <InputStd2 className={'grow basis-full xm:basis-auto'} placeholder={'Email'} title={'Email'} errMsg={errdatas[employeeDef.email]} value={data[employeeDef.email]} name={employeeDef.email} onChange={onChangeInput} />
                                <InputStd2 className={'grow basis-full xm:basis-auto'} disabled placeholder={'Username'} title={'Username'} errMsg={errdatas[employeeDef.email]} value={data[employeeDef.email]} name={employeeDef.email} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                {/* <InputTextAreaStd2 className={'basis-full'} rows={3} placeholder={'Address'} title={'Address'} errMsg={errdatas[employeeDef.Address]} value={data[employeeDef.Address]} name={employeeDef.Address} onChange={onChangeInput} /> */}
                                <InputStd2 className={' basis-full sm:basis-[49.5%]'} placeholder={'Phone No'} title={'Phone No'} type="number" errMsg={errdatas[employeeDef.MobileNumber]} value={data[employeeDef.MobileNumber]} name={employeeDef.MobileNumber} onChange={onChangeInput} />

                                {/* <InputStd2 className={'grow'} placeholder={'Blood Group'} title={'Blood Group'} errMsg={errdatas[employeeDef.BloodGroup]} value={data[employeeDef.BloodGroup]} name={employeeDef.BloodGroup} onChange={onChangeInput} /> */}
                                {/* <div className=" basis-full"> </div> */}

                                {/* <InputStd2 className={'grow'} placeholder={'password'} title={'Password'} errMsg={errdatas[employeeDef[15].fieldName]} value={data[employeeDef[15].fieldName]} name={employeeDef[15].fieldName} onChange={onChangeInput} /> */}
                                {/* <InputStd2 className={'grow'} placeholder={'country id'} title={'Country ID'} errMsg={errdatas[employeeDef.CountryID]} value={data[employeeDef.CountryID]} name={employeeDef.CountryID} onChange={onChangeInput} /> */}
                                {/* <InputStd2 className={'basis-full sm:basis-auto sm:grow'} placeholder={'joining date'} title={'Joining Date'} type="date" errMsg={errdatas[employeeDef.JoiningDate]} value={data[employeeDef.JoiningDate]} name={employeeDef.JoiningDate} onChange={onChangeInput} /> */}
                                {/* <InputStd2 className={'basis-full sm:basis-auto sm:grow'} placeholder={'Termination Date'} title={'Termination Date'} type="date" errMsg={errdatas[employeeDef.TerminationDate]} value={data[employeeDef.TerminationDate]} name={employeeDef.TerminationDate} onChange={onChangeInput} /> */}
                                {/* <InputStd2 className={'basis-full sm:basis-auto sm:grow'} placeholder={'created by'} title={'Created By'} errMsg={errdatas[employeeDef.CreatedBy]} value={data[employeeDef.CreatedBy]} name={employeeDef.CreatedBy} onChange={onChangeInput} /> */}

                                <div className=" flex grow justify-end pt-4  ">
                                    <SwitchInputStd2 states={{ enabled: 1, disabled: 0 }} title={'Allow Login'} value={data[employeeDef.AllowLogin]} name={employeeDef.AllowLogin} onChange={onChangeInput} />
                                </div>
                            </div>
                            {/* <InputStd2 placeholder={'Password'} title={'Password'} /> */}
                        </div>
                    </div>
                </ModalStanderd.Body>
                <ModalStanderd.Bottom>
                    <SquareIconButton icon={<IconPlus />} text={'Add'} onClick={addRole} />
                    <SquareButton text={'Cancel'} varient={squareButtonVarient.outlined} onClick={closeModal} />
                </ModalStanderd.Bottom>
            </ModalStanderd>
        </>
    )

}
export function ModalBranchDetails({ show, closeModal, updateUi, fiscalFull, id }) {

    const [errdatas, setErrDatas] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [allowEdit, setallowEdit] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const api = useRESTapi();

    const [data, setData] = useState({
        [employeeDef.EmployeeName]: '',
        [employeeDef.EmployeeCode]: '',
        // [employeeDef.ReportingTo]: '',
        // [employeeDef.CountryID]: '',
        // [employeeDef.BranchId]: '',
        // [employeeDef.Designation]: '',
        // [employeeDef.DOB]: '',
        // [employeeDef.MaritalStatus]: '',
        // [employeeDef.Gender]: '',
        // [employeeDef.Qualification]: '',
        [employeeDef.MobileNumber]: '',
        [employeeDef.email]: '',
        [employeeDef.LoginUserName]: '',
        // [employeeDef.JoiningDate]: '',
        // [employeeDef.TerminationDate]: '',
        // [employeeDef.BloodGroup]: '',
        [employeeDef.AllowLogin]: 1,
        // [employeeDef.IsActive]: '',
        // [employeeDef.CreatedBy]: '',
        // [employeeDef.CreatedDate]: '',
    })

    const [initialData, setInitialData] = useState({})





    const validationObj = {
        [employeeDef.EmployeeName]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },
        [employeeDef.EmployeeCode]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },

        [employeeDef.MobileNumber]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
                (value) => {
                    if (/^\d{10}$/.test(value)) {
                        return true
                    }
                    return 'Enter valid number'
                }
            ]
        },
        [employeeDef.email]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
                (value) => {
                    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                        return true
                    }
                    return 'Not valid email'
                }
            ]
        },
        [employeeDef.LoginUserName]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value && value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },


        [employeeDef.AllowLogin]: {
            message: 'required',
            validaton: [
                (value) => {
                    if (value !== '') {
                        return true
                    }
                    return 'required'
                },
            ]
        },


    }


    const performValidation = (obj = {}) => {

        let errObj = {}
        let errNotOccured = true;

        for (const key in validationObj) {

            let isPass = true;

            for (let i = 0; i < validationObj[key].validaton.length; i++) {
                isPass = validationObj[key].validaton[i](obj[key])
                if (isPass !== true) {
                    errNotOccured = false
                    errObj[key] = isPass;
                    break;
                }
            }



        }

        setErrDatas(errObj)
        return errNotOccured;

    }



    const onChangeInput = (event) => {

        let valueObj
        valueObj = {
            ...data,
            [event.target.name]: event.target.value
        }


        setData(valueObj)


    }

    async function deleteEmployee() {
        setIsLoading(true)
        try {
            const res = await api.Delete({
                url: `${process.env.REACT_APP_BACKEND_URL}/api/employees/${id}`
            });
            if (res?.data?.success === true) {
                closeTheModal();
                updateUi();
                showToast({
                    message: res.data?.message || 'Employee Deleted'
                })
            } else {
                showErrorToast({
                    message: res?.data?.message || 'Error Deleting party'
                })
            }

        } catch (error) {
            console.log(error)
            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR'
            })
        }
        setShowDeleteModal(false)
        setIsLoading(false)
    }


    const closeTheModal = () => {
        setShowDeleteModal(false)
        setallowEdit(false)
        clearData()
        closeModal()
    }


    const disAllowEdit = () => {
        setallowEdit(false);
        setErrDatas({})
        setData(initialData)
    }




    const clearData = () => {
        setData({
            [employeeDef.EmployeeName]: '',
            [employeeDef.EmployeeCode]: '',
            // [employeeDef.ReportingTo]: '',
            // [employeeDef.CountryID]: '',
            // [employeeDef.BranchId]: '',
            // [employeeDef.Designation]: '',
            // [employeeDef.DOB]: '',
            // [employeeDef.MaritalStatus]: '',
            // [employeeDef.Gender]: '',
            // [employeeDef.Qualification]: '',
            [employeeDef.MobileNumber]: '',
            [employeeDef.email]: '',
            [employeeDef.LoginUserName]: '',

            // [employeeDef.JoiningDate]: '',
            // [employeeDef.TerminationDate]: '',
            // [employeeDef.BloodGroup]: '',
            [employeeDef.AllowLogin]: 1,
            // [employeeDef.IsActive]: '',
            // [employeeDef.CreatedBy]: '',
            // [employeeDef.CreatedDate]: '',
        })
        setErrDatas({})
    }

    // const closeAndClearModal

    const addRole = async () => {
        setIsLoading(true)
        data[employeeDef.LoginUserName] = data[employeeDef.email]
        if (!data[employeeDef.AllowLogin]) {
            data[employeeDef.AllowLogin] = 0;
        }
        try {

            if (performValidation(data)) {

                const res = await api.put({
                    url: `${process.env.REACT_APP_BACKEND_URL}/api/employees/${id}`,
                    body: data
                })

                if (res?.data?.success === true) {
                    showToast({
                        message: res.data.message || 'Party Edited'
                    })
                    clearData()
                    closeModal();
                    updateUi();
                } else {
                    if (res && res.data) {
                        const res1 = res.data;
                        if (res1.error && typeof res1.error === "object" && !Array.isArray(res1.error)) {
                            const error = {};
                            for (let key in res1.error) {
                                error[key] = res1.error[key][0]
                            }
                            console.log(error)
                            setErrDatas(error)
                        }
                    }
                    showErrorToast({
                        message: res?.data?.message || 'Error adding party',
                        timeOut: 5000
                    })
                }

            } else {
                showErrorToast({
                    message: 'Please fill the form',
                    timeOut: 2000
                })
            }

        } catch (err) {
            console.log(err)

            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR',
            })
        }
        setIsLoading(false)
    }




    useEffect(
        () => {
            const loadEmployee = async () => {

                setIsLoading(true)
                try {
                    const res = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/employees/${id}`
                    });
                    if (res?.data?.success === true) {
                        const employee = res.data.data;
                        console.log(employee)
                        for (const key in employee) {
                            employee[key] = employee[key] === null || employee[key] === undefined ? '' : employee[key]
                            // employee[key] = undefined
                        }
                        setData(employee)
                        setInitialData(employee)
                    } else {
                        showErrorToast({
                            message: res.data?.message || 'Error loading branch'
                        })
                        closeModal()
                    }

                } catch (error) {
                    console.log(error)
                    showErrorToast({
                        message: 'Internal Error'
                    })
                    closeModal()

                }

                setIsLoading(false)

            }
            if (show) {
                loadEmployee();
            }
        }, [show]
    )



    return (
        <>
            <ModalLoading show={isLoading} zIndex={1000} />
            <QuestionModal show={showDeleteModal} Question="Do you want to delete employee?" onNegative={() => setShowDeleteModal(false)} onPositive={deleteEmployee} />

            <ModalStanderd title="Employee Details" show={show} closeModal={closeModal} afterLeave={closeTheModal} >
                <ModalStanderd.Body>
                    <div className=" flex justify-end gap-1 px-1 pt-1">
                        <IconOnlyButton onClick={() => setallowEdit(true)} icon={<IconEdit />} />
                        <IconOnlyButton onClick={() => setShowDeleteModal(true)} icon={<IconTrash />} />
                    </div>
                    <div className="sm:flex sm:items-start w-full">

                        <div className="mt-3 text-center  sm:mt-0 sm:text-left w-full pb-1">

                            <div className=" w-full flex flex-wrap gap-2 ">
                                <InputStd2 disabled={!allowEdit} errMsg={errdatas[employeeDef.EmployeeCode]} className={' grow basis-full xm:basis-auto'} placeholder={'code'} title={'Employee code'} value={data[employeeDef.EmployeeCode]} name={employeeDef.EmployeeCode} onChange={onChangeInput} />
                                <InputStd2 disabled={!allowEdit} errMsg={errdatas[employeeDef.EmployeeName]} className={' grow basis-full xm:basis-auto'} placeholder={'name'} title={'Employee name'} value={data[employeeDef.EmployeeName]} name={employeeDef.EmployeeName} onChange={onChangeInput} />
                                {/* <div className=" basis-full"> </div>
                                <InputStd2 errMsg={errdatas[employeeDef.Designation]} className={'w-auto grow '} placeholder={'Designation'} title={'Designation'} value={data[employeeDef.Designation]} name={employeeDef.Designation} onChange={onChangeInput} />
                                <div className=" basis-full"> </div> */}
                                {/* <InputStd2 type="number" className={'w-auto grow'} placeholder={'Branch Id'} title={'Branch Id'} errMsg={errdatas[employeeDef.BranchId]} value={data[employeeDef.BranchId]} name={employeeDef.BranchId} onChange={onChangeInput} />
                                <SelectBoxInputBox option={employees} className={'grow'} placeholder={'name'} title={'Reporting to'} errMsg={errdatas[employeeDef.ReportingTo]} value={data[employeeDef.ReportingTo]} name={employeeDef.ReportingTo} onChange={onChangeInput} />
                                <div className=" basis-full"> </div> */}

                                {/* <InputStd2 className={'basis-full sm:basis-auto sm:grow'} placeholder={'Dob'} title={'Dob'} type="date" errMsg={errdatas[employeeDef.DOB]} value={data[employeeDef.DOB]} name={employeeDef.DOB} onChange={onChangeInput} /> */}
                                {/* <SelectInputStd2 className={'grow'} title={'Marital Status'} items={maritalStatusItems} errMsg={errdatas[employeeDef.MaritalStatus]} value={data[employeeDef.MaritalStatus]} name={employeeDef.MaritalStatus} onChange={onChangeInput} /> */}
                                {/* <SelectInputStd2 className={'grow'} items={genderItems} title={'Gender'} errMsg={errdatas[employeeDef.Gender]} value={data[employeeDef.Gender]} name={employeeDef.Gender} onChange={onChangeInput} /> */}
                                <div className=" basis-full"> </div>
                                <InputStd2 disabled={!allowEdit} className={'grow basis-full xm:basis-auto'} placeholder={'Email'} title={'Email'} errMsg={errdatas[employeeDef.email]} value={data[employeeDef.email]} name={employeeDef.email} onChange={onChangeInput} />
                                <InputStd2 className={'grow basis-full xm:basis-auto'} disabled placeholder={'Username'} title={'Username'} errMsg={errdatas[employeeDef.email]} value={data[employeeDef.email]} name={employeeDef.email} onChange={onChangeInput} />
                                <div className=" basis-full"> </div>
                                {/* <InputTextAreaStd2 className={'basis-full'} rows={3} placeholder={'Address'} title={'Address'} errMsg={errdatas[employeeDef.Address]} value={data[employeeDef.Address]} name={employeeDef.Address} onChange={onChangeInput} /> */}
                                <InputStd2 disabled={!allowEdit} className={'  sm:basis-[49.6%]'} placeholder={'Phone No'} title={'Phone No'} type="number" errMsg={errdatas[employeeDef.MobileNumber]} value={data[employeeDef.MobileNumber]} name={employeeDef.MobileNumber} onChange={onChangeInput} />

                                {/* <InputStd2 className={'grow'} placeholder={'Qualification'} title={'Qualification'} errMsg={errdatas[employeeDef.Qualification]} value={data[employeeDef.Qualification]} name={employeeDef.Qualification} onChange={onChangeInput} /> */}
                                {/* <InputStd2 className={'grow'} placeholder={'Blood Group'} title={'Blood Group'} errMsg={errdatas[employeeDef.BloodGroup]} value={data[employeeDef.BloodGroup]} name={employeeDef.BloodGroup} onChange={onChangeInput} /> */}
                                {/* <div className=" basis-full"> </div> */}

                                {/* <InputStd2 className={'grow'} placeholder={'password'} title={'Password'} errMsg={errdatas[employeeDef[15].fieldName]} value={data[employeeDef[15].fieldName]} name={employeeDef[15].fieldName} onChange={onChangeInput} /> */}
                                {/* <InputStd2 className={'grow'} placeholder={'country id'} title={'Country ID'} errMsg={errdatas[employeeDef.CountryID]} value={data[employeeDef.CountryID]} name={employeeDef.CountryID} onChange={onChangeInput} /> */}
                                {/* <InputStd2 className={'basis-full sm:basis-auto sm:grow'} placeholder={'joining date'} title={'Joining Date'} type="date" errMsg={errdatas[employeeDef.JoiningDate]} value={data[employeeDef.JoiningDate]} name={employeeDef.JoiningDate} onChange={onChangeInput} /> */}
                                {/* <InputStd2 className={'basis-full sm:basis-auto sm:grow'} placeholder={'Termination Date'} title={'Termination Date'} type="date" errMsg={errdatas[employeeDef.TerminationDate]} value={data[employeeDef.TerminationDate]} name={employeeDef.TerminationDate} onChange={onChangeInput} /> */}
                                {/* <InputStd2 className={'basis-full sm:basis-auto sm:grow'} placeholder={'created by'} title={'Created By'} errMsg={errdatas[employeeDef.CreatedBy]} value={data[employeeDef.CreatedBy]} name={employeeDef.CreatedBy} onChange={onChangeInput} /> */}
                                <div className=" grow flex justify-end pt-2">
                                    <SwitchInputStd2 disabled={!allowEdit} states={{ enabled: 1, disabled: 0 }} title={'Allow Login'} value={data[employeeDef.AllowLogin]} name={employeeDef.AllowLogin} onChange={onChangeInput} />
                                </div>

                            </div>
                            {/* <InputStd2 placeholder={'Password'} title={'Password'} /> */}
                        </div>
                    </div>
                </ModalStanderd.Body>
                <ModalStanderd.Bottom>
                    {
                        allowEdit ?
                            <><SquareButton className={'w-min'} text={'Edit'} onClick={addRole} />
                                <SquareButton text={'Cancel'} varient={squareButtonVarient.outlined} onClick={disAllowEdit} /></>
                            :
                            <SquareButton className={' w-[90px]'} text={'Ok'} onClick={closeModal} />}
                </ModalStanderd.Bottom>
            </ModalStanderd>
        </>
    )

}

