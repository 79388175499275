import axios from "axios"
import { useNavigate } from "react-router-dom";

export function useAuthenticaton() {

    const navigate = useNavigate()

    function logout() {
        navigate('/')
        const headers = {
            Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE)}`,
        };
        try {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/logout`, { headers }).catch(err => console.log(err))
        } catch (error) {
            console.log('error logout')
        }
        localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE)
    }

    return ({
        logout
    })

}