import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Home } from './home.page'
import { Managment } from './managements/management.page'
import { ProtectedRoute } from '../../components/layouts'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setUser } from '../../redux/user/userSlice'

export default function HomeRoute() {

  const dispatch = useDispatch()
  const navigate = useNavigate();


  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE)}`,
    };
    const initData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user`, { headers })

        if (res.data.success === true) {
          dispatch(setUser(res.data.user))
        }else{
          navigate('/')
        }


      } catch (error) {
        navigate('/')
        console.log('error geting user')
      }
    }

    initData()
  }, [])
  return (
    <Routes>
      <Route key={1} path="/home/*" element={<Home />} />
      <Route path='/home/managements/*' element={
        // <ProtectedRoute>
        <Managment />
        // </ProtectedRoute>
      } />
    </Routes>
  )
}
