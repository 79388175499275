export function getMonthRange() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    // Get the first day of the month
    const firstDay = new Date(year, month, 1);

    // Get the last day of the month (tricky!)
    const lastDay = new Date(year, month + 1, 0);

    const formatDate = (date) => {
        const year = date.getFullYear().toString().padStart(4, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return {
        startDate: formatDate(firstDay),
        endDate: formatDate(lastDay)
    };
}