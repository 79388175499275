import { IconUpload } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SquareIconButton } from '../../../../../../components/button';
import { OkModal } from '../../../../../../components/modal';
import { ProgressBar } from '../../../../../../components/progressBars';
import { showErrorToast } from '../../../../../../components/toast';
import { useRESTapi } from '../../../../../../controller/restAPI';
import { setTopNavText } from '../../../../../../redux/config/configSlice';
import HelperButton, { DownloadFile, DownloadFile1 } from './components/helpButton';

export const TransactionUpload = () => {
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const api = useRESTapi()
    const dispatch = useDispatch();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStates, setUploadStates] = useState({
        uploading: false,
        error: false,
        uploadedMessage: null
    })
    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const droppedFile = e.dataTransfer.files[0];
        // console.log(droppedFile)
        if (droppedFile &&
            (droppedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                droppedFile.name.endsWith('.csv'))) {
            setFile(droppedFile);
        } else {
            // Handle invalid file type
            setFile(null);
            showErrorToast({
                message: 'Please select a CSV or XLSX file'
            });
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        console.log('selected file is', selectedFile)
        if (selectedFile &&
            (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                selectedFile.name.endsWith('.csv'))) {
            setFile(selectedFile);
        } else if (selectedFile) {
            // Handle invalid file type
            setFile(null);
            showErrorToast({
                message: 'Please select a CSV or XLSX file'
            });
        }
    };

    const handleUpload = async () => {
        if (!file) {
            showErrorToast({
                message: 'Please select a file to upload',
                timeOut: 3000
            })
            return;
        }

        uploadStates.uploading = true;
        setUploadStates({ ...uploadStates })

        try {
            const formData = new FormData();
            formData.append('excel_file', file);

            const res = await api.post({
                url: `${process.env.REACT_APP_BACKEND_URL}/api/importexcel`,
                body: formData,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                },
            })

            setUploadProgress(0)

            if (res?.data?.success === true) {
                uploadStates.uploadedMessage = res.data.message
                // showToast({
                    //     message: res.data.message
                    // })
                } else {
                uploadStates.error = true
                showErrorToast({
                    message: res?.data?.message || 'Something went wrong'
                })
            }
            
            
        } catch (error) {
            console.log(error)
            uploadStates.error = true
            showErrorToast({
                message: 'Internal Error !!!',
                showAtTimeOne: true,
                id: 'INTERNAL ERROR',
            })
        }
        setFile(null)
        fileInputRef.current.value = null;
        
        uploadStates.uploading = false;
        setUploadStates({ ...uploadStates })
        
    }

    useEffect(() => {
        dispatch(setTopNavText('Transaction Upload'));
        return () => {
            dispatch(setTopNavText(''));
        };
    }, []);

    return (
        <div className=" grow flex flex-col justify-center items-center gap-5 ">
            
            {<OkModal content={uploadStates.uploadedMessage} onPositive={() => { uploadStates.uploadedMessage = null; setUploadStates({ ...uploadStates }) }} show={uploadStates.uploadedMessage ? true : false} />}
            <div
                className={`border-2 duration-100 rounded-lg p-4 py-11 ${isDragOver ? 'border-primaryy-500 border-4 border-solid scale-125' : 'border-primaryy-400 border-dashed'}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <input
                    ref={fileInputRef}
                    type="file"
                    accept=".csv, .xlsx"
                    onChange={handleFileChange}
                    className="hidden"
                    id="file-input"
                />
                <label htmlFor="file-input" className="flex flex-col items-center justify-center h-full cursor-pointer">
                    {/* SVG icon */}
                    <svg

                        className="w-12 h-12 mb-3 text-gray-400"

                        stroke="currentColor"

                        fill="none"

                        viewBox="0 0 48 48"

                        aria-hidden="true"

                    >

                        <path

                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"

                            strokeWidth={2}

                            strokeLinecap="round"

                            strokeLinejoin="round"

                        />

                    </svg>

                    {
                        file ?
                            <div className=' text-black dark:text-gray-300 text-sm font-bold max-w-[260px] min-w-[260px] text-center theme-smooth'>{file.name}</div>
                            : <>
                                <div className="flex text-sm text-gray-600">
                                    <p className="mr-1">Drag and drop files here</p>
                                    <p className="ml-1 underline">or click to select files</p>
                                </div>
                                <p className="mt-1 text-xs text-gray-500">No file selected</p>
                            </>
                    }
                </label>
            </div>
            {
                uploadStates.uploading === true
                    ?
                    <div className=' max-w-60  w-full'>
                        <ProgressBar progressPercentage={uploadProgress} />
                    </div>
                    :
                    <>
                    <DownloadFile1/>
                    <SquareIconButton icon={<IconUpload />} text={'Upload'} onClick={handleUpload} />
                    </>
            }

        </div>
    );
};

