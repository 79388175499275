import { IconArrowsDiff, IconBell, IconBrandTidal, IconBuildingPavilion, IconBuildingStore, IconChartDots, IconChevronsLeft, IconChevronsRight, IconFileUpload, IconHelpSquareRounded, IconHome, IconReport, IconScale, IconSettings, IconUsersGroup } from "@tabler/icons-react";
import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { BodyWrapperMain, BodyWrapperSub } from "../../../../components/layouts";
import { MainTopNav } from "../../../../components/topNavs";
import { ReactComponent as LedgerIcon } from '../../../../icons/smallIcons/ledger.svg';
import { ReactComponent as ProfitLossIcon } from '../../../../icons/smallIcons/profitAndLoss.svg';
import { ReactComponent as TransactionReportIcon } from '../../../../icons/smallIcons/trasactionReport.svg';
import { ReactComponent as BalanceSheetIcon } from '../../../../icons/smallIcons/balanceSheet.svg';
import { AccountLedgerCRUD } from "./Master/AccountLedger/accountLedger";
import { EmployeeCRUD } from "./Master/employee/employee";
import { AccMaster } from "./Master/master";
import { PartyCRUD } from "./Master/party/party";
import { SideNavBar } from "./components/sideNavBar";
import HomePage from "./home/page";
import { PartyBalance } from "./reports/party balance/page";
import { PartyBalanceSummery } from "./reports/party balanceSummery/page";
import { AccReport } from "./reports/report";
import { TransactionReport } from "./reports/transactionReport/page";
import { TrialBalance } from "./reports/trialBalance/page";
import { TransactionUpload } from "./transactions/TrUpload/page";
import { PaymentCRUD } from "./transactions/payment/page";
import { RecieptCRUD } from "./transactions/reciept/page";
import { ProfitAndLoss } from "./reports/profitAndLoss/page";
import { BalanceSheet } from "./reports/balanceSheet/page";
import { IconExposure } from "@tabler/icons-react";
import { ExpenseCRUD } from "./transactions/expense/page";
import { ExpenseReport } from "./reports/expense_report/page";


export function Accounts() {
    const [selectedTab, setSelectedTab] = useState(0)
    const [selectedSubTab, setSelectedSubTab] = useState(null)


    const masterNested = [
        {
            name: 'Employee',
            icon: <IconUsersGroup />,
            href: 'master/employee',
            selectionId: '01',
            path: 'employee',
            component: <EmployeeCRUD/>
        },
        {
            name: 'Party',
            icon: <IconBuildingStore />,
            href: 'master/party',
            selectionId: '02',
            path: 'party',
            component:<PartyCRUD/>
        },
        {
            name: 'Account Ledger',
            icon: <LedgerIcon />,
            href: 'master/accountLedger',
            selectionId: '03',
            path: 'accountLedger',
            component:<AccountLedgerCRUD/>
        },

    ]
    const transactionNested = [
        {
            name: 'Payment',
            icon: <IconChevronsRight />,
            href: 'transaction/payment',
            selectionId: 'A1',
            path: 'payment',
            component: <PaymentCRUD/>
        },
        {
            name: 'Reciept',
            icon: <IconChevronsLeft />,
            href: 'transaction/reciept',
            selectionId: 'A2',
            path: 'reciept',
            component: <RecieptCRUD/>
        },
        {
            name: 'Expense',
            icon: <IconExposure />,
            href: 'transaction/expense',
            selectionId: 'A3',
            path: 'expense',
            component: <ExpenseCRUD/>
        },
        {
            name: 'Transaction Upload',
            icon: <IconFileUpload />,
            href: 'transaction/transactionUpload',
            selectionId: 'A4',
            path: 'transactionUpload',
            component:<TransactionUpload/>
        },
    ]
    const reportNested = [
        {
            name: 'Transaction Report',
            icon: <TransactionReportIcon />,
            href: 'reports/transactinReport',
            selectionId: '13',
            path: 'transactinReport',
            component: <TransactionReport/>
        },
        {
            name: 'Expense Report',
            icon: <IconExposure />,
            href: 'reports/expenseReport',
            selectionId: '16',
            path: 'expenseReport',
            component: <ExpenseReport/>
        },
        {
            name: 'Party Balance',
            icon: <IconBuildingPavilion />,
            href: 'reports/partybalance/init',
            selectionId: '14',
            path: 'partybalance/:id',
            component: <PartyBalance/>
        },
        {
            name: 'Party Balance Summary',
            icon: <IconBrandTidal />,
            href: 'reports/partybalancesummery',
            selectionId: '15',
            path: 'partybalancesummery',
            component: <PartyBalanceSummery/>
        },
        {
            name: 'Trial Balance',
            icon: <IconScale />,
            href: 'reports/trialbalance',
            selectionId: '11',
            path: 'trialbalance',
            component: <TrialBalance/>
        },
        {
            name: 'Profit and Loss',
            icon: <ProfitLossIcon />,
            href: 'reports/profitAndLoss',
            selectionId: '12',
            path: 'profitAndLoss',
            component: <ProfitAndLoss/>
        },
        {
            name: 'Balance Sheet',
            icon: <BalanceSheetIcon />,
            href: 'reports/balanceSheet',
            selectionId: '17',
            path: 'balanceSheet',
            component: <BalanceSheet/>
        },
        // {
        //     name: 'Profit and Loss',
        //     icon: <IconScale />,
        //     href: 'reports/profitAndLoss',
        //     selectionId: '12',
        //     path: 'profitAndLoss',
        //     component: <TrialBalance/>
        // },
    ]

    const generalTabs = [
        {
            name: 'Home',
            icon: <IconHome />,
            href: 'home',
            children: [],
            path: 'home',
            component: <HomePage/>,
            selectionId: 'S1',
        },
        {
            name: 'Master',
            icon: <IconChartDots />,
            href: 'master',
            children: masterNested,
            path: 'master/*',
            component: <AccMaster routes={masterNested} onChangeTab={(id) => setSelectedSubTab(id)} />
        },
        {
            name: 'Transaction',
            icon: <IconArrowsDiff />,
            href: 'transaction',
            children: transactionNested,
            path: 'transaction/*',
            component: <AccMaster routes={transactionNested} onChangeTab={(id) => setSelectedSubTab(id)} />
        },
        {
            name: 'Reports',
            icon: <IconReport />,
            href: 'reports',
            children: reportNested,
            path: 'reports/*',
            component: <AccReport routes={reportNested} onChangeTab={(id) => setSelectedSubTab(id)} />
        },
    ]

    const supportTabs = [
        {
            name: 'Notifications',
            icon: <IconBell />,
            href: 'notifications',
            path:'notifications',
            component: <div>Icons</div>
        },
        {
            name: 'Support',
            icon: <IconHelpSquareRounded />,
            href: 'support',
            path:'support',
            component: <div>support</div>
        },
        {
            name: 'Settings',
            icon: <IconSettings />,
            href: 'settings',
            path:'settings',
            component: <div>settings</div>
        }
    ]

    // useEffect(
    //     ()=>{
    //         // console.log('outer outer first')
    //     },[]
    // )

    return (
        <div className=" flex h-full theme-smooth bg-gray-50 dark:bg-primaryy-dark-1000">
            <SideNavBar generalTabs={generalTabs} supportTabs={supportTabs} selectedTab={selectedTab} selectedInnerTab={selectedSubTab} />
            <BodyWrapperMain>
                <MainTopNav />
                <Routes>
                    <Route index element={<Navigate to={'home'} replace />} />
                    {
                        generalTabs.filter(
                            tab => tab.component
                        ).map(
                            (route, index) => <Route key={`generalTabs${index}`}  path={route.path} Component={() => { setSelectedSubTab(null); setSelectedTab(index); return (
                                route.component
                            // <ProtectedRoute>
                                // </ProtectedRoute>
                                ) }} />
                        )
                    }
                </Routes>
                <BodyWrapperSub>
                    <Routes>
                        {
                            supportTabs.map(
                                (route, index) => <Route key={`supportTabs${index}`}  path={route.path} Component={() => { setSelectedSubTab(null); setSelectedTab(index + generalTabs.length); return (
                                // <ProtectedRoute>{
                                    route.component
                                    // }</ProtectedRoute>
                                    ) }} />
                            )
                        }
                    </Routes>
                </BodyWrapperSub>
            </BodyWrapperMain>
        </div>
    )

}