import React, { useEffect, useState } from 'react'
import { TablePagination, TableStanderd } from '../../../../../../../components/table'
import { TransactionDef, ledgerPostingDef, partyDef } from '../../../../../../../definitions/definintion'
import { BodyWrapperMain, BodyWrapperSub, BodyWrapperSubNoPadding } from '../../../../../../../components/layouts'
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import { useRESTapi } from '../../../../../../../controller/restAPI';
import { showErrorToast } from '../../../../../../../components/toast';
import { setTopNavText } from '../../../../../../../redux/config/configSlice';
import { useDispatch } from 'react-redux';
import { LoadingSimple } from '../../../../../../../components/loading';
import { formatNumberWithCommas, formattedDate } from '../../../../../../../controller/format';

export function TransactionDebit({ show = false, closeScreen, id, fromDate, toDate, type = 'credit' | 'debit', heading }) {

    const searchParamDef = {
        fromDate: 'start_date',
        toDate: 'end_date',
        party: TransactionDef.PartyID,
        type: TransactionDef.TranType,
        groupId: 'groupId'
    }

    const [rows1, setRow1] = useState([

    ])
    const [isLoading, setIsLoading] = useState(false)
    const [searchParams, setSearchParams] = useState({
        [searchParamDef.fromDate]: '',
        [searchParamDef.toDate]: '',
    })
    const [totalRow, setTotalRow] = useState(90)
    const [rowPerPage, setRowPerPage] = useState(30)
    const [pageNo, setpageNo] = useState(1)
    const [updateUi, setUpdateUi] = useState(false)

    const dispatch = useDispatch()



    const headers = [


        {
            id: '4',
            name: 'No',
            label: 'serialNo'
        },
        {
            id: '1',
            name: 'Date',
            label: 'dateFormatted'
        },
        {
            id: '6',
            name: 'Description',
            label: [TransactionDef.Description]
        },
        {
            id: '2',
            name: 'Party',
            label: [partyDef.PartyName]
        },
        {
            id: '5',
            name: 'Amount',
            label: 'commaAmount'
        },
        // {
        //     id: '7',
        //     name: '',
        //     label: 'deleteButton'
        // },

    ]
    const trialBalanceDef = {
        id: 'group_id',
        particulars: "group_name",
        debit: "total_debits",
        credit: 'total_credits',
        balance: "balance",
        balanceType: "balance_type",
        totalDebit: 'total_debits',
        totalCredit: 'total_credits'
    }

    const api = useRESTapi();


    const rebuildUi = () => {
        setUpdateUi(!updateUi)
    }

    useEffect(
        () => {
            if (show === true) {
                setpageNo(1)
            }
        }, [show]
    )

    useEffect(
        () => {

            const initialLoadDebit = async () => {

                setIsLoading(true)

                try {
                    const res = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/account-groups/debit-transactions`,
                        params: {
                            [searchParamDef.fromDate]: fromDate,
                            [searchParamDef.toDate]: toDate,
                            [searchParamDef.groupId]: id,
                            perPage: rowPerPage,
                            page: pageNo
                        }
                    });

                    setTotalRow(res.data.pagination.total)


                    if (res?.data?.success === true) {
                        const employees = res.data.data;

                        const rows = employees.map(
                            (emp, index) => {
                                emp['serialNo'] = ((pageNo - 1) * rowPerPage) + (index + 1);
                                emp['dateFormatted'] = <div className=' whitespace-nowrap'>{formattedDate(emp[ledgerPostingDef.Date])}</div>
                                emp['commaAmount'] = formatNumberWithCommas(emp[ledgerPostingDef.Debit])

                                // emp[trialBalanceDef.debit] = <div className=" cursor-pointer" onClick={() =>{setTransactionId(emp[trialBalanceDef.id]); setshowTransactionDebit(true)}} >{emp[trialBalanceDef.debit]}</div>
                                return ({
                                    id: emp[TransactionDef.TransactionID],
                                    data: emp
                                })
                            }
                        )
                        setRow1(rows)
                        // rowEndValues.totalCredit = res.data[trialBalanceDef.totalCredit]
                        // rowEndValues.totalDebit = res.data[trialBalanceDef.totalDebit]
                        // setRowEndValues({ ...rowEndValues })
                    } else {
                        showErrorToast({
                            message: res?.data?.message || 'something went wrong',
                            showAtTimeOne: true,
                            id: 'trialBalance',
                            timeOut: 5000
                        })
                    }


                } catch (error) {
                    console.log(error)
                    showErrorToast({
                        message: 'Internal Error !!!',
                        showAtTimeOne: true,
                        id: 'INTERNAL ERROR'
                    })
                }
                setIsLoading(false)
            }
            const initialLoadCredit = async () => {

                setIsLoading(true)

                try {
                    const res = await api.get({
                        url: `${process.env.REACT_APP_BACKEND_URL}/api/account-groups/credit-transactions`,
                        params: {
                            [searchParamDef.fromDate]: fromDate,
                            [searchParamDef.toDate]: toDate,
                            [searchParamDef.groupId]: id,
                            perPage: rowPerPage,
                            page: pageNo
                        }
                    });
                    setTotalRow(res.data.pagination.total)

                    if (res?.data?.success === true) {
                        const employees = res.data.data;

                        const rows = employees.map(
                            (emp, index) => {
                                emp['serialNo'] = ((pageNo - 1) * rowPerPage) + (index + 1);
                                emp['dateFormatted'] = <div className=' whitespace-nowrap'>{formattedDate(emp[ledgerPostingDef.Date])}</div>
                                emp['commaAmount'] = formatNumberWithCommas(emp[ledgerPostingDef.Credit])
                                return ({
                                    id: emp[TransactionDef.TransactionID],
                                    data: emp
                                })
                            }
                        )
                        setRow1(rows)
                        // rowEndValues.totalCredit = res.data[trialBalanceDef.totalCredit]
                        // rowEndValues.totalDebit = res.data[trialBalanceDef.totalDebit]
                        // setRowEndValues({ ...rowEndValues })
                    } else {
                        showErrorToast({
                            message: res?.data?.message || 'something went wrong',
                            showAtTimeOne: true,
                            id: 'trialBalance',
                            timeOut: 5000
                        })
                    }


                } catch (error) {
                    console.log(error)
                    showErrorToast({
                        message: 'Internal Error !!!',
                        showAtTimeOne: true,
                        id: 'INTERNAL ERROR'
                    })
                }
                setIsLoading(false)
            }

            if (id) {

                switch (type) {
                    case 'debit':
                        initialLoadDebit()
                        break;
                    case 'credit':
                        initialLoadCredit()
                        break

                    default:
                        break;
                }
            }

        }, [updateUi, id, type, pageNo]
    )



    return createPortal(
        <Transition
            className={` fixed inset-0`}
            show={show}
        // beforeEnter={beforeEnter}
        >
            <Transition.Child

                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-50"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-50"
            >
                <div className=' h-screen w-screen'>
                    <BodyWrapperSubNoPadding className={'h-full'}>
                        <div className=' w-full h-full relative p-2 sm:p-4 md:p-5 lg:p-6' >
                            <div className=' absolute inset-0 bg-black/30 -z-10'></div>
                            <div className='  bg-white rounded-md dark:bg-primaryy-dark-1000 w-full h-full flex flex-col gap-2 z-10 overflow-auto'>
                                <div className=' bg-gray-100 dark:bg-primaryy-dark-950/20 text-center font-bold leading-6 text-gray-900 dark:text-gray-300 h-11 flex items-center justify-between p-2'>
                                    <div className=' text-xl font-bold'>{type==='debit'?'Debit / ':'Credit / '}{heading}</div>
                                    <div onClick={closeScreen} className='  dark:text-white w-8 h-8 flex justify-center items-center cursor-pointer hover:bg-gray-500/10 '><IconX /></div>
                                </div>
                                <div className='p-2 gap-2 grow flex flex-col overflow-hidden'>
                                    {
                                        isLoading === false
                                            ?
                                            rows1.length > 0
                                                ?
                                                <div className='  flex overflow-auto z-10'>
                                                    <div className=' flex grow overflow-auto  sm:rounded-lg border'>
                                                        <TableStanderd>
                                                            <TableStanderd.Head >
                                                                {
                                                                    headers.map(
                                                                        (header,index) => (<th key={`${header.id}-headers-${index}`} scope="col" className="py-3 px-6">{header.name}</th>)
                                                                    )
                                                                }
                                                            </TableStanderd.Head>
                                                            <TableStanderd.Body>
                                                                {
                                                                    rows1.map(
                                                                        (row, index) => {

                                                                            if(rows1.length===index+1){

                                                                                return (
                                                                                    <TableStanderd.TbRowEnd key={row.id} >
                                                                                        {
                                                                                            headers.map(
                                                                                                (header, index) => (
                                                                                                    <TableStanderd.Td key={index} >{row.data[header.label]}</TableStanderd.Td>
                                                                                                )
                                                                                            )
                                                                                        }
    
                                                                                    </TableStanderd.TbRowEnd>
                                                                                )

                                                                            }

                                                                            return (
                                                                                <TableStanderd.TbRow key={row.id} >
                                                                                    {
                                                                                        headers.map(
                                                                                            (header, index) => (
                                                                                                <TableStanderd.Td key={index} >{row.data[header.label]}</TableStanderd.Td>
                                                                                            )
                                                                                        )
                                                                                    }

                                                                                </TableStanderd.TbRow>
                                                                            )
                                                                        }
                                                                    )
                                                                }

                                                            </TableStanderd.Body>
                                                        </TableStanderd>
                                                    </div>
                                                </div>
                                                :
                                                <div className=' flex grow dark:text-gray-300 text-3xl font-bold  items-center justify-center theme-smooth'>No Records Found</div>
                                            :
                                            <LoadingSimple />
                                    }
                                    <TablePagination selectedPage={pageNo} totalRows={totalRow} rowsPerPage={rowPerPage} onClickIndex={(index) => setpageNo(index)} />

                                </div>
                            </div>
                        </div>
                    </BodyWrapperSubNoPadding>
                </div>
            </Transition.Child>

        </Transition>
        ,
        document.body
    )
}
