import axios from "axios";
import { useAuthenticaton } from "./authentications";
import { useState } from "react";
import { showErrorToast } from "../components/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTryAgainHref } from "../redux/config/configSlice";

export function useRESTapi() {


    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentPath = location.pathname;

    function generateRandomId() {
        const timestamp = Date.now();
        const randomSuffix = Math.random().toString(36).substr(2);
        return `${timestamp}-${randomSuffix}`;
    }


    const { logout } = useAuthenticaton()
    const [uid, setUid] = useState(generateRandomId())

    function errorHandle(code) {

        switch (code) {
            case 'ERR_NETWORK':
                dispatch(setTryAgainHref(currentPath))
                // navigate('/networkError')
                showErrorToast({
                    message: 'Please check network !!!',
                    showAtTimeOne: true,
                    id: 'INTERNAL ERROR'
                })
                break;

            default:
                break;
        }

    }



    async function get({
        url = '',
        body = {},
        headers = {},
        params = {},
        responseType,
        withCredentials=false
    }) {

        const header = {
            Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE)}`,
        };

        try {

            const res = await axios.get(url, {
                headers: {
                    ...header,
                    headers,

                },
                responseType,
                params,
                withCredentials
                
            })
            if (res.status === 401) {   //authentication error
                logout()
            }

            return res;

        } catch (error) {
            console.log(error)
            errorHandle(error.code)
            if (error.response?.status === 401) {
                logout()
            }
            return error.response

        }

    }
    async function post({
        url = '',
        body = {},
        headers = {},
        onUploadProgress
    }) {

        const header = {
            Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE)}`,
        };

        try {

            const res = await axios.post(url, body, {
                headers: {
                    ...header,
                    headers
                },
                onUploadProgress:onUploadProgress
            })
            if (res.status === 401) {   //authentication error
                logout()
            }

            return res;

        } catch (error) {
            errorHandle(error.code)
            if (error.response?.status === 401) {
                logout()
            }
            console.log(error)
            return error.response

        }

    }
    async function put({
        url = '',
        body = {},
        headers = {}
    }) {

        const header = {
            Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE)}`,
        };

        try {

            const res = await axios.put(url, body, {
                headers: {
                    ...header,
                    headers
                }
            })
            if (res.status === 401) {   //authentication error
                logout()
            }

            return res;

        } catch (error) {
            console.log(error)
            errorHandle(error.code)
            if (error.response?.status === 401) {
                logout()
            }
            return error.response

        }

    }
    async function Delete({
        url = '',
        body = {},
        headers = {}
    }) {

        const header = {
            Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE)}`,
        };

        try {

            const res = await axios.delete(url, {
                headers: {
                    ...header,
                    headers
                }
            })
            if (res.status === 401) {   //authentication error
                logout()
            }

            return res;

        } catch (error) {
            console.log(error)
            errorHandle(error.code)
            if (error.response?.status === 401) {
                logout()
            }
            return error.response

        }

    }

    return ({
        get,
        post,
        put,
        Delete
    })


}