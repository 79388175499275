
export const productionModel = false

//theme modes
export const themeDef = {
    SYSTEM: 'system',
    DARK: 'dark',
    LIGHT: 'light'
}

export const themeStateDef = {
    START: 'start',
    PENDING: 'pending',
    END: 'end'
}

export const windowStateDef = {
    fullScreen: 'fullScreen',
    notFullScreen: 'notFullScreen'
}

export const sideNavStatesDef = {
    close: 'close',
    open: 'open',
    pending: true,
    notPening: false,
    pendingTime: 50     //in ms
}

export const sideNavSizesDef = {
    shrink: 'shrink',
    expanded: 'expanded',
    shrinkSize: '90px',
    expandedSize: '220px'
}

export const breakPointDef = {
    xxs: {
        name: 'xxs',
        size: 250    //px
    },
    xs: {
        name: 'xs',
        size: 450    //px
    },
    sm: {
        name: 'sm',
        size: 640    //px
    },
    md: {
        name: 'md',
        size: 768    //px
    },
    tablet: {
        name: 'tablet',
        size: 950    //px
    },
    lg: {
        name: 'lg',
        size: 1024    //px
    },
    xl: {
        name: 'xl',
        size: 1280    //px
    },
    xxl: {
        name: 'xxl',
        size: 1536    //px
    },
    xxxl: {
        name: 'xxl',
        size: 1836    //px
    },

}


export const squareButtonVarient = {
    primary: 'primary',
    outlined: 'outlined',
    danger: 'danger'

}

export const toastTypeDef = {
    error: 'error',
    success: 'success'
}



export const employeeDef = {
    EmployeeId: "FM08_EmployeeId",     //0
    EmployeeName: "FM08_EmployeeName",
    EmployeeCode: "FM08_EmployeeCode",
    ReportingTo: "FM08_M08_ReportingTo",
    CountryID: "FM08_M01_CountryID",
    BranchId: "FM08_M05_BranchId",      //5
    Designation: "FM08_Designation",
    DOB: "FM08_DOB",
    MaritalStatus: 'FM08_MaritalStatus',
    Gender: "FM08_Gender",
    Qualification: "FM08_Qualification",    //10
    Address: "FM08_Address",
    MobileNumber: "FM08_MobileNumber",
    email: "email",
    LoginUserName: "FM08_LoginUserName",
    JoiningDate: "FM08_JoiningDate",    //16
    TerminationDate: "FM08_TerminationDate",
    BloodGroup: "FM08_BloodGroup",
    AllowLogin: "FM08_AllowLogin",         //19
    IsActive: "FM08_IsActive",
    CreatedBy: "FM08_CreatedBy",
    CreatedDate: "FM08_CreatedDate",
    Otp: "FM08_Otp",
}


export const partyDef = {
    PartyID: "FM09_PartyID",     //0
    PartyName: "FM09_PartyName",
    CountryID: "FM09_M01_CountryID",
    Address: "FM09_Address",
    MobileNumber: "FM09_MobileNumber",
    Email: "FM09_Email",      //5
    IsActive: "FM09_IsActive",
    CreatedBy: "FM09_CreatedBy",
    CreatedDate: 'FM09_CreatedDate',
}


export const accountLedgerDef = {
    AccountLedgerId: "FM07_AccountLedgerId",     //0
    AccountGroupId: "FM07_M06_AccountGroupId",
    LedgerName: "FM07_LedgerName",
    OpeningBalance: "FM07_OpeningBalance",
    IsDefault: "FM07_IsDefault",
    CrOrDr: "FM07_CrOrDr",      //5
    Narration: "FM07_Narration",
    MailingName: "FM07_MailingName",
    Address: 'FM07_Address',
    Phone: "FM07_Phone",     //9
    Mobile: "FM07_Mobile",
    Email: "FM07_Email",
    CreditPeriod: "FM07_CreditPeriod",
    CreditLimit: "FM07_CreditLimit",
    IsActive: "FM07_IsActive",      //14
    CreatedBy: "FM07_CreatedBy",
    CreatedDate: "FM07_CreatedDate",
}

export const transType = {
    reciept: 'R',
    payment: 'P',
    expense:'E'
}


export const TransactionDef = {
    SerialNo: 'FT02_Serial_No',
    TransactionID: 'FT02_TransactionID',
    BranchId: 'FT02_M02_BranchId',
    LedgerPostingId: 'FT02_T01_LedgerPostingId',
    InvoiceNo: 'FT02_InvoiceNo',
    Date: 'FT02_Date',
    TranType: 'FT02_TranType',
    PartyID: 'FT02_M09_PartyID',
    LedgerId:'FT02_M07_LedgerId',
    AccountLedgerId: 'FT02_M07_AccountLedgerId',
    CurrencyID: 'FT02_M03_CurrencyID',
    BaseAmount: 'FT02_BaseAmount',
    ConversionRate: 'FT02_ConversionRate',
    ConvertedAmount: 'FT02_ConvertedAmount',
    "FiscalYearID": "FT02_M02_FiscalYearID",
    "EntryEmployeeId": "FT02_M08_EntryEmployeeId",
    "IsActive": "FT02_IsActive",
    "CreatedBy": "FT02_CreatedBy",
    "CreatedDate": "FT02_CreatedDate",
    "SysCode": "FT02_SysCode",
    "IsApprove": "FT02_IsApprove",
    "ApprovedBy": "FT02_ApprovedBy",
    "ApprovedDate": "FT02_ApprovedDate",
    "FT02_expected_received_date": 'FT02_expected_received_date',
    "FT02_payment_back_date": 'FT02_payment_back_date',
    "IsCancel": "FT02_IsCancel",
    'Description': 'FT02_Description',
    "CanceledBy": "FT02_CanceledBy",
    "CanceledDate": "FT02_CanceledDate",
    Is_pinned:'FT02_is_pinned'
}

export const ledgerPostingDef = {
    LedgerPostingID: "FT01_LedgerPostingID",
    Date: "FT01_Date",
    AccountLedgerId: "FT01_M07_AccountLedgerId",
    CurrencyID: "FT01_M03_CurrencyID",
    BranchID: "FT01_M05_BranchID",
    BaseAmount: "FT01_BaseAmount",
    conversionRate: "FT01_conversionRate",
    Debit: "FT01_Debit",
    Credit: "FT01_Credit",
    RefTable: "FT01_RefTable",
    RefTableID: "FT01_RefTableID",
    FiscalYearID: "FT01_M02_FiscalYearID",
    InvoiceNo: "FT01_InvoiceNo",
    ChequeNo: "FT01_ChequeNo",
    ChequeDate: "FT01_ChequeDate",
    IsActive: "FT01_IsActive",
    CreatedBy: "FT01_CreatedBy",
    CreatedDate: "FT01_CreatedDate",
    SysCode: "FT01_SysCode",
    IsApprove: "FT01_IsApprove",
    ApprovedBy: "FT01_ApprovedBy",
    ApprovedDate: "FT01_ApprovedDate",
    IsCancel: "FT01_IsCancel",
    CanceledBy: "FT01_CanceledBy",
    CanceledDate: "FT01_CanceledDate"
}


export const accountGroupDef = {
    AccountGroupId: "FM06_AccountGroupId",     //0
    AccountGroupName: "FM06_AccountGroupName",
}

























