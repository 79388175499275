import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import { ProtectedRoute } from "./components/layouts";
import HomeRoute from "./pages/home/home.route";
import { LoginPage } from "./pages/login.page";
import { initThemeMode } from "./redux/config/configSlice";
import { NetworkError } from "./pages/networkError";

export default function App(props) {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);


  useEffect(
    () => {
      dispatch(initThemeMode())
      setTimeout(
        () => {
          setIsLoaded(true)
        }, 100
      )
    }, []
  )
  return (
    <>
      <BrowserRouter>
        <Routes>
          {isLoaded && (
            <>
              {/* <Route key={1} path="/" element={<SignUpPage />} /> */}
              {/* <Route key={2} path="/login" element={<LoginPage />} /> */}
              <Route key={2} path="/" element={<ProtectedRoute loadingLogin={true} ><LoginPage /></ProtectedRoute>} />
              <Route key={5} path="/networkError" element={<NetworkError/>} />
              <Route key={4} path="*" element={<ProtectedRoute><HomeRoute /></ProtectedRoute>} />
              {/* <Route key={4} path="managements/*" element={<Managment />} /> */}
            </>
          )
        }
        </Routes>
      </BrowserRouter>
    </>
  );
}
