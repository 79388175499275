import { useDispatch, useSelector } from "react-redux"
import { sideNavSizesDef, themeStateDef } from "../definitions/definintion"
import React, { useEffect, useRef, useState } from "react"
import { IconArrowLeft, IconArrowRight, IconDotsVertical, IconFileDownload } from "@tabler/icons-react"
import { SelectInputStd2 } from "./inputs"
import MenuSimple from "./menu"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { showToast } from "./toast"
import { Transition } from "@headlessui/react"
import { Tooltip, tooltip } from "@material-tailwind/react"

class TableStanderd extends React.Component {

    static Head = ({ children }) => {
        return (
            <thead className={`theme-smooth text-xs text-gray-700 uppercase bg-gray-50/90 dark:bg-gray-700 dark:text-gray-400`}>
                <tr>
                    {children}
                </tr>
            </thead>
        )
    }

    static Body = ({ children }) => {
        return (
            <tbody>
                {children}
            </tbody>
        )
    }

    static Row = ({ children }) => {

        return (
            <tr >
                {children}
            </tr>
        )
    }

    static TbRow = ({ children, onClick = () => { }, coloured = false, hovering = true }) => {
        const theme = useSelector(
            state => state.config.theme
        )
        return (
            <tr onClick={onClick} className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''}  border-b ${coloured === true ? `bg-primaryy-200 text-gray-800 dark:text-white font-semibold dark:bg-gray-900 dark:border-gray-700  ${hovering === true ? 'hover:dark:bg-gray-600' : ''}` : `bg-white dark:bg-gray-800 dark:border-gray-700 ${hovering === true ? 'hover:bg-gray-200 hover:dark:bg-gray-600' : ''}`}`}>
                {children}
            </tr>
        )
    }
    static TbRowEnd = ({ children, onClick = () => { }, coloured = false, hovering = true }) => {
        const theme = useSelector(
            state => state.config.theme
        )
        return (
            <tr onClick={onClick} className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} ${coloured === true ? `bg-primaryy-400 text-white font-semibold dark:bg-gray-900 dark:border-gray-700  ${hovering === true ? 'hover:dark:bg-gray-600' : ''}` : `bg-white dark:bg-gray-800 dark:border-gray-700 ${hovering === true ? 'hover:bg-gray-200 hover:dark:bg-gray-600' : ''}`}`}>
                {children}
            </tr>
        )
    }

    static Td = ({ children, colSpan, rowSpan }) => {

        return (
            <td className="py-2 px-6" colSpan={colSpan} rowSpan={rowSpan}  >{children}</td>
        )

    }
    static Button = ({ children, onClick = (event) => { },tooltip }) => {

        if(tooltip){
            return (
                <Tooltip content={tooltip}>
                    <div onClick={onClick} className="p-2 border border-transparent  hover:border-gray-400/50 w-min h-min rounded-md cursor-pointer hover:shadow shadow-gray-300 duration-100 " >{children}</div>
                </Tooltip>
            )
        }else{
            return <div onClick={onClick} className="p-2 border border-transparent  hover:border-gray-400/50 w-min h-min rounded-md cursor-pointer hover:shadow shadow-gray-300 duration-100 " >{children}</div>  
        }

        

    }

    render() {
        return (
            <div className=" flex flex-col grow overflow-auto relative shadow-md sm:rounded-lg">
                <table className=" grow w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    {this.props.children}
                </table>
            </div>
        )
    }

}


export function TablePagination({
    // indexSelected=1,
    totalRows,
    rowsPerPage,
    selectedPage = 1,
    onClickIndex = (index) => { }
}) {

    const [totalPages, setTotalPages] = useState(0);
    const diff = 2;



    const [leftButtons, setLeftButtons] = useState([])      //left side hide buttons
    const [buttonArray, setButtonArray] = useState([])
    const [rightButtons, setRightButtons] = useState([])      //right side hide buttons

    // left side buttons listing
    useEffect(
        () => {
            const leftButtons = []
            const buttonArray = []
            const rightButtons = []
            setTotalPages(Math.ceil(totalRows / rowsPerPage))
            const totalPages = Math.ceil(totalRows / rowsPerPage)
            if (selectedPage - diff > 1) {
                buttonArray.push({
                    value: '...',
                    type: 'leftDrop'
                });
                for (let i = selectedPage - diff; i <= selectedPage; i++) {
                    buttonArray.push({
                        value: i,
                        type: 'number'
                    })
                }
                for (let j = 1; j < selectedPage - diff; j++) {
                    leftButtons.push({
                        value: j,
                        type: 'number'
                    })
                }
            } else {
                for (let i = 1; i <= selectedPage; i++) {
                    buttonArray.push({
                        value: i,
                        type: 'number'
                    })
                }
            }
            // right side button listing
            if (selectedPage + diff < totalPages) {
                for (let i = selectedPage + 1; i <= selectedPage + diff; i++) {
                    buttonArray.push({
                        value: i,
                        type: 'number'
                    })
                }
                buttonArray.push({
                    value: '...',
                    type: 'rightDrop'
                })
                for (let j = totalPages; j >= selectedPage + diff + 1; j--) {
                    rightButtons.push({
                        value: j,
                        type: 'number'
                    })
                }
            } else {
                for (let i = selectedPage + 1; i <= totalPages; i++) {
                    buttonArray.push({
                        value: i,
                        type: 'number'
                    })
                }
            }
            setButtonArray(buttonArray)
            setLeftButtons(leftButtons)
            setRightButtons(rightButtons)
        }, [totalRows, rowsPerPage, selectedPage]
    )


    // const buttons = Array.from({ length: totalPages }, (_, index) => {
    //     const buttonNumber = index + 1; // Add 1 to get 1 to 5
    //     return (
    //         <div onClick={()=>onClickIndex(buttonNumber)} className={` cursor-pointer   rounded-md shadow-sm h-full  min-w-7  ${ buttonNumber===selectedPage? 'bg-primaryy-500 text-white':'bg-white hover:bg-gray-200'} flex justify-center items-center  text-xs`} >{buttonNumber}</div>
    //         );
    // });
    const buttons = buttonArray.map((item, index) => {
        switch (item.type) {
            case 'number':

                return (
                    <div key={index} onClick={() => onClickIndex(item.value)} className={` cursor-pointer   rounded-md shadow-sm h-full  min-w-7  ${item.value === selectedPage ? 'bg-primaryy-500 text-white' : 'bg-white hover:bg-gray-200'} flex justify-center items-center  text-xs`} >{item.value}</div>
                );
            case 'leftDrop':

                return (
                    <LeftList
                        key={index}
                        list={leftButtons}
                        label={<div className={` cursor-pointer   rounded-md shadow-sm h-full min-h-7  min-w-7  bg-white hover:bg-gray-200 flex justify-center items-center  text-xs`} >{item.value}</div>}
                        onSelect={onClickIndex}
                    />
                );
            case 'rightDrop':

                return (
                    <RightList
                        key={index}
                        list={rightButtons}
                        label={<div className={` cursor-pointer   rounded-md shadow-sm h-full min-h-7  min-w-7  bg-white hover:bg-gray-200 flex justify-center items-center  text-xs`} >{item.value}</div>}
                        onSelect={onClickIndex}
                    />);



            default:
                break;
        }
    });




    // if only one page found or no data
    if (totalRows <= rowsPerPage) {
        return null
    }
    return (
        <div className=" flex">
            <div className=" flex h-7 min-h-7 gap-1">
                {/* <div className="rounded-md shadow-sm h-full min-w-7  bg-white flex justify-center items-center"  ><IconArrowLeft size={17} /></div> */}
                {buttons}
                {/* <div className="rounded-md shadow-sm h-full  min-w-7 bg-white leading-none flex justify-center items-center"><IconArrowRight size={17} /></div> */}
            </div>
        </div>
    )

}

function LeftList({ list = [], label, onSelect = (value) => { } }) {

    const [open, setOpen] = useState(false)
    const theme = useSelector(
        state => state.config.theme
    )
    const [height, setHeight] = useState(0)

    const heightOfSingle = 30

    // const height = ((list.length*heightOfSingle))<=200?((list.length*heightOfSingle)+20):200
    const contentRef = useRef(null)



    return (

        <MenuSimple
            show={open}
            closeMenu={() => { setOpen(false) }}
            contentClassName={' !p-0 overflow-hidden rounded-md'}
            label={<div onClick={() => setOpen(true)}>{label}</div>}
            // top={-(height + 5)}
            beforeEnter={() => {
                setHeight(contentRef.current?.offsetHeight)
                contentRef.current.scrollTop = contentRef.current?.scrollHeight;
            }}
            // right={0}
            left={0}
            bottom={30}
        >
            <div style={{
                maxHeight: 200,
                overflow: 'auto'
            }} ref={contentRef} className="flex flex-col gap-1 thin-scrollbar">
                {
                    list.map(
                        (item, index) => (
                            <button
                                key={index}
                                style={{
                                    height: heightOfSingle,
                                    minHeight: heightOfSingle,
                                    maxHeight: heightOfSingle
                                }}
                                onClick={() => { onSelect(item.value); setOpen(false) }}
                                className={`${theme.state === themeStateDef.PENDING ? ' theme-smooth' : 'transition-colors duration-100'} w-full rounded-md px-3 py-1 text-sm cursor-pointer 
                                    hover:text-gray-900 hover:dark:text-gray-300 dark:text-gray-100 hover:bg-gray-100 hover:dark:bg-gray-900 text-center `}

                            > {item.value} </button>
                        )
                    )
                }
            </div>
        </MenuSimple>
    )
}
function RightList({ list = [], label, onSelect = (value) => { } }) {

    const [open, setOpen] = useState(false)
    const theme = useSelector(
        state => state.config.theme
    )
    const [height, setHeight] = useState(0)

    const heightOfSingle = 30

    // const height = ((list.length*heightOfSingle))<=200?((list.length*heightOfSingle)+20):200
    const contentRef = useRef(null)



    return (

        <MenuSimple
            show={open}
            closeMenu={() => { setOpen(false) }}
            contentClassName={' !p-0 overflow-hidden rounded-md'}
            label={<div onClick={() => setOpen(true)}>{label}</div>}
            // top={-(height + 5)}
            bottom={30}
            beforeEnter={() => {
                setHeight(contentRef.current?.offsetHeight)
                contentRef.current.scrollTop = contentRef.current?.scrollHeight;
            }}
            right={0}
        // left={0}
        >
            <div style={{
                maxHeight: 200,
                overflow: 'auto'
            }} ref={contentRef} className="flex flex-col gap-1 thin-scrollbar">
                {
                    list.map(
                        (item, index) => (
                            <button
                                key={index}
                                style={{
                                    height: heightOfSingle,
                                    minHeight: heightOfSingle,
                                    maxHeight: heightOfSingle
                                }}
                                onClick={() => { onSelect(item.value); setOpen(false) }}
                                className={`${theme.state === themeStateDef.PENDING ? ' theme-smooth' : 'transition-colors duration-100'} w-full rounded-md px-3 py-1 text-sm cursor-pointer 
                                    hover:text-gray-900 hover:dark:text-gray-300 dark:text-gray-100 hover:bg-gray-100 hover:dark:bg-gray-900 text-center `}

                            > {item.value} </button>
                        )
                    )
                }
            </div>
        </MenuSimple>
    )
}

export function TableMenu({
    options = []
}) {
    const [show, setShow] = useState(false)
    const navigate = useNavigate();
    const theme = useSelector(
        state => state.config.theme
    )
    const user = useSelector(
        state => state.user.user
    )
    const sideNav = useSelector(
        state => state.config.sideNav
    )
    const toggleSettings = () => {
        setShow(!show)
    }


    return (
        <div className="h-full" >



            {
                sideNav.size === sideNavSizesDef.expanded ?
                    <MenuSimple
                        show={show}
                        closeMenu={() => setShow(false)}
                        className={'h-full'}
                        // contentClassName={'p-1'}
                        top={35}
                        right={0}
                        label={<div className={` ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ' transition-colors duration-100]'} h-full ${show === true ? 'bg-gray-500/20' : 'hover:bg-gray-500/20'}  rounded-md flex items-center dark:text-gray-200 cursor-pointer `} onClick={toggleSettings} ><IconDotsVertical size={17} /></div>}
                        closeInsideTouch={false}
                    >
                        {
                            options.map(
                                (item) => {
                                    return (
                                        <div
                                            onClick={() => item.onClick({ closeMenu: () => setShow(false) })}
                                            key={item.state} className="min-w-[20px] rounded-md pe-6 py-1 text-sm text-gray-700 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900  active:bg-blue-100 cursor-pointer flex items-end" role="menuitem">
                                            <div className="leading-none px-1 text-lg">{item.icon}</div> {item.name}
                                        </div>
                                    )
                                }
                            )
                        }
                    </MenuSimple> :
                    null
            }

        </div>
    )

}

export { TableStanderd }