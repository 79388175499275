import ReactDOM from 'react-dom'


export function LoadingSimple({zIndex = 50 }) {


    return (
        <div  className={` w-full h-full  transition-opacity`} style={{ zIndex: zIndex }} >
            <div className={`flex space-x-1 justify-center items-center h-full z-50 `}>
                <span className='sr-only'>Loading...</span>
                <div className='h-2 w-2 bg-primaryy-400 rounded-full animate-bounce  [animation-delay:-0.3s]'></div>
                <div className='h-2 w-2 bg-primaryy-400 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                <div className='h-2 w-2 bg-primaryy-400 rounded-full animate-bounce'></div>
            </div>
        </div>
    )
}