import { Transition } from "@headlessui/react"
import { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { deleteToastWithId } from "../redux/toast/toastSlice";
import { IconSquareRoundedCheckFilled, IconSquareRoundedXFilled, IconX } from "@tabler/icons-react";
import { store } from "../redux/store";
import { showToast as showToastRedux } from "../redux/toast/toastSlice";
import ReactDOM from 'react-dom'
import { themeStateDef, toastTypeDef } from "../definitions/definintion";


export function ToastScreen() {

    const dispatch = useDispatch();

    const toastmsgs = useSelector(
        state => state.toast.toasts
    )

    return ReactDOM.createPortal(
        <div className={` ${toastmsgs.length > 0 ? 'p-3 pe-5' : ''}  flex flex-col gap-2 items-center fixed z-[10000] right-0 bottom-0 `}>
            {
                toastmsgs.map(
                    (toasts, index) => (
                        <ToastMessage key={toasts.id} msg={toasts} deleteToast={
                            () => dispatch(deleteToastWithId(toasts.id))
                        } />
                    )
                )
            }
        </div>,
        document.body
    )

}



function ToastMessage({ msg, deleteToast = () => { } }) {

    const [open, setOpen] = useState(true)
    const theme = useSelector(
        state => state.config.theme
    )
    const currentTimerPos = useRef(0)
    const [update, setUpdate] = useState(false)

    const [mouseHovered, setMouseHovered] = useState(false)

    const [timer, setTimer] = useState(null)

    useEffect(
        () => {


            if (mouseHovered === false) {
                const timer = setTimeout(
                    () => {
                        // console.log(currentTimerPos.current)
                        if (currentTimerPos.current >= msg.timeOut) {
                            clearTimeout(timer)
                            setOpen(false)
                            const timoutCLose = setTimeout(
                                deleteToast, 500
                            )

                        } else {
                            currentTimerPos.current = currentTimerPos.current + 20
                        }
                        setUpdate(!update)
                    }
                    ,
                    20
                )

                setTimer(timer)
            }


            return () => {
                clearTimeout(timer)
            }

        }, [update,mouseHovered]
    )

    // useEffect(
    //     () => {

    //         const timeOut = setTimeout(
    //             deleteToast, msg.timeOut
    //         )
    //         const timoutCLose = setTimeout(
    //             () => setOpen(false), msg.timeOut - 500
    //         )




    //         return () => {
    //             clearTimeout(timeOut)
    //             clearTimeout(timoutCLose)
    //         }


    //     }, []
    // )

    switch (msg.type) {
        case toastTypeDef.error:
            return (
                <Transition
                    as={Fragment}
                    show={open}
                    enter="ease-out duration-500"
                    enterFrom="opacity-0 translate-x-3.5"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-500"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo=" opacity-0 scale-95 translate-x-12"
                    appear
                >
                    <div>
                        <div
                            onMouseEnter={()=>setMouseHovered(true)}
                            onMouseLeave={()=>setMouseHovered(false)}
                            className={`flex ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} relative items-center w-full max-w-xs p-4  text-gray-700 bg-gray-50 border-2 border-red-500  dark:border-red-400 rounded-lg shadow-sm dark:text-gray-200 dark:bg-gray-800`}
                        >
                            <div
                                className="theme-smooth inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                                <IconSquareRoundedXFilled />
                                <span className="sr-only">Check icon</span>
                            </div>
                            <div className="ml-3 text-sm font-normal pe-2  ">{msg.message}</div>
                            <button onClick={deleteToast} className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700`} data-dismiss-target="#toast-success" aria-label="Close">
                                <span className="sr-only">Close</span>
                                <IconX />
                            </button>
                            <div className=" absolute left-0 right-0 h-[2px] bottom-0 transition-transform duration-200">
                                <div style={{ width: `${(currentTimerPos.current / msg.timeOut) * 100}%` }} className=" bg-red-500 h-full transition-transform duration-200 "></div>
                            </div>
                        </div>
                    </div>
                </Transition>
            )
        case toastTypeDef.success:
        default:
            return (
                <Transition
                    as={Fragment}
                    show={open}
                    enter="ease-out duration-500"
                    enterFrom="opacity-0 translate-x-3.5"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-500"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo=" opacity-0 scale-95 translate-x-12"
                    appear
                >
                    <div>
                        <div
                            onMouseEnter={()=>setMouseHovered(true)}
                            onMouseLeave={()=>setMouseHovered(false)}
                            className={`flex ${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} relative items-center w-full max-w-xs p-4  text-gray-700 bg-gray-50 border-2 border-gray-700  dark:border-gray-200 rounded-lg shadow-sm dark:text-gray-200 dark:bg-gray-800`}
                        >
                            <div
                                className="theme-smooth inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                                <IconSquareRoundedCheckFilled />
                                <span className="sr-only">Check icon</span>
                            </div>
                            <div className="ml-3 text-sm font-normal pe-2  ">{msg.message}</div>
                            <button onClick={deleteToast} className={`${theme.state === themeStateDef.PENDING ? 'theme-smooth' : ''} ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700`} data-dismiss-target="#toast-success" aria-label="Close">
                                <span className="sr-only">Close</span>
                                <IconX />
                            </button>
                            <div className=" absolute left-0 right-0 h-[2px] bottom-0 transition-transform duration-200">
                                <div style={{ width: `${(currentTimerPos.current / msg.timeOut) * 100}%` }} className=" bg-gray-700 dark:bg-gray-200 h-full transition-transform duration-200 "></div>
                            </div>
                        </div>
                    </div>
                </Transition>
            )

    }



}

export function showToast({
    message,
    timout = 4000
}) {

    store.dispatch(
        showToastRedux({
            message: message,
            timeOut: timout
        })
    )

}

export function showErrorToast({
    message,
    timeOut,
    showAtTimeOne = false,
    id
}) {

    if (showAtTimeOne === true && !id) {
        throw new Error('unique Id must be required when showAtTimeone is true')
    }

    store.dispatch(
        showToastRedux({
            message: message,
            timeOut: timeOut,
            type: toastTypeDef.error,
            showAtTimeOne: showAtTimeOne,
            id: id
        })
    )
}